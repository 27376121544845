import _, { isInteger } from 'lodash';
import moment from 'moment';
import CONSTANTS from '../../../constants';
import {
  addData,
  deleteData,
  getStoreData,
  initMainDB,
  updateData
} from '../../../pos/src/utils/DBHelper';
import LocationService from '../../../services/LocationService';
import LoginService from '../../../services/LoginService';
import OrderTypeService from '../../../services/OrderTypeService';
import PromotionService from '../../../services/PromotionService';
import TableService from '../../../services/TableService';
import UserService from '../../../services/UserService';
import { initRestaurantDB } from './DBHelper';
import ItemService from './ItemService';

export default {
  checkForMenus,
  checkForOutlets,
  checkForOrderTypes,
  saveLocation,
  getUsers,
  getUsersLocations,
  getLocalLocations,
  getMenuBySyncTime,
  getMenuSyncTime,
  checkForPromotions,
  checkForTables,
  getPromotionsBySyncTime,
  getTableBySyncTime,
  getLocalDBUsers,
  getLocalMenus,
  saveUserOffline,
  saveUsersLocationOffline,
  syncOfflineUsersToOnine,
  getSyncableUsersData,
  saveLiveOrders,
  getSavedLiveOrders,
  getSavedLiveOrder
};
const date = moment().format(CONSTANTS.DATE_TIMES.FORMAT.DB);

async function saveUserOffline(user, location) {
  user.is_sync = 1;
  await initRestaurantDB(CONSTANTS.DB_CACHE_KEY_PREFIX, location.loc_id);
  let loc_id = location.is_outlet ? location.parent_id : location.loc_id;
  let localUsers = await getStoreData(
    CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
    CONSTANTS.INDEXDB.STORE.USERS
  );
  if (localUsers.length > 0) {
    let users = localUsers[1].data;
    users.push(user);
    await deleteData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, 0);
    await deleteData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, 1);
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, {
      id: 0,
      lastSync: date
    });
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, {
      id: 1,
      data: users
    });
    return users;
  }
  return false;
}

async function saveUsersLocationOffline(userLocation, location) {
  userLocation.is_sync = 1;
  let loc_id = location.is_outlet ? location.parent_id : location.loc_id;
  await initRestaurantDB(CONSTANTS.DB_CACHE_KEY_PREFIX, loc_id);
  let localUsersLocation = await getStoreData(
    CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
    CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS
  );
  if (localUsersLocation.length > 0) {
    let usersLocations = localUsersLocation[1].data;
    usersLocations.push(userLocation);
    await deleteData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
      CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS,
      1
    );
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS, {
      id: 1,
      data: usersLocations
    });
    return usersLocations;
  }
  return [];
}

async function saveLiveOrders(orders) {
  await initMainDB(CONSTANTS.DB_MAIN_CACHE_KEY_PREFIX);
  let liveOrders = await getStoreData(
    CONSTANTS.DB_MAIN_CACHE_KEY_PREFIX,
    CONSTANTS.INDEXDB.PLUGD.LIVE_ORDERS
  );
  if (liveOrders.length > 0) {
    await deleteData(CONSTANTS.DB_MAIN_CACHE_KEY_PREFIX, CONSTANTS.INDEXDB.PLUGD.LIVE_ORDERS, 0);
    await addData(CONSTANTS.DB_MAIN_CACHE_KEY_PREFIX, CONSTANTS.INDEXDB.PLUGD.LIVE_ORDERS, {
      id: 0,
      data: orders
    });
  } else {
    await addData(CONSTANTS.DB_MAIN_CACHE_KEY_PREFIX, CONSTANTS.INDEXDB.PLUGD.LIVE_ORDERS, {
      id: 0,
      data: orders
    });
  }
  return [];
}
async function getSavedLiveOrders() {
  let localLiveOrders = await getStoreData(
    CONSTANTS.DB_MAIN_CACHE_KEY_PREFIX,
    CONSTANTS.INDEXDB.PLUGD.LIVE_ORDERS
  );
  if (localLiveOrders.length > 0) {
    return localLiveOrders[0].data;
  }
  return [];
}

async function getSavedLiveOrder(order_id, loc_id) {
  let localLiveOrders = await getStoreData(
    CONSTANTS.DB_MAIN_CACHE_KEY_PREFIX,
    CONSTANTS.INDEXDB.PLUGD.LIVE_ORDERS
  );
  if (localLiveOrders.length > 0) {
    let orders = localLiveOrders[0].data.rows;
    let order = orders.filter((row) => row.order_id === order_id && row.location_id === loc_id);
    if (order.length > 0) {
      return order[0];
    }
  }
  return null;
}

async function syncOfflineUsersToOnine(location) {
  let users = await this.getSyncableUsersData(location);
  if (users.length > 0) {
    let response = await LoginService.syncUsersList(users);
    if (response) {
      let loc_id = location.is_outlet ? location.parent_id : location.loc_id;
      let customers = await UserService.getCustomers(loc_id);
      if (customers) {
        await deleteData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, 0);
        await deleteData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, 1);
        await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, {
          id: 0,
          lastSync: date
        });
        await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, {
          id: 1,
          data: customers
        });
      }

      let customers_locations = await UserService.getCustomersLocations(loc_id);
      if (customers_locations) {
        await deleteData(
          CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
          CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS,
          0
        );
        await deleteData(
          CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
          CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS,
          1
        );
        await addData(
          CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
          CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS,
          {
            id: 0,
            lastSync: date
          }
        );
        await addData(
          CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
          CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS,
          {
            id: 1,
            data: customers_locations
          }
        );
      }
    }
  }

  return [];
}

async function getSyncableUsersData(location) {
  if (!location) return [];
  await initRestaurantDB(CONSTANTS.DB_CACHE_KEY_PREFIX, location.loc_id);
  let loc_id = location.is_outlet ? location.parent_id : location.loc_id;
  let localUsers = await getStoreData(
    CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
    CONSTANTS.INDEXDB.STORE.USERS
  );
  let localUsersLocations = await getStoreData(
    CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
    CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS
  );
  let offlineUsers = [];
  if (localUsers.length > 0) {
    let usersData = localUsers.filter((row) => row.id === 1);
    let users = usersData[0].data;
    if (users.length > 0) {
      for (let i = 0; i < users.length; i++) {
        if (!isInteger(users[i].user_id) && users[i].is_sync === 1) {
          if (localUsersLocations.length > 0) {
            let usersLocations = localUsersLocations[1].data;
            if (usersLocations.length > 0) {
              let locations = usersLocations.filter((loc) => loc.user_id === users[i].user_id);
              users[i].usersLocations = locations.length > 0 ? locations : [];
              offlineUsers.push(users[i]);
            }
          }
        } else {
          if (localUsersLocations.length > 0) {
            let usersLocations = localUsersLocations[1].data;
            if (usersLocations.length > 0) {
              let locations = usersLocations.filter(
                (loc) => loc.user_id === users[i].user_id && loc.is_sync === 1
              );
              if (locations.length > 0) {
                users[i].loc_id = loc_id;
                users[i].usersLocations = locations;
                offlineUsers.push(users[i]);
              }
            }
          }
        }
      }
    }
  }
  return offlineUsers;
}

async function getLocalDBUsers(location) {
  await initRestaurantDB(CONSTANTS.DB_CACHE_KEY_PREFIX, location.loc_id);
  let loc_id = location.is_outlet ? location.parent_id : location.loc_id;
  let localUsers = await getStoreData(
    CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
    CONSTANTS.INDEXDB.STORE.USERS
  );
  if (localUsers.length > 0) {
    return localUsers[1].data;
  } else {
    let customers = await UserService.getCustomers(loc_id);

    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, {
      id: 0,
      lastSync: date
    });
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, {
      id: 1,
      data: customers
    });
    return customers;
  }

  return [];
}

async function getLocalLocations() {
  let localLocations = await getStoreData(
    CONSTANTS.DB_MAIN_CACHE_KEY_PREFIX,
    CONSTANTS.INDEXDB.PLUGD.LOCATIONS
  );
  if (localLocations.length > 0) {
    return localLocations[0].data;
  }

  return [];
}

async function getPromotionsBySyncTime(location) {
  const promotions = await PromotionService.getFilteredPromotionList(location.loc_id);
  if (promotions) {
    let newdate = moment().format(CONSTANTS.DATE_TIMES.FORMAT.DB);
    await deleteData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.PROMOTIONS,
      0
    );
    await deleteData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.PROMOTIONS,
      1
    );
    await addData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.PROMOTIONS,
      {
        id: 0,
        lastSync: newdate
      }
    );
    await addData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.PROMOTIONS,
      {
        id: 1,
        data: promotions
      }
    );
  }
}

async function getTableBySyncTime(location) {
  let tables = await TableService.getAllTables(location.loc_id);
  if (tables) {
    let newdate = moment().format(CONSTANTS.DATE_TIMES.FORMAT.DB);
    await deleteData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.TABLES,
      0
    );
    await deleteData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.TABLES,
      1
    );
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id, CONSTANTS.INDEXDB.STORE.TABLES, {
      id: 0,
      lastSync: newdate
    });
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id, CONSTANTS.INDEXDB.STORE.TABLES, {
      id: 1,
      data: tables
    });
  }
}

async function getMenuBySyncTime(location) {
  let menuSynctime = await getMenuSyncTime(location.loc_id);
  menuSynctime = moment(menuSynctime).subtract(2, 'minutes').format(CONSTANTS.DATE_TIMES.FORMAT.DB);

  let menu = await ItemService.getMenuSyncPOS(location.slug, menuSynctime);
  let categoriesData = await ItemService.getCategoryItem(JSON.stringify(menu), location.slug);
  if (categoriesData) {
    let localMenus = await getLocalMenus(location);
    for (let categoryItem of categoriesData) {
      let index = _.findIndex(localMenus, { category_id: categoryItem.category_id });
      if (index !== -1) {
        localMenus.splice(index, 1, categoryItem);
      } else {
        localMenus.push(categoryItem);
      }
    }

    let newdate = moment().format(CONSTANTS.DATE_TIMES.FORMATf.DB);
    await updateData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.MENUS,
      0,
      {
        id: 0,
        lastSync: newdate
      }
    );
    await updateData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.MENUS,
      1,
      {
        id: 1,
        data: localMenus
      }
    );
    return localMenus;
  }
  return false;
}

async function getMenuSyncTime(location_id) {
  let localMenu = await getStoreData(
    CONSTANTS.DB_CACHE_KEY_PREFIX + location_id,
    CONSTANTS.INDEXDB.STORE.MENUS
  );
  if (localMenu.length > 0) {
    return localMenu[0].lastSync;
  }
  return null;
}
async function getLocalMenus(location) {
  await initRestaurantDB(CONSTANTS.DB_CACHE_KEY_PREFIX, location.loc_id);
  let localMenu = await getStoreData(
    CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
    CONSTANTS.INDEXDB.STORE.MENUS
  );
  if (localMenu.length > 0) {
    return localMenu[1].data;
  } else {
    let menu = await ItemService.getMenu(location.slug);
    let categoriesData = await ItemService.getCategoryItem(JSON.stringify(menu), location.slug);
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id, CONSTANTS.INDEXDB.STORE.MENUS, {
      id: 0,
      lastSync: date
    });
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id, CONSTANTS.INDEXDB.STORE.MENUS, {
      id: 1,
      data: categoriesData
    });
    return categoriesData;
  }
  return [];
}
async function saveLocation(location) {
  let localLocations = await getStoreData(
    CONSTANTS.DB_MAIN_CACHE_KEY_PREFIX,
    CONSTANTS.INDEXDB.PLUGD.LOCATIONS
  );
  if (localLocations.length > 0) {
    localLocations = localLocations[0].data;
    let filterLocation = localLocations.filter((row) => row.loc_id === location.loc_id);
    if (filterLocation.length === 0) {
      localLocations.push(location);
      await updateData(CONSTANTS.DB_MAIN_CACHE_KEY_PREFIX, CONSTANTS.INDEXDB.PLUGD.LOCATIONS, 0, {
        id: 0,
        data: localLocations
      });
    }
  }
  if (localLocations.length === 0) {
    await addData(CONSTANTS.DB_MAIN_CACHE_KEY_PREFIX, CONSTANTS.INDEXDB.PLUGD.LOCATIONS, {
      id: 0,
      data: [location]
    });

    return true;
  }
  return false;
}

async function getUsers(loc_id) {
  let localUsers = await getStoreData(
    CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
    CONSTANTS.INDEXDB.STORE.USERS
  );
  if (localUsers.length === 0) {
    let customers = await UserService.getCustomers(loc_id);
    let promises = [];

    promises.push(
      addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, {
        id: 0,
        lastSync: date
      })
    );
    promises.push(
      addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, {
        id: 1,
        data: customers
      })
    );
    return Promise.all(promises);
  }
  return false;
}

async function getUsersLocations(loc_id) {
  let localUsers = await getStoreData(
    CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
    CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS
  );
  if (localUsers.length === 0) {
    let customers_locations = await UserService.getCustomersLocations(loc_id);

    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS, {
      id: 0,
      lastSync: date
    });
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS, {
      id: 1,
      data: customers_locations
    });

    return true;
  }
  return false;
}

async function checkForMenus(location) {
  let localMenu = await getStoreData(
    CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
    CONSTANTS.INDEXDB.STORE.MENUS
  );
  if (localMenu.length === 0) {
    let menu = await ItemService.getMenu(location.slug);
    let categoriesData = await ItemService.getCategoryItem(JSON.stringify(menu), location.slug);
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id, CONSTANTS.INDEXDB.STORE.MENUS, {
      id: 0,
      lastSync: date
    });
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id, CONSTANTS.INDEXDB.STORE.MENUS, {
      id: 1,
      data: categoriesData
    });
    return categoriesData;
  }
  return false;
}

async function checkForOutlets(location) {
  let localOutlets = await getStoreData(
    CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
    CONSTANTS.INDEXDB.STORE.OUTLETS
  );
  if (localOutlets.length === 0) {
    const outlet = await LocationService.getOutlets(location.slug);
    await addData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.OUTLETS,
      {
        id: 0,
        lastSync: date
      }
    );
    await addData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.OUTLETS,
      {
        id: 1,
        data: outlet
      }
    );
    return outlet;
  }
  return false;
}

async function checkForOrderTypes(location) {
  await initRestaurantDB(CONSTANTS.DB_CACHE_KEY_PREFIX, location.loc_id);
  let localOrderTypes = await getStoreData(
    CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
    CONSTANTS.INDEXDB.STORE.ORDER_TYPES
  );
  if (localOrderTypes.length === 0) {
    let orderTypes = '';
    if (location.orderTypes) {
      orderTypes = location.orderTypes;
    } else {
      orderTypes = await OrderTypeService.getOrderTypesPOS(location.loc_id);
    }
    await addData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.ORDER_TYPES,
      {
        id: 0,
        lastSync: date
      }
    );
    await addData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.ORDER_TYPES,
      {
        id: 1,
        data: orderTypes
      }
    );
    return orderTypes;
  }
  return false;
}

async function checkForPromotions(location) {
  await initRestaurantDB(CONSTANTS.DB_CACHE_KEY_PREFIX, location.loc_id);
  let localPromotions = await getStoreData(
    CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
    CONSTANTS.INDEXDB.STORE.PROMOTIONS
  );
  if (localPromotions.length === 0) {
    let loc_id = location.is_outlet ? location.parent_id : location.loc_id;
    const promotions = await PromotionService.getFilteredPromotionList(loc_id);
    await addData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.PROMOTIONS,
      {
        id: 0,
        lastSync: date
      }
    );
    await addData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.PROMOTIONS,
      {
        id: 1,
        data: promotions
      }
    );
    return promotions;
  }
  return false;
}

async function checkForTables(location) {
  await initRestaurantDB(CONSTANTS.DB_CACHE_KEY_PREFIX, location.loc_id);
  let localTables = await getStoreData(
    CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
    CONSTANTS.INDEXDB.STORE.TABLES
  );
  if (localTables.length === 0) {
    let tables = await TableService.getAllTables(location.loc_id);
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id, CONSTANTS.INDEXDB.STORE.TABLES, {
      id: 0,
      lastSync: date
    });
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id, CONSTANTS.INDEXDB.STORE.TABLES, {
      id: 1,
      data: tables
    });
    return tables;
  }
  return false;
}
