import CONSTANTS from '../../../constants';

export const initMainDB = (dbName) => {
  return new Promise((resolve) => {
    let request = window.indexedDB.open(dbName, 1);

    // if the data object store doesn't exist, create it
    request.onupgradeneeded = () => {
      let db = request.result;

      if (!db.objectStoreNames.contains(CONSTANTS.INDEXDB.PLUGD.LOCATIONS)) {
        db.createObjectStore(CONSTANTS.INDEXDB.PLUGD.LOCATIONS, {
          keyPath: 'id',
          autoIncrement: true
        });
      }
      if (!db.objectStoreNames.contains(CONSTANTS.INDEXDB.PLUGD.LIVE_ORDERS)) {
        db.createObjectStore(CONSTANTS.INDEXDB.PLUGD.LIVE_ORDERS, {
          keyPath: 'id',
          autoIncrement: true
        });
      }
    };

    request.onsuccess = (e) => {
      let db = request.result;
      if (
        !db.objectStoreNames.contains(CONSTANTS.INDEXDB.PLUGD.LOCATIONS) ||
        !db.objectStoreNames.contains(CONSTANTS.INDEXDB.PLUGD.LIVE_ORDERS)
      ) {
        const previousVersion = db.version;
        console.log(previousVersion);
        upgradeMainDatabase(dbName, previousVersion + 1);
        return;
      }
      db.transaction(['locations', 'live_orders'], 'readwrite');
      resolve(request.result);
    };

    request.onerror = (e) => {
      resolve(false);
    };
  });
};

const upgradeMainDatabase = (dbName, version) => {
  const request = window.indexedDB.open(dbName, version);

  request.onupgradeneeded = (event) => {
    const db = event.target.result;

    // Create the object store
    if (!db.objectStoreNames.contains(CONSTANTS.INDEXDB.PLUGD.LOCATIONS)) {
      db.createObjectStore(CONSTANTS.INDEXDB.PLUGD.LOCATIONS, {
        keyPath: 'id',
        autoIncrement: true
      });
    }
    if (!db.objectStoreNames.contains(CONSTANTS.INDEXDB.PLUGD.LIVE_ORDERS)) {
      db.createObjectStore(CONSTANTS.INDEXDB.PLUGD.LIVE_ORDERS, {
        keyPath: 'id',
        autoIncrement: true
      });
    }
  };

  request.onsuccess = (event) => {
    const db = event.target.result;
    console.log('Database upgraded and object store created:', db);
  };

  request.onerror = (event) => {
    console.error('Database error during upgrade:', event.target.errorCode);
  };
};

export const initRestaurantDB = (dbName, loc_id) => {
  return new Promise((resolve) => {
    let request = window.indexedDB.open(dbName + loc_id, 1);
    request.onupgradeneeded = () => {
      let db = request.result;
      if (!db.objectStoreNames.contains(CONSTANTS.INDEXDB.STORE.USERS)) {
        db.createObjectStore(CONSTANTS.INDEXDB.STORE.USERS, { keyPath: 'id', autoIncrement: true });
      }
      if (!db.objectStoreNames.contains(CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS)) {
        db.createObjectStore(CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS, {
          keyPath: 'id',
          autoIncrement: true
        });
      }
      if (!db.objectStoreNames.contains(CONSTANTS.INDEXDB.STORE.OUTLETS)) {
        db.createObjectStore(CONSTANTS.INDEXDB.STORE.OUTLETS, {
          keyPath: 'id',
          autoIncrement: true
        });
      }
      if (!db.objectStoreNames.contains(CONSTANTS.INDEXDB.STORE.MENUS)) {
        db.createObjectStore(CONSTANTS.INDEXDB.STORE.MENUS, { keyPath: 'id', autoIncrement: true });
      }
      if (!db.objectStoreNames.contains(CONSTANTS.INDEXDB.STORE.ORDER_TYPES)) {
        db.createObjectStore(CONSTANTS.INDEXDB.STORE.ORDER_TYPES, {
          keyPath: 'id',
          autoIncrement: true
        });
      }
      if (!db.objectStoreNames.contains(CONSTANTS.INDEXDB.STORE.PROMOTIONS)) {
        db.createObjectStore(CONSTANTS.INDEXDB.STORE.PROMOTIONS, {
          keyPath: 'id',
          autoIncrement: true
        });
      }
      if (!db.objectStoreNames.contains(CONSTANTS.INDEXDB.STORE.TABLES)) {
        db.createObjectStore(CONSTANTS.INDEXDB.STORE.TABLES, {
          keyPath: 'id',
          autoIncrement: true
        });
      }
    };

    request.onsuccess = (e) => {
      let db = request.result;
      db.transaction(
        ['users', 'users_locations', 'outlets', 'menus', 'ordertypes', 'promotions', 'tables'],
        'readwrite'
      );
      resolve(request.result);
    };

    request.onerror = (e) => {
      resolve(false);
    };
  });
};

export const deleteDatabase = (dbName, loc_id) => {
  return new Promise((resolve) => {
    let request = window.indexedDB.deleteDatabase(dbName + loc_id);
    request.onsuccess = () => {
      console.log('Database deleted successfully.');
    };

    request.onerror = (event) => {
      console.error('Database deletion failed:', event.target.error);
    };

    request.onblocked = () => {
      console.warn('Database deletion blocked. Close all tabs using this database and try again.');
    };
  });
};

export const addData = (dbName, storeName, data) => {
  return new Promise((resolve) => {
    let request = indexedDB.open(dbName);

    request.onsuccess = () => {
      let db = request.result;
      let tx = db.transaction(storeName, 'readwrite');
      let store;
      try {
        store = tx.objectStore(storeName);
      } catch (e) {
        store = db.createObjectStore(storeName);
      }
      store.add(data);
      resolve(data);
    };

    request.onerror = () => {
      let error = request.error?.message;
      if (error) {
        resolve(error);
      } else {
        resolve('Unknown error');
      }
    };
  });
};

export const deleteData = (dbName, storeName, key) => {
  return new Promise((resolve) => {
    const request = indexedDB.open(dbName);

    request.onsuccess = () => {
      const db = request.result;
      const tx = db.transaction(storeName, 'readwrite');
      let store;
      try {
        store = tx.objectStore(storeName);
      } catch (e) {
        store = db.createObjectStore(storeName);
      }
      const res = store.delete(key);
      res.onsuccess = () => {
        resolve(true);
      };
      res.onerror = () => {
        resolve(false);
      };
    };
  });
};

export const updateData = (dbName, storeName, key, data) => {
  return new Promise((resolve) => {
    const request = indexedDB.open(dbName);

    request.onsuccess = () => {
      const db = request.result;
      const tx = db.transaction(storeName, 'readwrite');
      let store;
      try {
        store = tx.objectStore(storeName);
      } catch (e) {
        store = db.createObjectStore(storeName);
      }
      const res = store.get(key);
      res.onsuccess = () => {
        const newData = { ...res.result, ...data };
        store.put(newData);
        resolve(newData);
      };
      res.onerror = () => {
        resolve(null);
      };
    };
  });
};

export const getStoreData = (dbName, storeName) => {
  return new Promise((resolve) => {
    const request = indexedDB.open(dbName);

    request.onsuccess = () => {
      const db = request.result;
      const tx = db.transaction(storeName, 'readonly');
      let store;
      try {
        store = tx.objectStore(storeName);
      } catch (e) {
        store = db.createObjectStore(storeName);
      }
      const res = store.getAll();
      res.onsuccess = () => {
        resolve(res.result);
      };
    };
  });
};
