import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import parsePhoneNumber from 'libphonenumber-js';
import React, { useEffect, useRef, useState } from 'react';
import * as Icon from 'react-feather';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { search } from 'ss-search';
import Swal from 'sweetalert2';
import LocationSelectHeader from '../../../components/shared/LocationSelectHeader';
import ViewHelper from '../../../helpers/ViewHelper';
import { getMenuGroupedByCategory } from '../../../redux/actions/categories';
import { toggleCartVisibility } from '../../../redux/actions/customizer';
import { getLocation } from '../../../redux/actions/locations';
import { getDummyItem } from '../../../redux/actions/menus';
import { getAll } from '../../../redux/actions/options';
import { getPromotions } from '../../../redux/actions/promotions';
import { getCustomers } from '../../../redux/actions/users';
import { StyledDesktopMenu, useStyles } from '../container/style/DesktopMenuStyle';
import {
  clearCart,
  clearCheckout,
  setOrderAddress,
  setOrderCustomer,
  setOrderTableNo,
  setOrderType
} from '../redux/actions/cart';
import { setLocationData, setMenuOptionsData, setOrderTypeList } from '../redux/actions/pos/index';
import { registerUser, storeUserData } from '../redux/actions/users';
import MenuSearch from './MenuSearch';
import RightMenu from './RightMenu';
import SelectLocationData from './SelectLocationData';

const MenuDesktop = (props) => {
  const { locationType } = props;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { order_id } = useParams();
  const [priceCardWidth, setPriceCardWidth] = useState();
  const [positionRightValue, setPositionRightValue] = useState();
  const [customerClicked, setCustomerClicked] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [orderCustomer, setOrderCustomerInfo] = useState();
  const isInitialMount = useRef(true);

  const connection = window.navigator.onLine;
  // let allCustomers = JSON.parse(localStorage.getItem('allCustomers'));
  let usersList = props.allCustomers ? props.allCustomers : [];

  const changeOrderCustomerInformation = (customer) => {
    props.setOrderCustomer(customer);
    setOrderCustomerInfo(customer);
  };
  const [customer, setCustomer] = useState({
    phone_number: ''
  });

  const [customerInformation, setCustomerInformation] = useState();

  const changeSearchClicked = () => {
    props.setSearchClicked(!props.searchClicked);
    props.handlerSearch('');
    console.log('change search clicked:' + !props.searchClicked);
    // setTimeout(() => {
    //   if (!props.searchClicked) {
    //     document.querySelector('.MuiInputBase-input').focus();
    //   } else {
    //     document.querySelector('.MuiInputBase-input').blur();
    //   }
    // }, 300);
  };

  const searchCustomer = (event) => {
    let keys = ['phone_number'];
    if (event.keyCode === 13) {
      const result = search(usersList, keys, customer.phone_number);
      if (result.length) {
        setCustomerInformation(result[0]);
      } else {
        setCustomerInformation(null);
      }
    }
  };

  const changeCustomer = (event) => {
    if (event.target.value.length >= 10) {
      let keys = ['phone_number'];
      let phoneNumber = parsePhoneNumber(event.target.value, 'PK');
      let number = phoneNumber.formatNational().replaceAll('-', '').replaceAll(' ', '');
      let convertedNumber = number.toString();

      if (convertedNumber[0] === '0') {
        convertedNumber = Number(convertedNumber.substring(1));
      }

      const result = search(
        usersList,
        keys,
        convertedNumber ? convertedNumber : event.target.value
      );

      if (result.length) {
        setCustomerInformation(result[0]);
      } else {
        setCustomerInformation(null);
      }
      setCustomer({ phone_number: event.target.value });
    } else {
      setCustomerInformation(null);
      setCustomer({ phone_number: event.target.value });
    }
  };
  const handleCustomerInfoOpen = (name) => {
    const menuElem = document.getElementsByClassName('main-menu');
    menuElem[0].style.zIndex = 1;
    if (name === 'table') {
      if (props.orderInfo?.orderType === '') {
        Swal.fire('Opps!', 'You forgot to select order type, please select first', 'warning');
      } else {
        props.setCustomerInfoModal({ [name]: true });
      }
    } else {
      props.setCustomerInfoModal({ [name]: true });
    }
  };

  const handleAddNewCustomerClose = (name, order_type = '') => {
    const menuElem = document.getElementsByClassName('main-menu');
    menuElem[0].style.zIndex = 1030;
    props.setCustomerInfoModal({ [name]: false });
    if (name === 'customer') setCustomer({ phone_number: '' });
    if (name === 'orderType') {
      if (
        order_type === 'delivery' ||
        order_type === 'dine_in' ||
        order_type === 'pick_up' ||
        order_type === 'curbside_pickup'
      ) {
        menuElem[0].style.zIndex = 1;
        props.setCustomerInfoModal({ ['orderType']: false });
        if (
          order_type === 'delivery' ||
          order_type === 'pick_up' ||
          order_type === 'curbside_pickup'
        ) {
          if (!props.customer.user_id) {
            if (connection) {
              props.setCustomerInfoModal({ ['address']: true });
            } else {
              //props.setCustomerInfoModal({ ['customer']: true });
              props.setCustomerInfoModal({ ['address']: true });
            }
          }
        } else {
          props.setCustomerInfoModal({ ['table']: true });
        }
      }
    }
  };

  const closeOrderTypeModal = () => {
    const menuElem = document.getElementsByClassName('main-menu');
    menuElem[0].style.zIndex = 1030;
    props.setCustomerInfoModal({ ['orderType']: false });
  };

  let itemCard = document.getElementsByClassName('order-item-card');
  let body = document.getElementById('root');
  let topbar = document.getElementsByClassName('top-bar');
  let rightMenu = document.getElementsByClassName('right-menu');

  useEffect(() => {
    function handleResize() {
      if (location.pathname === '/locations/pos') {
        itemCard = document.getElementsByClassName('order-item-card');
        body = document.getElementById('root');
        topbar = document.getElementsByClassName('top-bar');
        setPriceCardWidth(itemCard[0].offsetWidth);
        setPositionRightValue(Number(body.offsetWidth - topbar[0].offsetWidth) / 2);
      }
    }

    window.addEventListener('resize', handleResize);

    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let { registerUser } = props;

  const [tableAnchorEl, setTableAnchorEl] = React.useState(null);
  const handleTableClick = (event) => {
    setTableAnchorEl(event.currentTarget);
  };
  const [enableDropdown, setEnableDropdown] = useState({
    table: false,
    orderType: false,
    customer: false,
    address: false
  });
  const changeEnableDropdownStatus = (name) => {
    if (window.navigator.onLine) {
      props.setCustomerInfoModal({ ['address']: true });
    } else {
      props.setCustomerInfoModal({ ['address']: true });
      // props.setCustomerInfoModal({ ['customer']: true });
    }
    // setEnableDropdown({ [name]: !enableDropdown[name] });
    // if (enableDropdown[name] === false) {
    //   setTimeout(() => {
    //     document.querySelector('.customer-input').focus();
    //   }, 300);
    // }
  };

  let dropDownref = useRef();
  let customerClickRef = useRef();
  const searchClickRef = useRef();

  useEffect(() => {
    if (props.locationLoc && !props.locationLoc?.is_master_venue) {
      if (props.openOrderType === 'true') {
        props.setCustomerInfoModal({ ['orderType']: true });
        history.push('/locations/pos');
        localStorage.setItem('location_index', 0);
      }
    }
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (searchClickRef.current && !searchClickRef.current.contains(e.target)) {
        if (
          enableDropdown.customer &&
          customerClickRef.current &&
          customerClickRef.current.contains(e.target)
        ) {
        } else {
          if (
            enableDropdown.customer &&
            dropDownref.current &&
            !dropDownref.current.contains(e.target)
          ) {
            setEnableDropdown({ customer: false });
          }
        }
      } else {
        if (e.target?.classList.length > 0) {
          if (e.target.classList.contains('customer-input') === false) {
            setEnableDropdown({ customer: false });
          }
        }
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [enableDropdown.customer, props.searchClicked]);

  let theme = props.customizer?.customizer?.theme;
  const changeCustomerClick = (customer) => {
    changeOrderCustomerInformation(customer);
    setEnableDropdown({ ...enableDropdown, customer: false });
    setCustomerClicked(!customerClicked);
    setCustomer({ phone_number: '' });
    setCustomerInformation(null);
  };

  let itemCardVisible = props.customizer?.customizer?.itemCardVisible;

  const changeCartVisibility = props.toggleCartVisibility;

  const toggleItemCartVisibility = () => {
    changeCartVisibility(!itemCardVisible);
  };

  const userRegistration = async () => {
    if (customer.phone_number === '') {
      alert('Please fill the phone number first');
    } else {
      if (connection) {
        const response = await registerUser(customer);
        if (response.status === 200) {
          let loc_id = props.location.is_outlet ? props.location.parent_id : props.location.loc_id;
          props.getCustomers(loc_id);
          handleCustomerInfoOpen('customer');
        }
      } else {
        let data = {
          phone_number: customer.phone_number
        };
        props.storeUserData(data);
        handleCustomerInfoOpen('customer');
      }
    }
  };

  const locationName = props.location ? props.location.name : '';

  let handleOnSearchText = (e) => {
    e.preventDefault();
    props.setSearchText(e.target.value);
  };

  let onChangeOutlet = (data) => {
    console.log(data);
  };

  let updateSavedLocations = (data) => {
    let customerData = {
      name:
        data.location_name !== ''
          ? data.location_name
          : props.address?.name !== undefined
          ? props.address.name
          : '',
      address:
        data.address !== ''
          ? data.address
          : props.address?.address !== undefined
          ? props.address.address
          : '',
      location:
        data.location !== ''
          ? data.location
          : props.address?.location !== undefined
          ? props.address.location
          : '',
      phone_number:
        data.phone_number !== ''
          ? data.phone_number
          : props.address?.phone_number !== undefined
          ? props.address.phone_number
          : '',
      note:
        data.note !== '' ? data.note : props.address?.note !== undefined ? props.address.note : ''
    };
    props.setOrderAddress(customerData);

    props.setCustomerInfoModal({ ['address']: false });
  };

  const [userDeliveryInformation, setUserDeliveryInformation] = useState({
    location_name: '',
    address:
      props.user.city || props.user.country ? `${props.user.city},${props.user.country}` : '',
    phone_number: props.user?.phone_number ? props.user?.phone_number : '',
    note: ''
  });

  const changeUserDeliveryInformation = (event) => {
    setUserDeliveryInformation({
      ...userDeliveryInformation,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    setUserDeliveryInformation({
      location_name: '',
      address:
        props.user.city || props.user.country ? `${props.user.city},${props.user.country}` : '',
      phone_number: props.user?.phone_number ? props.user?.phone_number : '',
      note: ''
    });
  }, [props.user]);

  // const renderHandler = (location, msg) => {
  //   return (
  //     <>
  //       <div className="m-2 location">
  //         <div className="mb-50">
  //           <LocationSelectHeader text={location.name} onIconClick={toggleLocation} />
  //         </div>
  //         <ErrorHandler message={msg}></ErrorHandler>
  //       </div>
  //     </>
  //   );
  // };

  if (props.location) {
    if (props.location.modules) {
      let modules = JSON.parse(props.location.modules);
      if (!ViewHelper.checkValidModule(modules, props.module)) {
        return (
          <>
            <StyledDesktopMenu
              enableDropdown={enableDropdown}
              theme={theme}
              module={props.module}
              sidebarCollapsed={props.customizer?.customizer.sidebarCollapsed}
              orderDivWidth={
                priceCardWidth ? priceCardWidth : itemCard[0] ? itemCard[0].offsetWidth : ''
              }
              positionRightValue={
                positionRightValue
                  ? positionRightValue
                  : topbar[0]
                  ? Number(body.offsetWidth - topbar[0].offsetWidth) / 2
                  : ''
              }
              customerClicked={customerClicked}
              pathname={location.pathname}
              cartVisibility={itemCardVisible}
              searchClicked={props.searchClicked}
              showSidebar={showSidebar}
              isIdExists={order_id !== undefined ? true : false}
              className="main-menu-desktop">
              <Container>
                <Row>
                  <div className="top-bar">
                    <div className="top-bar__left-side">
                      {location.pathname === '/locations/pos/cash-checkout' && (
                        <div className="menu-icon-back">
                          {location.pathname === '/locations/pos/cash-checkout' && (
                            <span onClick={() => history.push('/locations/pos')}>
                              <FontAwesomeIcon icon={faArrowLeft} size={'lg'} color="#c2c6dc" />
                            </span>
                          )}
                        </div>
                      )}
                      {/* {location.pathname === '/locations/pos/checkout-done' && (
                        <div className="menu-icon-back">
                          {location.pathname === '/locations/pos/checkout-done' && (
                            <span onClick={() => history.push('/locations/pos/cash-checkout')}>
                              <FontAwesomeIcon icon={faArrowLeft} size={'lg'} color="#c2c6dc" />
                            </span>
                          )}
                        </div>
                      )} */}

                      <div className="menu-icon">
                        {order_id !== undefined && (
                          <span onClick={() => history.goBack()}>
                            <FontAwesomeIcon icon={faArrowLeft} size={'lg'} color="#c2c6dc" />
                          </span>
                        )}

                        {(itemCardVisible === false &&
                          order_id === undefined &&
                          location.pathname === '/locations/pos') ||
                          (location.pathname === '/' && (
                            <Icon.Menu
                              onClick={() => props.toggleSidebarVisibility()}
                              className="ficon"
                            />
                          ))}

                        {itemCardVisible === false &&
                          order_id === undefined &&
                          location.pathname === '/locations/pos' && (
                            <Icon.Menu
                              onClick={() => props.toggleSidebarVisibility()}
                              className="ficon"
                            />
                          )}

                        {itemCardVisible === true && location.pathname !== '/' && (
                          <span onClick={toggleItemCartVisibility}>
                            <FontAwesomeIcon icon={faArrowLeft} size={'lg'} color="#c2c6dc" />
                          </span>
                        )}
                      </div>

                      {order_id !== undefined && (
                        <h2 className={'order-detail'}>Order details - {order_id}</h2>
                      )}
                      {location.pathname === '/' && <h2 className={'order-detail'}>Live Orders</h2>}
                      <div className={'select-restaurant-parent'}>
                        {props.selectLocationActive ? (
                          <div className={'select-restaurant'}>
                            <SelectLocationData
                              onBack={props.toggleLocationActive}
                              onSelect={props.onLocationSelect}
                            />
                          </div>
                        ) : (
                          <div className={'select-restaurant'}>
                            <LocationSelectHeader
                              text={locationName}
                              onIconClick={props.toggleLocationActive}
                            />
                          </div>
                        )}
                      </div>
                      <div className={'pos-icon'}>
                        {props.searchClicked === false && order_id === undefined && (
                          <h2>PLUGD POS</h2>
                        )}
                      </div>
                    </div>
                  </div>
                </Row>
              </Container>
            </StyledDesktopMenu>
          </>
        );
      }
    } else {
      return (
        <>
          <StyledDesktopMenu
            enableDropdown={enableDropdown}
            theme={theme}
            module={props.module}
            sidebarCollapsed={props.customizer?.customizer.sidebarCollapsed}
            orderDivWidth={
              priceCardWidth ? priceCardWidth : itemCard[0] ? itemCard[0].offsetWidth : ''
            }
            positionRightValue={
              positionRightValue
                ? positionRightValue
                : topbar[0]
                ? Number(body.offsetWidth - topbar[0].offsetWidth) / 2
                : ''
            }
            customerClicked={customerClicked}
            pathname={location.pathname}
            cartVisibility={itemCardVisible}
            searchClicked={props.searchClicked}
            showSidebar={showSidebar}
            isIdExists={order_id !== undefined ? true : false}
            className="main-menu-desktop">
            <Container>
              <Row>
                <div className="top-bar">
                  <div className="top-bar__left-side">
                    {location.pathname === '/locations/pos/cash-checkout' && (
                      <div className="menu-icon-back">
                        {location.pathname === '/locations/pos/cash-checkout' && (
                          <span onClick={() => history.push('/locations/pos')}>
                            <FontAwesomeIcon icon={faArrowLeft} size={'lg'} color="#c2c6dc" />
                          </span>
                        )}
                      </div>
                    )}
                    {/* {location.pathname === '/locations/pos/checkout-done' && (
                      <div className="menu-icon-back">
                        {location.pathname === '/locations/pos/checkout-done' && (
                          <span onClick={() => history.push('/locations/pos/cash-checkout')}>
                            <FontAwesomeIcon icon={faArrowLeft} size={'lg'} color="#c2c6dc" />
                          </span>
                        )}
                      </div>
                    )} */}

                    <div className="menu-icon">
                      {order_id !== undefined && (
                        <span onClick={() => history.goBack()}>
                          <FontAwesomeIcon icon={faArrowLeft} size={'lg'} color="#c2c6dc" />
                        </span>
                      )}

                      {(itemCardVisible === false &&
                        order_id === undefined &&
                        location.pathname === '/locations/pos') ||
                        (location.pathname === '/' && (
                          <Icon.Menu
                            onClick={() => props.toggleSidebarVisibility()}
                            className="ficon"
                          />
                        ))}

                      {itemCardVisible === false &&
                        order_id === undefined &&
                        location.pathname === '/locations/pos' && (
                          <Icon.Menu
                            onClick={() => props.toggleSidebarVisibility()}
                            className="ficon"
                          />
                        )}

                      {itemCardVisible === true && location.pathname !== '/' && (
                        <span onClick={toggleItemCartVisibility}>
                          <FontAwesomeIcon icon={faArrowLeft} size={'lg'} color="#c2c6dc" />
                        </span>
                      )}
                    </div>

                    {order_id !== undefined && (
                      <h2 className={'order-detail'}>Order details - {order_id}</h2>
                    )}
                    {location.pathname === '/' && <h2 className={'order-detail'}>Live Orders</h2>}
                    <div className={'select-restaurant-parent'}>
                      {props.selectLocationActive ? (
                        <div className={'select-restaurant'}>
                          <SelectLocationData
                            onBack={props.toggleLocationActive}
                            onSelect={props.onLocationSelect}
                          />
                        </div>
                      ) : (
                        <div className={'select-restaurant'}>
                          <LocationSelectHeader
                            text={locationName}
                            onIconClick={props.toggleLocationActive}
                          />
                        </div>
                      )}
                    </div>
                    <div className={'pos-icon'}>
                      {props.searchClicked === false && order_id === undefined && (
                        <h2>PLUGD POS</h2>
                      )}
                    </div>
                    <MenuSearch
                      toggleMenu={props.toggleMenu}
                      itemCardVisible={itemCardVisible}
                      classes={classes}
                      searchClickRef={searchClickRef}
                      changeSearchClicked={changeSearchClicked}
                      searchClicked={props.searchClicked}
                      theme={theme}
                      searchText={props.searchText}
                      handleOnSearchText={handleOnSearchText}
                      handlerSearch={props.handlerSearch}
                    />
                  </div>
                  <RightMenu
                    orderInfo={props.orderInfo}
                    handleCustomerInfoOpen={handleCustomerInfoOpen}
                    theme={theme}
                    customerClickRef={customerClickRef}
                    dropDownref={dropDownref}
                    customer={customer}
                    customerInformation={customerInformation}
                    searchCustomer={searchCustomer}
                    changeCustomer={changeCustomer}
                    changeEnableDropdownStatus={changeEnableDropdownStatus}
                    userRegistration={userRegistration}
                    changeCustomerClick={changeCustomerClick}
                    connection={connection}
                    handleAddNewCustomerClose={handleAddNewCustomerClose}
                    customerInfoModalOpen={props.customerInfoModalOpen}
                    setCustomerInfoModal={props.setCustomerInfoModal}
                    closeOrderTypeModal={closeOrderTypeModal}
                    _outlets={props._outlets}
                    onChangeOutlet={onChangeOutlet}
                    _location={props._location}
                    updateSavedLocations={updateSavedLocations}
                    userDeliveryInformation={userDeliveryInformation}
                    changeUserDeliveryInformation={changeUserDeliveryInformation}
                    setUserDeliveryInformation={setUserDeliveryInformation}
                  />
                </div>
              </Row>
            </Container>
          </StyledDesktopMenu>
        </>
      );
    }
  }

  return (
    <StyledDesktopMenu
      enableDropdown={enableDropdown}
      theme={theme}
      module={props.module}
      sidebarCollapsed={props.customizer?.customizer.sidebarCollapsed}
      orderDivWidth={priceCardWidth ? priceCardWidth : itemCard[0] ? itemCard[0].offsetWidth : ''}
      positionRightValue={
        positionRightValue
          ? positionRightValue
          : topbar[0]
          ? Number(body.offsetWidth - topbar[0].offsetWidth) / 2
          : ''
      }
      customerClicked={customerClicked}
      pathname={location.pathname}
      cartVisibility={itemCardVisible}
      searchClicked={props.searchClicked}
      showSidebar={showSidebar}
      isIdExists={order_id !== undefined ? true : false}
      className="main-menu-desktop">
      <Container>
        <Row>
          <div className="top-bar">
            <div className="top-bar__left-side">
              {location.pathname === '/locations/pos/cash-checkout' && (
                <div className="menu-icon-back">
                  {location.pathname === '/locations/pos/cash-checkout' && (
                    <span onClick={() => history.push('/locations/pos')}>
                      <FontAwesomeIcon icon={faArrowLeft} size={'lg'} color="#c2c6dc" />
                    </span>
                  )}
                </div>
              )}
              {/* {location.pathname === '/locations/pos/checkout-done' && (
                <div className="menu-icon-back">
                  {location.pathname === '/locations/pos/checkout-done' && (
                    <span onClick={() => history.push('/locations/pos/cash-checkout')}>
                      <FontAwesomeIcon icon={faArrowLeft} size={'lg'} color="#c2c6dc" />
                    </span>
                  )}
                </div>
              )} */}

              <div className="menu-icon">
                {order_id !== undefined && (
                  <span onClick={() => history.goBack()}>
                    <FontAwesomeIcon icon={faArrowLeft} size={'lg'} color="#c2c6dc" />
                  </span>
                )}

                {(itemCardVisible === false &&
                  order_id === undefined &&
                  location.pathname === '/locations/pos') ||
                  (location.pathname === '/' && (
                    <Icon.Menu onClick={() => props.toggleSidebarVisibility()} className="ficon" />
                  ))}

                {itemCardVisible === false &&
                  order_id === undefined &&
                  location.pathname === '/locations/pos' && (
                    <Icon.Menu onClick={() => props.toggleSidebarVisibility()} className="ficon" />
                  )}

                {itemCardVisible === true && location.pathname !== '/' && (
                  <span onClick={toggleItemCartVisibility}>
                    <FontAwesomeIcon icon={faArrowLeft} size={'lg'} color="#c2c6dc" />
                  </span>
                )}
              </div>

              {order_id !== undefined && (
                <h2 className={'order-detail'}>Order details - {order_id}</h2>
              )}
              {location.pathname === '/' && <h2 className={'order-detail'}>Live Orders</h2>}
              <div className={'select-restaurant-parent'}>
                {props.selectLocationActive ? (
                  <div className={'select-restaurant'}>
                    <SelectLocationData
                      onBack={props.toggleLocationActive}
                      onSelect={props.onLocationSelect}
                    />
                  </div>
                ) : (
                  <div className={'select-restaurant'}>
                    <LocationSelectHeader
                      text={locationName}
                      onIconClick={props.toggleLocationActive}
                    />
                  </div>
                )}
              </div>
              <div className={'pos-icon'}>
                {props.searchClicked === false && order_id === undefined && <h2>PLUGD POS</h2>}
              </div>
              <MenuSearch
                toggleMenu={props.toggleMenu}
                itemCardVisible={itemCardVisible}
                classes={classes}
                searchClickRef={searchClickRef}
                changeSearchClicked={changeSearchClicked}
                searchClicked={props.searchClicked}
                theme={theme}
                searchText={props.searchText}
                handleOnSearchText={handleOnSearchText}
                handlerSearch={props.handlerSearch}
              />
            </div>
            <RightMenu
              orderInfo={props.orderInfo}
              handleCustomerInfoOpen={handleCustomerInfoOpen}
              theme={theme}
              customerClickRef={customerClickRef}
              dropDownref={dropDownref}
              customer={customer}
              customerInformation={customerInformation}
              searchCustomer={searchCustomer}
              changeCustomer={changeCustomer}
              changeEnableDropdownStatus={changeEnableDropdownStatus}
              userRegistration={userRegistration}
              changeCustomerClick={changeCustomerClick}
              connection={connection}
              handleAddNewCustomerClose={handleAddNewCustomerClose}
              customerInfoModalOpen={props.customerInfoModalOpen}
              setCustomerInfoModal={props.setCustomerInfoModal}
              closeOrderTypeModal={closeOrderTypeModal}
              _outlets={props._outlets}
              onChangeOutlet={onChangeOutlet}
              _location={props._location}
              updateSavedLocations={updateSavedLocations}
              userDeliveryInformation={userDeliveryInformation}
              changeUserDeliveryInformation={changeUserDeliveryInformation}
              setUserDeliveryInformation={setUserDeliveryInformation}
            />
          </div>
        </Row>
      </Container>
    </StyledDesktopMenu>
  );
};

function mapStateToProps(state) {
  return {
    locationType: state.locations && state.locations.locationType,
    token: state.posReducer ? state.posReducer.token : null,
    customizer: state.customizer ? state.customizer : null,
    customer: state.cartReducer ? state.cartReducer.customer : null,
    address: state.cartReducer ? state.cartReducer.address : null,
    location: state.posReducer ? state.posReducer.location : null,
    allCustomers: state.users.allCustomers.data,
    orderInfo: state.cartReducer ? state.cartReducer : null,
    _location: state.locations ? state.locations?.location?.data : null,
    _outlets: state.locations ? state.locations?.outlet?.data : null,
    user: state.cartReducer ? state.cartReducer.customer : null,
    locationLoc: state.locations ? state.locations?.location?.data : null
  };
}

export default connect(mapStateToProps, {
  setLocationData,
  getLocation,
  getAll,
  getDummyItem,
  getMenuGroupedByCategory,
  setMenuOptionsData,
  getPromotions,
  getCustomers,
  clearCart,
  clearCheckout,
  registerUser,
  storeUserData,
  setOrderType,
  setOrderAddress,
  setOrderTableNo,
  setOrderCustomer,
  toggleCartVisibility,
  setOrderTypeList
})(MenuDesktop);
