import { faCreditCard, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import clearIcon from '../../../assets/img/static/clear-icon.svg';
import clearIconWhite from '../../../assets/img/static/cross-icon-white.svg';
import SpinnerComponent from '../../../components/@vuexy/spinner/Fallback-spinner';
import GoogleHelper from '../../../helpers/GoogleHelper';
import ViewHelper from '../../../helpers/ViewHelper';
import LayoutContext from '../../../layouts/LayoutContext';
import { collapseSidebar } from '../../../redux/actions/customizer';
import { setStateValue } from '../../../redux/actions/orders';
import LocationService from '../../../services/LocationService';
import { StyledCashCheckout } from '../../../styles/GlobalStyles';
import { addCartAmountReceived, setCheckout, setOrderPaymentType } from '../redux/actions/cart';
import {
  createOrder,
  createOrderWithoutUser,
  updateOrder,
  updateOrderWithoutUser
} from '../redux/actions/pos';
import CartService from '../utils/CartService';
import { getFinalSuggestedAmountList } from '../utils/amountSuggestion';
import ItemCard from './ItemCard';
import MenuDesktop from './MainMenuDesktop';

function makeid(length) {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const CashCheckout = (props) => {
  const history = useHistory();
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const [dueAmount, setDueAmount] = useState(21);
  const [emailReceipt, setEmailReceipt] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(
    props.cart?.paymentType ? props.cart?.paymentType : 'Cash'
  );
  const [customerInfoModalOpen, setCustomerInfoModal] = useState({
    customer: false,
    table: false,
    orderType: false,
    address: false
  });

  const connection = window.navigator.onLine;

  useEffect(() => {
    props.collapseSidebar(true);
    setPaymentMethod('Cash');
    props.setOrderPaymentType('Cash');
  }, []);

  let theme = props.customizer?.customizer?.theme;

  const toggleMenuCollapsed = () => {
    setMenuCollapsed(!menuCollapsed);
  };

  let cartData = [],
    lineItem;
  props.userCart &&
    props.userCart.length &&
    props.userCart.map((item, key) => {
      lineItem = item.cartItem;
      cartData.push(lineItem);
    });

  useEffect(() => {
    console.log(props.location);
    if (paymentMethod.toLowerCase() === 'cash') {
      if (props.location) {
        localStorage.setItem('tax_percentage', props.location.tax_percent);
      }
      setCalculatorNumber('0');
    } else {
      if (props.location) {
        localStorage.setItem('tax_percentage', props.location.card_tax_percent);
      }
      setCalculatorNumber(amountToPay.toString());
    }
  }, [paymentMethod]);
  let amountToPay = CartService.getOrderTotalWithAllCharges(
    props.location,
    Number(
      paymentMethod.toLowerCase() === 'cash'
        ? props.location?.tax_percent
        : props.location?.card_tax_percent
    ),
    props.promotions,
    props.orderType?.id,
    null,
    cartData,
    props.location?.parent_id ? true : false
  );
  let discount = CartService.getDiscount(
    props.location,
    cartData,
    props.promotions,
    props.orderType?.id,
    null,
    props.location?.parent_id ? true : false
  ).discount;
  let taxWithDelivery = CartService.getTaxValueWithDelivery(
    cartData,
    CartService.getDeliveryCharge(props.location?.loc_id),
    discount,
    localStorage.getItem('tax_percentage')
  ).taxRate;
  let checkoutData = props.checkout;
  checkoutData.tax = taxWithDelivery;
  checkoutData.delivery_location =
    props.orderType?.order_type !== 'delivery'
      ? '0,0'
      : props.customerSelectedLocation.latitude
      ? `${props.customerSelectedLocation?.latitude},${props.customerSelectedLocation?.longitude}`
      : props.address
      ? `${props.address?.location?.lat},${props.address?.location?.lng}`
      : `${props.orderInfo?.address?.location?.lat},${props.orderInfo?.address?.location?.lng}`;
  checkoutData.delivery_address =
    props.orderType?.order_type !== 'delivery'
      ? ''
      : props.customerSelectedLocation.address
      ? props.customerSelectedLocation.address
      : props.address?.address
      ? props.address?.address
      : props.orderInfo?.address?.address;

  checkoutData.table.table_no = props.table?.id ? props.table?.id : props.table?.table_id;
  checkoutData.table.table_name = props.table?.name ? props.table?.name : props.table?.table_name;
  setCheckout(checkoutData);
  const [calculatorNumber, setCalculatorNumber] = useState('0');
  const addCalculatorNumber = (number) => {
    if (calculatorNumber.length === 15) {
      return;
    }
    let newNumber = calculatorNumber === '0' ? `${number}` : `${calculatorNumber}${number}`;
    setCalculatorNumber(newNumber);
  };
  const removeNumber = () => {
    if (calculatorNumber.length === 1) setCalculatorNumber('0');
    else {
      setCalculatorNumber(calculatorNumber.substring(0, calculatorNumber.length - 1));
    }
  };
  const replaceNumber = (number) => {
    setCalculatorNumber(number.toString());
  };

  const completeCheckout = () => {
    let checkoutData = props.checkout;
    checkoutData.address_id = props.customerSelectedLocation.id
      ? props.customerSelectedLocation.id
      : props.address?.id
      ? props.address?.id
      : props.orderInfo?.address?.id
      ? props.orderInfo?.address?.id
      : 1;
    let paymentMethod = props.cart.paymentType;
    checkoutData.payment = paymentMethod.toLowerCase();
    checkoutData.send_email = emailReceipt;
    checkoutData.payment_details.method =
      paymentMethod === 'Card' ? 'Card' : paymentMethod.toLowerCase();
    if (props.user?.token) {
      if (props.cart.cartUpdate === true) {
        let datas = {
          filter: 'live_orders',
          filterKeyName: 'live_orders',
          offlineOrder: false,
          page: 0,
          sort: 'DESC'
        };
        props.setStateValue(datas);
        props.updateOrder(
          checkoutData,
          props.location,
          props.user.token,
          null,
          false,
          props.cart.orderId
        );
      } else {
        if (connection) {
          let datas = {
            filter: 'live_orders',
            filterKeyName: 'live_orders',
            offlineOrder: false,
            page: 0,
            sort: 'DESC'
          };
          props.setStateValue(datas);
          props.createOrder(checkoutData, props.location, props.user.token);
        } else {
          let data = {
            ...props.checkout,
            koId: makeid(15)
          };
          data.offline_order = true;

          let datas = {
            filter: 'offline',
            filterKeyName: 'offline',
            offlineOrder: true,
            page: 0,
            sort: 'DESC'
          };
          props.setStateValue(datas);

          let offlineOrderList = localStorage.getItem('offline_order_list');
          if (!offlineOrderList) {
            offlineOrderList = [];
            offlineOrderList.push(data);
            localStorage.setItem('offline_order_list', JSON.stringify(offlineOrderList));
          } else {
            offlineOrderList = JSON.parse(offlineOrderList);
            offlineOrderList.push(data);
            localStorage.setItem('offline_order_list', JSON.stringify(offlineOrderList));
          }
        }
      }
    } else {
      if (props.cart.cartUpdate === true && connection) {
        let datas = {
          filter: 'live_orders',
          filterKeyName: 'live_orders',
          offlineOrder: false,
          page: 0,
          sort: 'DESC'
        };
        props.setStateValue(datas);
        props.updateOrderWithoutUser(checkoutData, props.location, null, false, props.cart.orderId);
      } else {
        if (connection) {
          let datas = {
            filter: 'live_orders',
            filterKeyName: 'live_orders',
            offlineOrder: false,
            page: 0,
            sort: 'DESC'
          };
          props.setStateValue(datas);
          props.createOrderWithoutUser(checkoutData, props.location);
        } else {
          if (props.cart.cartUpdate === true) {
            let offlineOrderList = localStorage.getItem('offline_order_list');
            offlineOrderList = JSON.parse(offlineOrderList);
            if (offlineOrderList.length > 0) {
              const index = offlineOrderList.findIndex(
                (item) => item.koId === props.orderInfo.orderId
              );
              if (index === -1) {
                // Item with the given id not found
                return;
              }

              const updatedItems = [
                ...offlineOrderList.slice(0, index),
                { ...offlineOrderList[index], ...props.checkout },
                ...offlineOrderList.slice(index + 1)
              ];
              localStorage.setItem('offline_order_list', JSON.stringify(updatedItems));
            }
          } else {
            let data = {
              ...props.checkout,
              koId: makeid(15)
            };
            data.offline_order = true;
            let datas = {
              filter: 'offline',
              filterKeyName: 'offline',
              offlineOrder: true,
              page: 0,
              sort: 'DESC'
            };
            props.setStateValue(datas);
            let offlineOrderList = localStorage.getItem('offline_order_list');
            if (!offlineOrderList) {
              offlineOrderList = [];
              offlineOrderList.push(data);
              localStorage.setItem('offline_order_list', JSON.stringify(offlineOrderList));
            } else {
              offlineOrderList = JSON.parse(offlineOrderList);
              offlineOrderList.push(data);
              localStorage.setItem('offline_order_list', JSON.stringify(offlineOrderList));
            }
          }
        }
      }
    }
    history.push('/locations/pos/checkout-done');

    //redirectToLiveOrders();
    // setTimeout(() => {
    //     clearCart();
    //     props.clearCheckout()
    //     localStorage.removeItem('plugd_menu_cart')
    //     history.push('/');
    // }, 1000)
  };

  const confirmCheckout = () => {
    props.checkout.customer = props.customer;
    const locationLoc = LocationService.getLocationLoc();
    console.log(props.checkout.delivery_location);
    let areas = null;
    if (props.checkout.delivery_location === '0,0') {
      areas = true;
    } else {
      areas = GoogleHelper.checkForDeliveryArea(
        props.checkout.delivery_location,
        locationLoc.deliveryAreas
      );
    }
    if (
      props.checkout.order_type === 'delivery' &&
      Object.keys(props.checkout.customer).length === 0
    ) {
      Swal.fire('Opps!', 'Seems like you forgot to select customer', 'warning').then(function () {
        props.setCustomerInfoModal((prevState) => ({
          ...prevState,
          address: true
        }));
      });
    } else if (
      props.checkout.order_type === 'delivery' &&
      (props.checkout.delivery_address === undefined || props.checkout.delivery_address === '')
    ) {
      Swal.fire('Opps!', 'Seems like you forgot to select delivery address', 'warning').then(
        function () {
          props.setCustomerInfoModal((prevState) => ({
            ...prevState,
            orderType: true
          }));
        }
      );
    } else if (props.checkout.order_type === 'delivery' && !areas) {
      Swal.fire('Opps!', 'This restaurant can not deliver in the selected area', 'warning').then(
        function () {
          props.setCustomerInfoModal((prevState) => ({
            ...prevState,
            orderType: true
          }));
        }
      );
    } else if (paymentMethod.toLowerCase() === 'cash') {
      if (Number(calculatorNumber) === 0) {
        Swal.fire('Opps!', 'Seems like you forgot to add amount, please add amount!', 'warning');
      } else if (Number(calculatorNumber) < Number(props.cart.amountDue)) {
        Swal.fire('Opps!', 'Collected cash amount is lower than order value', 'warning');
      } else {
        props.addCartAmountReceived(Number(calculatorNumber));
        completeCheckout();
        //history.push('/locations/pos/checkout-done');
      }
    } else {
      if (paymentMethod === '') {
        Swal.fire(
          'Opps!',
          'Seems like you forgot to select payment type, please select first!',
          'warning'
        );
      } else {
        props.addCartAmountReceived(Number(props.cart.amountDue));
        completeCheckout();
        //history.push('/locations/pos/checkout-done');
      }
    }
  };
  const changePaymentMethod = (method) => {
    if (method === 'Credit') {
      Swal.fire('Opps!', 'This feature is not available', 'warning');
    } else {
      setPaymentMethod(method);
      props.setOrderPaymentType(method);
    }
  };

  const inactiveButtonPopup = () => {
    Swal.fire('Sorry!', 'Your not allowed to select while payment is Card/Credit', 'warning');
  };

  const suggestAmountList = getFinalSuggestedAmountList(
    amountToPay,
    localStorage.getItem('currency_code')
  );
  return (
    <>
      <LayoutContext.Consumer>
        {(context) => (
          <MenuDesktop
            toggleMenu={toggleMenuCollapsed}
            customerInfoModalOpen={customerInfoModalOpen}
            setCustomerInfoModal={setCustomerInfoModal}
            toggleSidebarVisibility={context.sidebarVisibility}
          />
        )}
      </LayoutContext.Consumer>
      {props.loading ? (
        <SpinnerComponent />
      ) : (
        <StyledCashCheckout theme={theme} paymentMethod={paymentMethod}>
          {/*<SideBar/>*/}
          <Container>
            <Row>
              <Col md={8}>
                <center>
                  <div className="calculator-parent">
                    <div className="calculator-wrapper">
                      <div className="due-amount">
                        <p>Amount Due</p>
                        <p>
                          {ViewHelper.toCurrency(
                            amountToPay,
                            localStorage.getItem('currency_code'),
                            localStorage.getItem('decimal_place')
                          )}
                        </p>
                      </div>
                      <div
                        className={
                          paymentMethod === 'Card' || paymentMethod === 'Credit'
                            ? 'calculator inactive-calculator'
                            : 'calculator'
                        }>
                        <div
                          className={
                            paymentMethod === 'Card' || paymentMethod === 'Credit'
                              ? 'amount-received amount-received-inactive'
                              : 'amount-received'
                          }>
                          <div className="amount-received__left-side">
                            {/*<img onClick={() => history.push('/locations/pos')}*/}
                            {/*     src={arrowLeftWhiteIcon} alt=""/>*/}
                            <p>Amount Received</p>
                          </div>
                          <p className={'amount-received__amount'}>
                            {getSymbolFromCurrency(localStorage.getItem('currency_code'))}{' '}
                            {calculatorNumber === '0'
                              ? `${parseFloat(calculatorNumber).toFixed(
                                  localStorage.getItem('decimal_place')
                                )}`
                              : `${parseFloat(calculatorNumber).toFixed(
                                  localStorage.getItem('decimal_place')
                                )}`}
                          </p>
                        </div>
                        <div className="calculator-buttons">
                          <div className="button-row">
                            <button
                              onClick={() =>
                                paymentMethod === 'Card' || paymentMethod === 'Credit'
                                  ? inactiveButtonPopup()
                                  : replaceNumber(
                                      amountToPay !== 0
                                        ? `${parseFloat(amountToPay).toFixed(
                                            localStorage.getItem('decimal_place')
                                          )}`
                                        : `${parseFloat('0').toFixed(
                                            localStorage.getItem('decimal_place')
                                          )}`
                                    )
                              }>
                              {getSymbolFromCurrency(localStorage.getItem('currency_code'))}{' '}
                              {amountToPay !== 0
                                ? `${parseFloat(amountToPay).toFixed(
                                    localStorage.getItem('decimal_place')
                                  )}`
                                : `${parseFloat('0').toFixed(
                                    localStorage.getItem('decimal_place')
                                  )}`}
                            </button>
                            <button
                              onClick={() =>
                                paymentMethod === 'Card' || paymentMethod === 'Credit'
                                  ? inactiveButtonPopup()
                                  : replaceNumber(
                                      amountToPay !== 0
                                        ? `${parseFloat(
                                            suggestAmountList?.length > 0
                                              ? suggestAmountList[0] !== undefined
                                                ? suggestAmountList[0]
                                                : amountToPay
                                              : amountToPay + 10
                                          ).toFixed(localStorage.getItem('decimal_place'))}`
                                        : `${parseFloat('0').toFixed(
                                            localStorage.getItem('decimal_place')
                                          )}`
                                    )
                              }>
                              {getSymbolFromCurrency(localStorage.getItem('currency_code'))}{' '}
                              {amountToPay !== 0
                                ? `${parseFloat(
                                    suggestAmountList?.length > 0
                                      ? suggestAmountList[0] !== undefined
                                        ? suggestAmountList[0]
                                        : amountToPay
                                      : amountToPay + 10
                                  ).toFixed(localStorage.getItem('decimal_place'))}`
                                : `${parseFloat('0').toFixed(
                                    localStorage.getItem('decimal_place')
                                  )}`}
                            </button>
                            <button
                              onClick={() =>
                                paymentMethod === 'Card' || paymentMethod === 'Credit'
                                  ? inactiveButtonPopup()
                                  : replaceNumber(
                                      amountToPay !== 0
                                        ? `${parseFloat(
                                            suggestAmountList?.length > 0
                                              ? suggestAmountList[1] !== undefined
                                                ? suggestAmountList[1]
                                                : amountToPay
                                              : amountToPay + 10
                                          ).toFixed(localStorage.getItem('decimal_place'))}`
                                        : `${parseFloat('0').toFixed(
                                            localStorage.getItem('decimal_place')
                                          )}`
                                    )
                              }>
                              {getSymbolFromCurrency(localStorage.getItem('currency_code'))}{' '}
                              {amountToPay !== 0
                                ? `${parseFloat(
                                    suggestAmountList?.length > 0
                                      ? suggestAmountList[1] !== undefined
                                        ? suggestAmountList[1]
                                        : amountToPay
                                      : amountToPay + 10
                                  ).toFixed(localStorage.getItem('decimal_place'))}`
                                : `${parseFloat('0').toFixed(
                                    localStorage.getItem('decimal_place')
                                  )}`}
                            </button>
                          </div>
                          <div className="button-row">
                            <button
                              onClick={() =>
                                paymentMethod === 'Card' || paymentMethod === 'Credit'
                                  ? inactiveButtonPopup()
                                  : addCalculatorNumber('1')
                              }>
                              1
                            </button>
                            <button
                              onClick={() =>
                                paymentMethod === 'Card' || paymentMethod === 'Credit'
                                  ? inactiveButtonPopup()
                                  : addCalculatorNumber('2')
                              }>
                              2
                            </button>
                            <button
                              onClick={() =>
                                paymentMethod === 'Card' || paymentMethod === 'Credit'
                                  ? inactiveButtonPopup()
                                  : addCalculatorNumber('3')
                              }>
                              3
                            </button>
                          </div>
                          <div className="button-row">
                            <button
                              onClick={() =>
                                paymentMethod === 'Card' || paymentMethod === 'Credit'
                                  ? inactiveButtonPopup()
                                  : addCalculatorNumber('4')
                              }>
                              4
                            </button>
                            <button
                              onClick={() =>
                                paymentMethod === 'Card' || paymentMethod === 'Credit'
                                  ? inactiveButtonPopup()
                                  : addCalculatorNumber('5')
                              }>
                              5
                            </button>
                            <button
                              onClick={() =>
                                paymentMethod === 'Card' || paymentMethod === 'Credit'
                                  ? inactiveButtonPopup()
                                  : addCalculatorNumber('6')
                              }>
                              6
                            </button>
                          </div>
                          <div className="button-row">
                            <button
                              onClick={() =>
                                paymentMethod === 'Card' || paymentMethod === 'Credit'
                                  ? inactiveButtonPopup()
                                  : addCalculatorNumber('7')
                              }>
                              7
                            </button>
                            <button
                              onClick={() =>
                                paymentMethod === 'Card' || paymentMethod === 'Credit'
                                  ? inactiveButtonPopup()
                                  : addCalculatorNumber('8')
                              }>
                              8
                            </button>
                            <button
                              onClick={() =>
                                paymentMethod === 'Card' || paymentMethod === 'Credit'
                                  ? inactiveButtonPopup()
                                  : addCalculatorNumber('9')
                              }>
                              9
                            </button>
                          </div>
                          <div className="button-row">
                            <button
                              onClick={() =>
                                paymentMethod === 'Card' || paymentMethod === 'Credit'
                                  ? inactiveButtonPopup()
                                  : addCalculatorNumber('0')
                              }>
                              0
                            </button>
                            <button
                              onClick={() =>
                                paymentMethod === 'Card' || paymentMethod === 'Credit'
                                  ? inactiveButtonPopup()
                                  : addCalculatorNumber('.')
                              }>
                              .
                            </button>
                            <button
                              onClick={() =>
                                paymentMethod === 'Card' || paymentMethod === 'Credit'
                                  ? inactiveButtonPopup()
                                  : removeNumber()
                              }>
                              <img src={theme === 'dark' ? clearIconWhite : clearIcon} alt="" />
                            </button>
                          </div>
                          <h2 className={'payment-method'}>Select payment method:</h2>
                          <div className="payment-methods">
                            <div
                              onClick={() => changePaymentMethod('Cash')}
                              className={paymentMethod === 'Cash' && 'selected-payment-method'}>
                              <span>
                                <FontAwesomeIcon icon={faMoneyBill} size={'lg'} color="#c2c6dc" />
                              </span>
                              <span>Cash</span>
                            </div>
                            <div
                              onClick={() => changePaymentMethod('Card')}
                              className={paymentMethod === 'Card' && 'selected-payment-method'}>
                              <span>
                                <FontAwesomeIcon icon={faCreditCard} size={'lg'} color="#c2c6dc" />
                              </span>
                              <span>Card</span>
                            </div>
                            <div
                              onClick={() => changePaymentMethod('Credit')}
                              className={paymentMethod === 'Credit' && 'selected-payment-method'}>
                              <span>
                                <FontAwesomeIcon icon={faCreditCard} size={'lg'} color="#c2c6dc" />
                              </span>
                              <span>Credit</span>
                            </div>
                          </div>
                          {/* <div className="button-row-inner">
                            <button onClick={confirmCheckout}>
                              <span>Process Payment</span>
                            </button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="button-row-btn  d-lg-none d-sm-none d-block">
                      <button onClick={confirmCheckout}>
                        <span>Done</span>
                      </button>
                    </div>
                  </div>
                </center>
              </Col>
              <Col md={4}>
                <ItemCard confirmCheckout={() => confirmCheckout()} />
              </Col>
            </Row>
          </Container>
          {/*<div className="total-due-mobile">*/}
          {/*    <div className="total-due-mobile__contents">*/}
          {/*            <h2>Total Due</h2>*/}
          {/*            <h2>{*/}
          {/*                ViewHelper.toCurrency(props.cart.amountDue, localStorage.getItem('currency_code'), props.location.decimal_places)*/}
          {/*            }</h2>*/}
          {/*    </div>*/}
          {/*</div>*/}
        </StyledCashCheckout>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    customizer: state.customizer ? state.customizer : null,
    cart: state.cartReducer ? state.cartReducer : [],
    location: state.posReducer ? state.posReducer.location : null,
    currency: state.locations ? state.locations?.location?.data?.Currency : null,
    locationData: state.locations ? state.locations?.location?.data : null,
    userCart: state.cartReducer ? state.cartReducer.cart : [],
    promotions: state.posReducer ? state.posReducer?.promotionsData : [],
    orderType: state.cartReducer ? state.cartReducer.orderType : '',
    checkout: state.cartReducer ? state.cartReducer.checkout : '',
    customer: state.cartReducer ? state.cartReducer.customer : [],
    table: state.cartReducer ? state.cartReducer.table : '',
    customerSelectedLocation: state.users.customerSelectedLocation,
    address: state.users.customerSelectedLocation
      ? state.users.customerSelectedLocation.address
      : state.cartReducer
      ? state.cartReducer.address
      : '',
    orderType: state.cartReducer ? state.cartReducer.orderType : '',
    orderInfo: state.cartReducer ? state.cartReducer : null
  };
}

export default connect(mapStateToProps, {
  setCheckout,
  addCartAmountReceived,
  setOrderPaymentType,
  collapseSidebar,
  createOrder,
  createOrderWithoutUser,
  updateOrder,
  updateOrderWithoutUser,
  setStateValue
})(CashCheckout);
