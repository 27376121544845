import Select from 'react-select';
import { Card } from 'reactstrap';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import styled from 'styled-components';

export const StyledModalOrderType = styled(Rodal)`
  .rodal-dialog {
    @media (min-width: 769px) {
      width: fit-content !important;
    }
  }

  background: rgba(49, 49, 49, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    box-shadow: unset;
    width: fit-content;
    height: 100%;
    background: transparent !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .customer-modal {
    width: fit-content;
    height: fit-content;
    background: ${(props) => (props.theme === 'dark' ? '#262C49' : '#FFFFFF')};
    padding: 32px;
    display: flex;
    height: ${(props) => (props.isLandspace === true ? '90%' : '80%')};
    flex-direction: column;
    overflow: auto;
    outline: unset;
    width: 457px;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 32px;

      h2 {
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        font-family: Larsseit, sans-serif !important;
        color: ${(props) => (props.theme === 'dark' ? '#FFFFFF' : '#313131')};
        margin: 0;
      }

      button {
        font-family: Larsseit, sans-serif !important;
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#A5A5A5')};
        border: unset;
        outline: unset;
        background: unset;
        outline: unset;
      }
    }

    // button{
    //   background: ${(props) => (props.theme === 'dark' ? '#00CDBE' : '#313131')};
    //   color: #fff;
    //   width: 393px;
    //   padding: 20px 151px;
    //   margin-top: 16px;
    //   border: unset;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   outline: unset;
    // }

    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
    }

    &__contents {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      @media (max-width: 559px) {
        justify-content: unset;
        &__content-group {
          max-height: 50%;
          overflow: auto;
          /* Track */

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            background: #343d69;
            width: 4px;
            border-radius: 5px;
          }

          /* Handle */

          &::-webkit-scrollbar-thumb {
            background: #7367f0;
            border-radius: 5px;
          }

          /* Handle on hover */

          &::-webkit-scrollbar-thumb:hover {
            background: #7367f0;
          }
        }
      }

      .mt-24 {
        margin-top: 24px;
        @media (max-width: 320px) {
          margin-top: 12px;
        }
        @media (min-width: 560px) and (max-width: 768px) {
          margin-top: 0;
        }
      }

      &__content-group {
        display: flex;
        margin-bottom: 24px;
        /* flex: 0 0 33%; */
        flex-wrap: wrap;
        justify-content: flex-start;
        column-gap: 16px;

        button {
          max-width: 120px;
          height: 100px;
          flex: 0 0 31%;
          text-transform: capitalize;
          background: #10163a;
          border-radius: 3px;
          font-weight: normal;
          line-height: 18px;
          border: unset;
          outline: unset;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          row-gap: 10px;

          p {
            font-family: Larsseit, sans-serif !important;
            font-size: 16px;
            line-height: 18px;
            font-weight: normal;
            color: #c2c6dc;
          }
        }

        .selected {
          background: #6057cc;
        }

        @media (max-width: 320px) {
          column-gap: 12px;
        }
      }

      .proceed {
        background: #00cdbe;
        color: #fff;
        width: 100%;
        padding: 20px 0;
        margin-top: 16px;
        border: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: unset;
      }
    }
  }

  @media (max-width: 768px) {
    .customer-modal {
      padding: 16px;
      width: 100%;

      &__contents {
        &__content-group {
          button {
            flex: 0 0 30%;
          }
        }
      }
    }
  }
  @media (max-width: 376px) {
    .customer-modal {
      &__form {
        button,
        input {
          width: 300px !important;
        }
      }
    }
  }
`;

export const StyledModalTable = styled(Rodal)`
  .rodal-dialog {
    @media (min-width: 769px) {
      width: fit-content !important;
    }
  }

  background: rgba(49, 49, 49, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    box-shadow: unset;
    width: fit-content;
    height: 100%;
    background: transparent !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .customer-modal {
    width: 457px;
    background: ${(props) => (props.theme === 'dark' ? '#262C49' : '#FFFFFF')};
    padding: 32px;
    display: flex;
    flex-direction: column;
    outline: unset;
    height: 80%;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 32px;

      h2 {
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        font-family: Larsseit, sans-serif;
        color: ${(props) => (props.theme === 'dark' ? '#FFFFFF' : '#313131')};
        margin: 0;
      }

      button {
        font-family: Larsseit, sans-serif;
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#A5A5A5')};
        border: unset;
        outline: unset;
        background: unset;
        outline: unset;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
    // button{
    //   background: ${(props) => (props.theme === 'dark' ? '#00CDBE' : '#313131')};
    //   color: #fff;
    //   width: 393px;
    //   padding: 20px 151px;
    //   margin-top: 16px;
    //   border: unset;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   outline: unset;
    // }
    &__contents {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;

      .mt-24 {
        margin-top: 24px;
      }

      &__content-group {
        display: flex;
        margin-bottom: 52px;
        /* flex: 0 0 33%; */
        overflow: auto;
        flex-wrap: wrap;
        //justify-content: space-between;
        column-gap: 16px;

        @media (max-width: 768px) {
          justify-content: center;
        }
        @media (max-width: 356px) {
          column-gap: 14px;
        }
        /* Track */

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: #343d69;
          width: 4px;
          border-radius: 5px;
        }

        /* Handle */

        &::-webkit-scrollbar-thumb {
          background: #7367f0;
          border-radius: 5px;
        }

        /* Handle on hover */

        &::-webkit-scrollbar-thumb:hover {
          background: #7367f0;
        }

        &__content {
          cursor: pointer;
          width: 120px;
          flex: 0 0 30%;
          background: #10163a;
          border-radius: 3px;
          font-family: Larsseit, sans-serif;
          font-size: 16px;
          color: #ffffff;
          font-weight: normal;
          line-height: 18px;
          border: unset;
          outline: unset;
          padding: 13px;
          margin-bottom: 16px;
          text-align: center;
          display: flex;
          flex-direction: column;
          row-gap: 16px;
          align-items: center;
          height: 100px;
          justify-content: center;

          h2,
          h3,
          h4 {
            margin: 0;
            width: fit-content;
            font-family: Larsseit, sans-serif;
            text-align: center;
            line-height: 16px;
          }

          h3 {
            font-size: 12px;
            font-weight: 300;
            color: #00cdbe;
          }

          h2 {
            font-size: 16px;
            font-weight: normal;
            color: #ffffff;
            //opacity: 0.6;
          }

          h4 {
            color: #c2c6dc;
            font-size: 10px;
            font-weight: normal;
          }
        }

        .selected {
          background: #6057cc;
        }

        .occupied {
          background: rgba(194, 198, 220, 0.8);

          h3 {
            color: #10163a;
            opacity: 0.4;
          }

          h2 {
            opacity: 0.6;
          }

          pointer-events: none;
        }
      }

      .proceed {
        background: #00cdbe;
        color: #fff;
        width: 100%;
        padding: 20px 151px;
        margin-top: 16px;
        margin-bottom: 40px;
        border: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: unset;
      }
    }
  }

  @media (max-width: 768px) {
    .customer-modal {
      padding: 16px;
      width: 100%;

      &__contents {
        &__content-group {
          &__content {
            flex: 0 0 30%;
          }
        }
      }
    }
  }
  @media (max-width: 376px) {
    .customer-modal {
      &__form {
        button,
        input {
          width: 300px !important;
        }
      }
    }
  }
`;

export const StyledOrderList = styled.div`
  display: flex;
  background: ${(props) => (props.theme === 'dark' ? '#262C49' : '#E9E9E9')};
  padding-bottom: 50px;
  .MuiMenu-paper {
    width: 85px !important;
  }
  .container {
    margin-top: 100px;
    max-width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    @media (max-width: 1120px) {
      max-width: unset;
      width: 92%;
    }
    @media (min-width: 769px) and (max-width: 991px) {
      max-width: 91%;
    }
    @media (max-width: 768px) {
      max-width: 100% !important;
      padding-right: 20px;
      padding-left: 20px;
      padding-bottom: 150px;
      margin-right: 0;
      margin-left: 0;
      margin-top: 78px;
      width: 100%;
    }
  }

  .sorting {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    @media (max-width: 768px) {
      margin-bottom: 0;
    }
    .sort-orders {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      @media (max-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
      }
      //.MuiSelect-icon{
      //  top: unset;
      //  color: #262C49;
      //  margin-right: 15px;
      //}
      h2 {
        display: none;
        color: #c2c6dc;
        font-size: 12px;
        font-weight: normal;
        line-height: 15px;
        font-family: Larsseit, sans-serif;
        margin-bottom: 4px;
        @media (max-width: 768px) {
          display: block;
        }
      }
      .Dropdown-root {
        .Dropdown-menu {
          background: #10163a;
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;
          border-color: #10163a;
          top: 96%;
          padding: 0 16px 16px 16px;
          max-height: fit-content;
          .is-selected {
            background: transparent !important;
          }
          .Dropdown-option {
            font-size: 16px;
            font-family: Larsseit, sans-serif;
            font-weight: normal;
            color: #c2c6dc;
            line-height: 20px;
            margin: 0;
            padding: 0 0 12px 0;

            &:hover {
              background: none;
            }
            &:nth-of-type(3) {
              padding-bottom: 0;
            }
            &:nth-of-type(1) {
              padding-top: 16px;
              border-top: 1px solid #e1e4e6;
            }
          }
        }
        .Dropdown-control {
          background: #10163a;
          border: unset;
          border-radius: 5px;
          height: 50px;
          width: 212px;
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 8px 52px 8px 16px;

          .Dropdown-placeholder {
            font-size: 16px;
            font-family: Larsseit, sans-serif;
            font-weight: normal;
            color: #c2c6dc;
            line-height: 20px;
            margin: 0;
          }
        }
        .Dropdown-arrow-wrapper {
          position: absolute;
          top: 50%;
          right: 12px;
          transform: translate(-50%, -50%);
        }
      }
      &__filter {
        display: none;
        .Dropdown-root {
          .Dropdown-menu {
            .Dropdown-option {
              &:nth-of-type(3) {
                padding-bottom: 12px;
              }
              &:nth-of-type(7) {
                padding-bottom: 0;
              }
            }
          }
        }
        @media (max-width: 768px) {
          display: block;
        }
      }
      @media (max-width: 768px) {
        .MuiPaper-root {
          left: 0 !important;
          border-radius: 0;
        }
      }

      .MuiFormControl-root {
        height: fit-content;
        background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#C2C6DC')};
        border-radius: 5px;
        font-family: Larsseit, sans-serif;
        width: 212px;
        .MuiSelect-root {
          padding: 14px 24px 14px 15px;
          font-size: 16px;
          line-height: 20px;
          font-weight: normal;
          font-family: Larsseit, sans-serif;
          color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#262c49')};

          @media (max-width: 768px) {
            padding-right: 35px;
            padding-left: 20px;
          }
        }

        @media (max-width: 768px) {
          position: fixed;
          width: 100%;
          left: 0;
          border-radius: 0;
          top: 64px;
          z-index: 999;
        }
      }

      .MuiSelect-icon {
        top: 50%;
        right: -8px;
        transform: translate(-50%, -50%);
        color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : 'rgba(0, 0, 0, 0.54)')};
        @media (max-width: 768px) {
          right: 3px;
        }
      }
    }

    .filter-orders {
      display: flex;
      text-align: center;
      justify-content: flex-start;
      align-items: center;
      overflow: auto;
      overflow: auto;
      column-gap: 8px;
      //width: 60%;
      @media (max-width: 768px) {
        position: fixed;
        z-index: 5;
        bottom: 0;
        overflow-x: auto;
        width: 90%;
        display: none;
      }

      &__order {
        width: fit-content;
        height: fit-content;
        padding: 15px;
        background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#fff')};
        height: 80px;
        width: 104px;
        cursor: pointer;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        row-gap: 6px;
        align-items: center;

        h2 {
          font-family: Larsseit, sans-serif;
          font-size: 12px;
          line-height: 16px;
          font-weight: normal;
          color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#262C49')};
          text-align: center;
          //padding-top: 8px;
          margin: 0;
        }
        img {
          width: 24px;
          height: 24px;
        }
      }

      .active-order {
        background: ${(props) => (props.theme === 'dark' ? '#6057CC' : '#313131')};

        h2 {
          color: #fff;
        }
      }
    }
  }

  .order-tabs-root {
    .MuiTabs-flexContainer {
      justify-content: space-between;

      .MuiTab-root {
        min-width: 50% !important;
        background: #ffffff;
        color: #dddddd;
        border-radius: 5px;
      }

      .Mui-selected {
        background: #dddddd;
        color: #000;
        border-radius: 5px;
      }
    }

    .PrivateTabIndicator-colorPrimary-2 {
      background-color: unset !important;
    }
  }

  .MuiPaper-root {
    border-radius: 5px;
    box-shadow: unset;
    cursor: pointer;
    background: #10163a;

    .MuiButtonBase-root {
      background: #10163a;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      min-height: fit-content;
      padding-top: 16px;
      padding-bottom: ${(props) => (props.theme === 'dark' ? '' : '16px')};
    }

    .MuiAccordionSummary-content {
      border-bottom: ${(props) => (props.theme === 'dark' ? '1px solid #C2C6DC' : 'unset')};
      padding-bottom: ${(props) => (props.theme === 'dark' ? '16px' : '')};
      //padding-bottom: 10px;
      //border-bottom: unset;
      margin: unset;

      .header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .accepted {
          color: #00cdbe;
        }
        h2 {
          font-weight: 400;
          font-size: 20px;
          line-height: 20px;
          color: ${(props) => (props.theme === 'dark' ? '#8176F5' : '#fff')};
          margin-bottom: unset;
          font-family: Larsseit, sans-serif;
        }

        &__buttons {
          display: flex;
          column-gap: 8px;

          button {
            padding: unset;
            background: #6057cc !important;
            padding: 8px 12px;
            color: #ffffff;
            border-radius: 5px;
            font-family: Larsseit, sans-serif;
            width: 88px;
            height: 32px;
            &:hover {
              box-shadow: unset;
              color: #313131 !important;
            }
          }

          .reject {
            background: #ea5455 !important;
            color: #fff;
            &:hover {
              color: #fff !important;
            }
          }
        }

        &__complete {
          display: flex;
          column-gap: 10px;
          align-items: center;

          button {
            color: ${(props) => (props.theme === 'dark' ? '#FFFFFF' : '#10163a !important')};
            font-size: 12px;
            font-family: Larsseit, sans-serif;
            line-height: 14px;
            width: 96px;
            height: 32px;
            font-weight: normal;
            border-radius: 3px;
            padding: 8px 10px;
            background: ${(props) =>
              props.theme === 'dark' ? '#00CDBE !important' : '#e1e4e6 !important'};
          }

          .MuiFormControl-root {
            width: 120px;
            height: 32px;
            background: ${(props) => (props.theme === 'dark' ? '#262c49' : '#E1E4E6')};
            border-radius: 5px;

            .MuiSelect-root {
              padding: 8px 24px 8px 8px;
              font-size: 12px;
              line-height: 14px;
              font-weight: normal;
              border-radius: 5px;
              background: ${(props) => (props.theme === 'dark' ? '#262c49' : '#FFFFFF')};
              font-family: Larsseit, sans-serif;
              color: ${(props) => (props.theme === 'dark' ? '#00CDBE' : '#262c49')};
            }
            svg {
              color: ${(props) => props.theme === 'dark' && '#C2C6DC'};
            }
          }

          .MuiSelect-icon {
            top: 50%;
            right: -8px;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
    .MuiCollapse-root {
      background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#FFF')};
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .MuiAccordionDetails-root {
      padding-top: ${(props) => (props.theme === 'dark' ? '24px' : '8px')};
      display: block;
      background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#FFFFFF')};
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      h2 {
        font-size: 14px;
        font-weight: 400;
        color: #676767;

        &:first-child {
          display: block;
          width: 100%;
        }

        margin: 0;

        span {
          color: #7367f0;
        }
      }

      .date,
      .time {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        h2 {
          width: 50%;

          &:nth-of-type(2) {
            text-align: right;
          }
        }
      }

      .order-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: fit-content;
        margin-top: 12px;

        &:first-child {
          margin-top: 0;
        }

        &__info {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h2 {
            font-family: Larsseit, sans-serif;
            padding-left: 12px;
            font-size: 16px;
            font-weight: normal;
            line-height: 18px;
            color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#10163a')};
          }
        }
      }

      .location,
      .total {
        margin-top: 10px;
      }
    }
  }
`;

export const StyledModaloff = styled(Rodal)`
  .rodal-dialog {
    @media (min-width: 769px) {
      width: fit-content !important;
    }
  }

  background: rgba(49, 49, 49, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    box-shadow: unset;
    width: fit-content;
    height: 100%;
    background: transparent !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .customer-modal {
    width: fit-content;
    height: fit-content;
    background: ${(props) => (props.theme === 'dark' ? '#262C49' : '#FFFFFF')};
    padding: 32px;
    display: flex;
    flex-direction: column;
    outline: unset;
    height: ${(props) => (props.isLandspace === true ? '90% !important' : '90%')};
    overflow: auto;
    width: 457px;

    @media (max-width: 768px) {
      height: 100%;
    }

    @media (max-width: 425px) {
      width: 100%;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 32px;

      h2 {
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        font-family: Larsseit, sans-serif !important;
        color: ${(props) => (props.theme === 'dark' ? '#FFFFFF' : '#313131')};
        margin: 0;
      }

      button {
        font-family: Larsseit, sans-serif !important;
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#A5A5A5')};
        border: unset;
        outline: unset;
        background: unset;
        outline: unset;
      }
    }

    &__form {
      height: 100%;
      justify-content: space-between;
      display: flex;
      flex-direction: column;

      &__input_group {
        display: flex;
        flex-direction: column;

        input {
          margin-bottom: 24px;
          outline: unset;
          color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#B7B7B7')};
          padding: 15px 16px;
          width: 393px;
          background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#fff')};
          border: ${(props) => (props.theme === 'dark' ? 'unset' : '1px solid #DBDBDB')};
          border-radius: 3px;

          &:last-child {
            margin-bottom: 0;
          }

          &::placeholder {
            color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#45485B')};
            font-size: 12px;
            font-weight: 300;
            line-height: 18px;
            font-family: Larsseit, sans-serif !important;
          }

          &:focus {
            color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#B7B7B7')};
          }

          &:hover {
            color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#B7B7B7')};
          }
        }

        &__label {
          font-size: 14px;
          font-weight: 300;
          color: #c2c6dc;
          line-height: 18px;
          margin-bottom: 10px;
        }
      }

      button {
        background: ${(props) => (props.theme === 'dark' ? '#00CDBE' : '#313131')};
        color: #fff;
        width: 393px;
        padding: 20px 151px;
        margin-top: 16px;
        border: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: unset;
      }
    }
  }

  @media (max-width: 768px) {
    .customer-modal {
      padding: 16px;
      width: 100%;
      height: 100% !important;

      &__form {
        button,
        input {
          width: 100%;
        }
        overflow: hidden;
      }
    }
  }
  //@media(max-width: 376px){
  //  .customer-modal{
  //      &__form{
  //        button,input{
  //          width: 300px !important;
  //        }
  //      }
  //  }
  //}
`;

export const StyledModalMap = styled(Rodal)`
  .rodal-dialog {
    @media (min-width: 769px) {
      width: fit-content !important;
    }
  }

  background: rgba(49, 49, 49, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    box-shadow: unset;
    width: fit-content;
    height: 100%;
    background: transparent !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .customer-modal {
    //width: fit-content;
    //height: fit-content;
    background: ${(props) => (props.theme === 'dark' ? '#262C49' : '#FFFFFF')};
    padding: 32px;
    display: flex;
    flex-direction: column;
    outline: unset;
    height: ${(props) => (props.isLandspace === true ? '90% !important' : '80%')};
    overflow: auto;
    width: 457px;

    .modal-content-z-index {
      z-index: 10;
    }

    .input-group {
      flex-wrap: nowrap;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
    }
    input:-webkit-autofill {
      -webkit-text-fill-color: ${(props) =>
        props.theme === 'dark' ? '#C2C6DC' : '#313131'} !important;
    }

    input {
      color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#313131')} !important;
    }

    .font-color-danger {
      color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#313131')} !important;
    }

    .takeaway-map-container {
      height: 240px !important;
      position: relative;

      .map {
        flex: 8;

        .pin-icon-container {
          z-index: 0;
          position: absolute;
          color: #0f1642;
          left: calc(50% - 9px) !important;
          top: calc(50% - 9px) !important;
        }
      }
    }

    .map-container {
      height: 240px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .map {
        flex: 8;

        .pin-icon-container {
          z-index: 0;
          position: absolute;
          top: calc(50% - 42px);
          left: calc(50% - 9px);
        }
      }

      .places {
        .mt-3,
        .my-3 {
          margin-top: 1rem !important;
        }
        .places-search {
          border-radius: 0;
          height: 50px;
          border: none;
          width: 100%;
          padding: 1rem;
          color: #6c757d;
        }

        .places-search:focus,
        .places-search:active {
          outline: none;
        }

        .suggestion-item--active {
          background-color: #fafafa;
        }

        .suggestion-item {
          background-color: #ffffff;
          z-index: 10;
        }

        .suggestion-item,
        .suggestion-item--active {
          color: #6c757d;
          padding: 0.5rem;
        }

        .clear-icon {
          position: absolute;
          right: 1rem;
          top: 15px;
          color: #6c757d;
        }
      }

      .actions {
        z-index: 1;

        .select-location {
          cursor: pointer;
          color: #fff;
          padding: 1rem;
          width: 100%;
          text-align: center;
          background-color: var(--primary-color);
        }
      }
    }

    @media (max-width: 768px) {
      height: 100% !important;
      width: 100% !important;
    }

    @media (max-width: 425px) {
      width: 100%;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 32px;

      h2 {
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        font-family: Larsseit, sans-serif !important;
        color: ${(props) => (props.theme === 'dark' ? '#FFFFFF' : '#313131')};
        margin: 0;
      }

      button {
        font-family: Larsseit, sans-serif !important;
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#A5A5A5')};
        border: unset;
        outline: unset;
        background: unset;
        outline: unset;
      }
    }

    &__form {
      height: 100%;
      justify-content: space-between;
      display: flex;
      flex-direction: column;

      &__input_group {
        width: 100%;
        .form-group {
          display: flex;
          flex-direction: column;

          input {
            margin-bottom: 5px;
            outline: unset;
            color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#B7B7B7')};
            padding: 15px 16px;
            background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#fff')};
            border: ${(props) => (props.theme === 'dark' ? 'unset' : '1px solid #DBDBDB')};
            border-radius: 3px;
            min-height: 36px;

            &:last-child {
              margin-bottom: 0;
            }

            &::placeholder {
              color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#45485B')};
              font-size: 12px;
              font-weight: 300;
              line-height: 18px;
              font-family: Larsseit, sans-serif !important;
            }

            &:focus {
              color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#B7B7B7')};
            }

            &:hover {
              color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#B7B7B7')};
            }
          }

          &__label {
            font-size: 14px;
            font-weight: 300;
            color: #c2c6dc;
            line-height: 18px;
            margin-bottom: 10px;
          }
        }
      }

      .btn-add {
        background: ${(props) => (props.theme === 'dark' ? '#00CDBE' : '#313131')};
        color: #fff;
        margin-top: 16px;
        padding-top: 10px;
        padding-bottom: 8px;
        border: unset;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: unset;
      }
    }

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  @media (max-width: 768px) {
    .customer-modal {
      padding: 16px;

      &__form {
        button,
        input {
          width: 100%;
        }
      }
    }
  }
`;

export const StyledDiv = styled.div`
  position: relative;
  .css-6j8wv5-Input {
    padding-left: 0 !important;
    height: 100%;
  }
  .css-2613qy-menu {
    padding-left: 0 !important;
    z-index: 1200;
  }
  .input-field-div {
    position: absolute;
    min-width: 250px;
    z-index: 1;
    left: 40px;

    @media (max-width: 768px) {
      position: unset;
    }
  }
`;

export const StyledCheckoutDone = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  background: ${(props) => (props.theme === 'dark' ? '#262C49' : '#EEEEEE')};

  @media (max-width: 768px) {
    height: 100vh;
    flex-direction: column;
  }

  .invoice-component {
    display: none;
    @media print {
      margin-top: 50px;
      display: block;
    }
  }

  .container {
    //margin-top: 64px;
    //max-width: calc(100% - 84px);
    max-width: 100%;
    padding-right: 0;
    //overflow-x: hidden;
    height: fit-content;

    @media (max-width: 768px) {
      overflow-x: unset;
      //max-width: 100%;
      padding-right: 20px;
      padding-left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0;
      margin-left: 0;
      max-width: 100%;

      padding-top: 100px;
      .row {
        width: 100%;

        .col-md-8 {
          padding-right: 0;
          padding-left: 0;
          max-width: 100%;
          flex: 0 0 100%;
        }
      }
    }
  }

  .checkout-done {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 370px;
    @media (max-width: 768px) {
      position: unset;
      top: unset;
      left: unset;
      transform: unset;
    }
    @media (max-width: 425px) {
      width: 100%;
    }

    .amount {
      display: flex;
      align-items: center;
      margin-bottom: 50px;
      justify-content: ${(props) => props.paymentType !== 'Cash' && 'center'};
      justify-content: center;

      div {
        p {
          font-size: 16px;
          line-height: 20px;
          color: ${(props) => (props.theme === 'dark' ? '#fff' : '#313131')};
          font-family: Larsseit, sans-serif !important;
          font-weight: 300;
          text-align: center;
        }

        &:nth-of-type(1) {
          margin-right: ${(props) => (props.paymentType === 'Cash' ? '35px' : '0')};

          p {
            font-family: Larsseit, sans-serif !important;

            &:nth-of-type(2) {
              color: #00cdbe;
              font-size: 24px;
              line-height: 20px;
              font-weight: 500;
              margin-bottom: 0;
            }
          }
        }

        &:nth-of-type(2) {
          padding-left: 35px;
          border-left: 1px solid #a5a5a5;

          p {
            font-font: Larsseit, sans-serif !important;

            &:nth-of-type(2) {
              color: #ea5455;
              font-size: 24px;
              line-height: 20px;
              font-weight: 500;
              margin-bottom: 0;
            }

            &:nth-of-type(1) {
              margin-bottom: 10px;
            }
          }
        }
      }

      @media (max-width: 768px) {
        justify-content: center;
      }
    }

    .receipt {
      display: flex;
      justify-content: center;
      column-gap: 20px;

      div {
        cursor: pointer;
        width: 100px;
        //border: 1px solid #6057CC;
        background: #6057cc;
        padding: 14px 26px;
        border-radius: 2px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
          display: block;
          text-align: center;
          color: #fff;
          font-family: Larsseit, sans-serif !important;

          &:first-child {
            margin-bottom: 10px;
          }

          &:nth-of-type(2) {
            line-height: 16px;
          }
        }
      }
    }

    .select {
      div {
        background: #00cdbe !important;
        border: 1px solid #00cdbe;
      }
    }

    button {
      background: #00cdbe;
      width: 100%;
      padding: 19px 20px;
      color: #fff;
      border: unset;
      margin-top: 30px;
      font-family: Larsseit, sans-serif !important;
      outline: none;

      @media (max-width: 768px) {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .due-amount {
    display: none;
    @media (max-width: 768px) {
      display: flex;
      padding: 20px;
      justify-content: space-between;
      margin-top: 56px;
      border-top: 1px solid ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#B7B7B7')};
      align-items: center;
      h2 {
        font-family: Larsseit, sans-serif !important;
        font-weight: 500;
        color: ${(props) => (props.theme === 'dark' ? '#FFFFFF' : '#313131')};
        line-height: 20px;
        font-size: 16px;
        margin: 0;

        &:nth-of-type(2) {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
`;

export const StyledCashCheckout = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  background: ${(props) => (props.theme === 'dark' ? '#262C49' : '#EEEEEE')};

  //padding-bottom: 150px;
  @media (max-width: 768px) {
    flex-direction: column;
    .col-md-4 {
      display: none;
    }
    .col-md-8 {
      margin: 0 auto;
    }
  }

  .container {
    //margin-top: 64px;
    //max-width: calc(100% - 84px);
    max-width: 100%;
    padding-right: 0;
    overflow-x: hidden;
    //padding-bottom: 250px;
    .col-md-4 {
      position: fixed;
      right: -14px;
    }
    @media (max-width: 768px) {
      overflow-x: unset;
      max-width: 100%;
      padding-right: 24px;
      padding-left: 24px;
    }

    .col-md-4 {
      overflow: hidden;
    }
  }

  .calculator-parent {
    width: fit-content;
    position: relative;
    overflow: hidden;
    // height: ${`${window.innerHeight - 64}px`};
    @media (max-width: 376px) {
      width: 100% !important;
    }
    @media (min-width: 377px) and (max-width: 414px) {
      width: 100% !important;
    }

    .calculator-wrapper {
      height: 100%;
      margin-top: 50px;
      //padding-bottom: 90px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 0px;
      }

      &::-webkit-scrollbar-track {
        background: #343d69;
        width: 4px;
        border-radius: 5px;
      }

      /* Handle */

      &::-webkit-scrollbar-thumb {
        background: #7367f0;
        border-radius: 5px;
      }

      /* Handle on hover */

      &::-webkit-scrollbar-thumb:hover {
        background: #7367f0;
      }
    }

    .due-amount {
      padding-top: 30px;

      p {
        text-align: center;
        font-family: Larsseit, sans-serif !important;
        color: ${(props) => (props.theme === 'dark' ? '#fff' : '#313131')};

        &:nth-of-type(1) {
          font-size: 16px;
          font-family: Larsseit, sans-serif !important;
          font-weight: 300;
          line-height: 20px;
          margin-bottom: 4px;
        }

        &:nth-of-type(2) {
          font-size: 24px;
          line-height: 20px;
          font-weight: 500;
          margin-bottom: 20px;
          color: #00cdbe;
          font-family: Larsseit, sans-serif !important;
        }
      }
    }

    .calculator {
      width: fit-content;
      height: 630px !important;
      @media (max-width: 376px) {
        width: 100% !important;
      }
      @media (min-width: 377px) and (max-width: 414px) {
        width: 100% !important;
      }
      @media (max-width: 375px) {
        height: 600px !important;
      }
    }

    .inactive-calculator {
      .calculator-buttons {
        .button-row {
          button {
            background: #6c757d !important;
          }
        }
      }
    }

    .amount-received-inactive {
      background: #c2c6dc !important;
      border-color: #c2c6dc !important;

      p {
        &:nth-of-type(1) {
          color: #313131a6 !important;
        }
      }
    }

    .amount-received {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #e1e4e6;
      background: #ffffff;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      padding: 20px;
      font-family: Larsseit, sans-serif !important;
      color: #313131;

      &__left-side {
        display: flex;
        align-items: center;
        column-gap: 4px;

        img {
          display: none;
          cursor: pointer;
          @media (max-width: 768px) {
            display: block;
          }
        }

        p {
          font-size: 12px;
          font-family: Larsseit, sans-serif !important;
          line-height: 20px;
          font-weight: 300;
          margin-bottom: 0;
          font-family: Larsseit, sans-serif !important;
          color: #313131;
        }
      }

      p {
        margin-bottom: 0;
        font-family: Larsseit, sans-serif !important;
        color: #313131;

        &:nth-of-type(2) {
          font-size: 20px;
          font-family: Larsseit, sans-serif !important;
          line-height: 20px;
          font-weight: normal;
        }
      }
      &__amount {
        font-size: 20px;
      }
    }

    .calculator-buttons {
      .payment-method {
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        margin: 16px 0;
        font-weight: 300;
        font-family: Larsseit, sans-serif !important;
        color: ${(props) => (props.theme === 'dark' ? '#fff' : '#313131')};
      }

      .payment-methods {
        display: flex;
        justify-content: space-between;

        div {
          cursor: pointer;
          width: 100px;
          border: 1px solid #6057cc;
          background: #6057cc;
          padding: 14px 26px;
          border-radius: 2px;
          height: 100px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          @media (max-width: 375px) {
            width: 86px;
            height: 90px;
          }

          span {
            display: block;
            text-align: center;
            color: #ffffff;
            font-family: Larsseit, sans-serif !important;

            &:first-child {
              margin-bottom: 10px;
            }
          }
        }

        .selected-payment-method {
          border: 1px solid #00cdbe;
          background: #00cdbe;
        }
      }

      .button-row {
        display: flex;
        justify-content: space-between;

        button {
          padding: 20px 32px;
          width: 124px;
          border: 1px solid #262c49;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          color: ${(props) => (props.theme === 'dark' ? '#FFFFFF' : '#313131')};
          background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#fff')};
          font-family: Larsseit, sans-serif !important;
          display: flex;
          justify-content: center;
          align-items: center;
          outline: unset;
        }

        &:first-child {
          button {
            background: #00cdbe;
            color: #ffffff;
            @media (max-width: 376px) {
              padding: 20px 16px;
            }
          }
        }

        &:last-child {
          button {
            width: 100%;
            margin-top: 20px;
            background: ${(props) => (props.theme === 'dark' ? '#00CDBE' : '#313131')};
            border: unset;

            span {
              font-size: 16px;
              line-height: 20px;
              font-weight: 400;
              color: #ffffff;
              font-family: Larsseit, sans-serif !important;
            }
          }
        }
      }

      .button-row-inner {
        display: flex;
        justify-content: space-between;

        button {
          padding: 20px 32px;
          width: 124px;
          border: 1px solid ${(props) => (props.theme === 'dark' ? '#262C49' : '#E1E4E6')};
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          color: ${(props) => (props.theme === 'dark' ? '#FFFFFF' : '#313131')};
          background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#fff')};
          font-family: Larsseit, sans-serif !important;
          display: flex;
          justify-content: center;
          align-items: center;
          outline: unset;

          @media (max-width: 768px) {
            height: 50px;
          }
        }

        &:first-child {
          button {
            background: ${(props) => (props.theme === 'dark' ? '#00CDBE' : '#C2C6DC')};
            @media (max-width: 376px) {
              padding: 20px 16px;
            }
          }
        }

        &:last-child {
          button {
            width: 100%;
            margin-top: 20px;
            background: #00cdbe;
            border: unset;

            span {
              font-size: 16px;
              line-height: 20px;
              font-weight: 400;
              color: #ffffff;
              font-family: Larsseit, sans-serif !important;
            }
          }
        }

        @media (max-height: 760px) {
          display: none;
        }
      }
    }

    .button-row-btn {
      display: none;
      justify-content: space-between;
      position: absolute;
      bottom: 20px;
      background: ${(props) => (props.theme === 'dark' ? '#262C49' : '#EEEEEE')};
      width: 100%;

      button {
        padding: 20px 32px;
        width: 124px;
        border: 1px solid ${(props) => (props.theme === 'dark' ? '#262C49' : '#E1E4E6')};
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: ${(props) => (props.theme === 'dark' ? '#FFFFFF' : '#313131')};
        background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#fff')};
        font-family: Larsseit, sans-serif !important;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: unset;
      }

      &:first-child {
        button {
          background: #00cdbe;
          color: #ffffff;
          @media (max-width: 376px) {
            padding: 20px 16px;
          }
        }
      }

      &:last-child {
        button {
          width: 100%;
          margin-top: 20px;
          background: #00cdbe;
          border: unset;

          span {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            color: #ffffff;
            font-family: Larsseit, sans-serif !important;
          }
        }
      }

      @media (max-height: 760px) {
        display: flex;
      }
    }
  }

  .total-due-mobile {
    border-top: 1px solid #b7b7b7;
    margin-top: 56px;
    display: none;
    //display: flex;
    //justify-content: space-between;
    //position: relative;
    padding-bottom: 100px;
    @media (max-width: 768px) {
      display: block;
    }

    &__contents {
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* padding-top: 20px; */
      padding: 20px;

      h2 {
        font-size: 16px;
        color: ${(props) => (props.theme === 'dark' ? '#FFFFFF' : '#313131')};
        font-weight: 500;
        line-height: 20px;
        font-family: Larsseit, sans-serif !important;
        margin: 0;

        &:nth-of-type(2) {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
`;

export const StyledSidebar = styled.div`
  .pro-sidebar {
    @media (max-width: 768px) {
      display: none;
    }
    //margin-top: 80px;
    height: 100%;
    min-width: 84px;
    width: 84px;

    .pro-sidebar-inner {
      background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#313131')};
      position: fixed;
      width: 84px;
      .pro-menu-item {
        border-bottom: 1px solid #707070;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &:first-child {
          height: 64px;
        }
        .pro-item-content {
          display: flex;
          align-items: center;
          margin-left: 20px;
          font-size: 12px;
          //color: #585858;
          color: #a5a5a5;
          transition: 0.3s all ease;
          font-family: Larsseit, sans-serif;
          &:hover {
            color: #7367f0;
          }
          span {
            margin-left: 20px;
            font-size: 12px;
            //color: #585858;
            color: #a5a5a5;
            transition: 0.3s all ease;
            font-family: Larsseit, sans-serif;
            &:hover {
              color: #7367f0;
            }
          }
        }
      }
      .pro-sidebar-layout {
        &::-webkit-scrollbar {
          width: 0;
        }
      }
    }
    .pro-menu {
      padding: unset;
      .pro-menu-item {
        .pro-inner-item {
          padding: 16px 0;
          justify-content: center;
          flex-direction: column;
          .pro-icon-wrapper {
            background-color: unset;
            margin-right: 0;
            @media (max-width: 768px) {
              margin-left: 4px;
            }
            .pro-icon {
              @media (max-width: 768px) {
                //justify-content: flex-end !important;
              }
            }
          }
          .pro-arrow-wrapper {
            position: absolute;
            right: -14px;
            top: 59px;
            transform: translateY(-50%);
            height: fit-content;
            width: fit-content;
            display: none;
            @media (max-width: 768px) {
              display: block;
            }
          }
        }
        .pro-inner-list-item {
          background-color: unset !important;
          padding-left: 0;
          width: 100%;
          .pro-inner-item {
            padding: 8px 0;
            text-align: center;
          }
          ul {
            padding-top: unset !important;
            li {
              display: block !important;
              height: fit-content;
              border-bottom: unset !important;
            }
          }
        }

        .pro-item-content {
          flex-direction: column;
          margin-left: 0;
          span {
            margin-left: 0;
            text-align: center;
          }
        }
      }
    }
  }
  .collapsed {
    min-width: 80px !important;
    width: 80px !important;
  }
`;

export const CustomCardNotify = styled(Card)`
  .card-body {
    padding: 30px;
  }

  .select-sound {
    margin-bottom: 70px;

    label {
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      color: #c2c6dc;
    }

    .vx-radio-con {
      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        color: #c2c6dc;
      }
    }
  }

  .select-sound-mode {
    margin-bottom: 50px;

    label {
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      color: #c2c6dc;
    }

    .vx-radio-con {
      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        color: #c2c6dc;
      }
    }
  }

  .play-sound-btn {
    border-radius: 5px;
    background: transparent !important;
    width: 100%;
    min-width: 175px;
    height: 50px;
    border: 1px solid #bcc0d6;
    border-color: #bcc0d6 !important;
    display: flex;
    column-gap: 20px;
    padding: 16px 25px;
    margin-bottom: 15px;
  }
`;

export const StyledToastifyNotify = styled.div`
  .setting-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 14px;
    color: #ffffff;
    margin-bottom: 40px;
  }

  /*Notification Toast Popup*/

  .Toastify__toast-container {
    @media (max-width: 477px) {
      padding-top: 81px !important;
    }
  }

  .Toastify__toast-container--top-right {
    right: -23px;
    @media (max-width: 501px) {
      right: -38px;
    }
  }

  .custom-toast {
    color: white;
    background: #7065eb;
    height: 36px;
    width: 260px;
  }

  .toast-button {
    float: right;
    cursor: pointer;
    background: none;
    border: none;
  }

  .Toastify__toast {
    position: absolute !important;
    min-height: 36px;
    box-sizing: border-box;
    /* margin-bottom: 0rem; */
    margin: 0px;
    padding: 0px;
    border-radius: 5px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    box-shadow: 0 1px 0px 0 rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%);
    //right: 18px;
    @media (max-width: 477px) {
      right: 18px;
    }
  }

  .Toastify__toast-body {
    margin: auto 0 !important;
    flex: 1 1 auto;
    padding: 6px !important;
    display: flex;
    align-items: start !important;
  }

  .toast-button1 {
    padding-top: 10px;
    position: absolute;
    right: -124px;
    top: -9px;
    z-index: 2;
  }

  .toast-button2 {
    padding-right: 20px;
    /*padding-top: 9px;*/
  }

  .toast-button2-hide {
    opacity: 0;
  }

  .toast-text {
    font-size: 12px !important;
    font-weight: 600;
    padding-top: 4px !important;
    padding-left: 10px !important;
    @media (max-width: 477px) {
      padding-left: 28px !important;
    }
  }
`;

export const StyledSelect = styled(Select)`
  .select {
    &__control {
      background: #10163a !important;
      border-color: #10163a !important;

      .select {
        &__indicator-separator {
          display: none;
        }

        &__placeholder {
          color: #c2c6d6;
        }

        &__input-container {
          input {
            color: #c2c6d6 !important;
          }
        }
      }
    }
  }
`;

export const StyledLiveOrders = styled.div`
  .vx-pagination li a {
    color: #c2c6dc;
    font-family: 'montserrat';
    font-size: 14px;
    font-weight: 700;
  }

  .main-menu-desktop {
    display: none;
    @media (max-width: 768px) {
      display: flex;
    }
  }

  .new-order-mobile {
    display: none;
    @media (max-width: 768px) {
      width: 100%;
      display: block;
    }
    border-radius: 3px;
    background: #00cdbe;
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    color: #ffffff;
    height: 40px;
    outline: unset;
    border: unset;
    width: 146px;
  }

  .sorting {
    padding-bottom: 24px;
    @media (max-width: 768px) {
      padding-bottom: 16px;
    }

    .filter-orders {
      display: flex;
      text-align: center;
      justify-content: flex-start;
      align-items: center;
      overflow: auto;
      overflow: auto;
      column-gap: 8px;
      //width: 60%;
      @media (max-width: 768px) {
        position: fixed;
        z-index: 5;
        bottom: 0;
        overflow-x: auto;
        width: 90%;
        display: none;
      }

      &__order {
        width: fit-content;
        height: fit-content;
        padding: 15px;
        background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#fff')};
        height: 80px;
        width: 104px;
        cursor: pointer;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        row-gap: 6px;
        align-items: center;
        justify-content: center;

        h2 {
          font-family: Larsseit, sans-serif !important;
          font-size: 12px;
          line-height: 16px;
          font-weight: normal;
          color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#262C49')};
          text-align: center;
          //padding-top: 8px;
          margin: 0;
        }

        img {
          width: 24px;
          height: 24px;
        }
      }

      .active-order {
        background: ${(props) => (props.theme === 'dark' ? '#6057CC' : '#313131')};

        h2 {
          color: #fff;
        }
      }
    }
  }

  .live-orders {
    .filters {
      align-items: center;
      //@media(max-width: 425px){
      //  flex-direction: column;
      //  row-gap: 8px;
      //  .filter-data,.form-group{
      //    width: 100% !important;
      //  }
      //  .filter-data{
      //    .col-md-6{
      //      padding-right: unset;
      //    }
      //  }
      //  .form-group{
      //    &:nth-of-type(2){
      //      .col-md-2,.col-md-6{
      //        padding-left: unset;
      //      }
      //    }
      //  }
      //}
      .form-group {
        margin-bottom: 0;
        @media (max-width: 768px) {
          .col-md-6 {
            max-width: 100%;
            flex: 0 0 100%;
          }
        }

        .custom-select {
          width: 100%;
        }

        &:nth-of-type(1) {
          width: 100%;
        }

        &:nth-of-type(2) {
          width: 50%;

          .col-md-6 {
            max-width: 100%;
            @media (max-width: 768px) {
              display: flex;
              justify-content: flex-end;
            }
          }
        }
      }

      .filter-data {
        display: none;

        .form-group {
          width: 100% !important;
        }

        @media (max-width: 768px) {
          display: block;
          width: 50%;
        }
      }

      .row {
        .col-md-2 {
          @media (max-width: 768px) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      .new-order {
        @media (max-width: 768px) {
          display: none;
        }
        border-radius: 3px;
        background: #00cdbe;
        font-size: 12px;
        font-weight: normal;
        line-height: 14px;
        color: #ffffff;
        height: 40px;
        outline: unset;
        border: unset;
        width: 146px;
      }

      .custom-select {
        width: 212px;
        height: 40px;
      }
    }

    .live-order {
      .row {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
`;

export const StyledOrder = styled.div`
  .payment-details-section {
    @media (max-width: 768px) {
      margin-bottom: 108px;
    }
  }
  .invoice-component {
    display: none;
    @media print {
      margin-top: 50px;
      display: block;
    }
  }

  .main-menu-desktop {
    display: none;
    @media (max-width: 768px) {
      display: flex;
    }
  }
`;

export const StyledEditOrder = styled.div`
  padding-bottom: 16px;

  @media (max-width: 768px) {
    padding-bottom: 16px;
  }

  .header {
    &__left-side {
      display: flex;
      column-gap: 15px;
      //justify-content: space-between;
      align-items: center;
      @media (max-width: 768px) {
        justify-content: space-between;
      }

      &__details {
        display: flex;
        column-gap: 24px;
        align-items: center;
      }

      img {
        cursor: pointer;
      }

      h2 {
        color: #ffffff;
        font-family: Larsseit, sans-serif;
        font-size: 20px;
        line-height: 23px;
        font-weight: 500;
        margin: 0;
      }

      &__button {
        background: ${(props) => (props.theme === 'dark' ? '#00CDBE' : '#313131')};
        color: #ffffff;
        outline: unset;
        padding: 8px 16px;
        display: flex;
        column-gap: 8px;
        align-items: center;
        justify-content: center;
        border: unset;
        border-radius: 3px;
        font-family: Larsseit, sans-serif;
        cursor: pointer;
        height: 40px;

        img {
          width: 14px;
          height: 14px;
        }

        h2 {
          font-family: Larsseit, sans-serif;
          font-size: 12px;
          font-weight: normal;
          line-height: 14px;
          color: #fff;
          margin: 0;
        }

        @media (max-width: 768px) {
          width: 50%;
        }
      }

      &__button-receipt {
        background: ${(props) => (props.theme === 'dark' ? '#6057CC' : '#313131')};
        color: #ffffff;
        outline: unset;
        padding: 8px 8px;
        display: flex;
        column-gap: 8px;
        align-items: center;
        justify-content: center;
        border: unset;
        border-radius: 3px;
        font-family: Larsseit, sans-serif;
        cursor: pointer;
        height: 40px;

        img {
          width: 14px;
          height: 14px;
        }

        h2 {
          font-family: Larsseit, sans-serif;
          font-size: 12px;
          font-weight: normal;
          line-height: 14px;
          color: #fff;
          margin: 0;
        }

        @media (max-width: 768px) {
          width: 50%;
        }
      }
    }
  }
`;

export const StyledNavbar = styled.div`
  display: ${(props) => (props.navbarInvisibility === true ? 'none' : 'block')};
  @media (max-width: 768px) {
    display: ${(props) =>
      props.path === '/'
        ? 'none'
        : props.location_id !== undefined && props.order_id !== undefined && 'none'};
  }
`;

export const commonHeaderStyle = `
    background: #10163a;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 14px 16px;
    h2{
        font-size: 16px;
        font-weight: normal;
        line-height: 18px;
        font-family: Larsseit, sans-serif;
        color: #FFFFFF;
        margin: 0;
    }
`;

export const commonContentStyle = `
    padding-bottom: 16px;
    &:last-child{
      padding-bottom: 0;
    }
    h2,p{
      color: #313131;
      font-family: Larsseit, sans-serif;
      font-size: 16px;
      line-height: 18px;
      font-weight: 500;
      margin: 0;
    }
    p{
      font-weight: 300;
    }
    h2{
      padding-bottom: 4px;
    }
`;

export const commonBorderBottomRadius = `
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
`;

export const StyledOrderDetail = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
  background: ${(props) => (props.theme === 'dark' ? '#262C49' : '#FFFFFF')};

  .container {
    //margin-top: 64px;
    //max-width: calc(100% - 84px);
    //padding-right: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;

    @media (max-width: 768px) {
      //max-width: 100%;
      //padding-right: 24px;
      //padding-left: 24px;
      //overflow-y: auto;
      padding-bottom: 100px;
      .col-md-4 {
        max-width: 100%;
        flex: 0 0 100%;
      }
      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }
  .header {
    padding-top: 16px;
    display: flex;
    //column-gap: 32px;
    align-items: center;
    padding-bottom: 24px;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      row-gap: 16px;
      justify-content: unset;
      align-items: unset;
      &__right-side {
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
    &__left-side {
      display: flex;
      column-gap: 15px;
      //justify-content: space-between;
      align-items: center;
      &__details {
        display: flex;
        column-gap: 24px;
        align-items: center;
      }
      img {
        cursor: pointer;
      }
      h2 {
        color: #ffffff;
        font-family: Larsseit, sans-serif;
        font-size: 20px;
        line-height: 23px;
        font-weight: 500;
        margin: 0;
      }
      &__button {
        background: ${(props) => (props.theme === 'dark' ? '#00CDBE' : '#313131')};
        color: #ffffff;
        outline: unset;
        padding: 8px 16px;
        display: flex;
        column-gap: 8px;
        align-items: center;
        justify-content: center;
        border: unset;
        border-radius: 3px;
        font-family: Larsseit, sans-serif;
        cursor: pointer;
        img {
          width: 14px;
          height: 14px;
        }
        h2 {
          font-family: Larsseit, sans-serif;
          font-size: 12px;
          font-weight: normal;
          line-height: 14px;
          color: #fff;
          margin: 0;
        }
      }
      &__button-receipt {
        background: ${(props) => (props.theme === 'dark' ? '#6057CC' : '#313131')};
        color: #ffffff;
        outline: unset;
        padding: 8px 16px;
        display: flex;
        column-gap: 8px;
        align-items: center;
        justify-content: center;
        border: unset;
        border-radius: 3px;
        font-family: Larsseit, sans-serif;
        cursor: pointer;
        img {
          width: 14px;
          height: 14px;
        }
        h2 {
          font-family: Larsseit, sans-serif;
          font-size: 12px;
          font-weight: normal;
          line-height: 14px;
          color: #fff;
          margin: 0;
        }
      }
    }
    &__right-side {
      button {
        background: ${(props) => (props.theme === 'dark' ? '#8176F5' : '#313131')};
        color: #ffffff;
        font-size: 12px;
        outline: unset;
        padding: 8px 16px;
        display: flex;
        column-gap: 8px;
        align-items: center;
        justify-content: center;
        border: unset;
        border-radius: 3px;
        font-family: Larsseit, sans-serif;
        cursor: pointer;
        height: 32px;
        @media (max-width: 768px) {
          height: 40px;
        }
      }
    }
  }

  .order-detail {
    &__header {
      ${commonHeaderStyle}
    }
    .order-details {
      background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#E1E4E6')};
      padding: 16px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      &__contents {
        &__content {
          ${commonContentStyle};
          h2 {
            color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#313131')};
          }
          p {
            color: ${(props) => (props.theme === 'dark' ? '#FFFFFF' : '#313131')};
          }
          &:nth-of-type(3) {
            p {
              color: #7367f0;
            }
          }
        }
      }
    }
  }
  .manage-delivery {
    padding-bottom: 24px;
    @media (max-width: 768px) {
      padding-top: 16px;
      padding-bottom: 16px;
    }
    &__header {
      ${commonHeaderStyle};
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        background: ${(props) => (props.theme === 'dark' ? '#00CDBE' : '#E1E4E6')};
        color: ${(props) => (props.theme === 'dark' ? '#FFFFFF' : '#313131')};
        font-size: 12px;
        font-weight: normal;
        line-height: 14px;
        font-family: Larsseit, sans-serif;
        border: unset;
        outline: unset;
        border-radius: 3px;
        padding: 4px 10px;
        width: 96px;
        height: 32px;
      }
    }
    &__content {
      background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#E1E4E6')};
      padding: 16px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      h2 {
        margin: 0;
        font-family: Larsseit, sans-serif;
        font-size: 16px;
        font-weight: 300;
        line-height: 18px;
        color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#313131')};
      }
    }
  }
  .menu-details {
    padding-bottom: 24px;
    @media (max-width: 768px) {
      padding-bottom: 16px;
    }
    &__header {
      ${commonHeaderStyle};
    }
    &__contents {
      background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#E1E4E6')};
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      padding: 16px;
      &__content {
        display: flex;
        justify-content: space-between;
        &:first-child {
          padding-bottom: 32px;
        }
        h2 {
          font-size: 16px;
          font-weight: 300;
          font-family: Larsseit, sans-serif;
          color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#313131')};
          line-height: 18px;
          margin: 0;
        }
        padding-bottom: 8px;
        &:last-child {
          padding-bottom: 0;
          h2 {
            font-weight: 500;
          }
        }
      }
    }
  }
  .additional-details {
    &__header {
      ${commonHeaderStyle};
    }
    &__contents {
      background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#E1E4E6')};
      padding: 16px;
      ${commonBorderBottomRadius}
      &__content {
        ${commonContentStyle};
        h2 {
          color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#313131')};
        }
        p {
          color: ${(props) => (props.theme === 'dark' ? '#FFFFFF' : '#313131')};
        }
      }
    }
  }
  .manage-status {
    padding-bottom: 24px;
    @media (max-width: 768px) {
      padding-top: 16px;
      padding-bottom: 16px;
    }
    &__header {
      ${commonHeaderStyle};
    }
    &__content {
      background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#E1E4E6')};
      padding: 16px;
      ${commonBorderBottomRadius};
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 768px) {
        position: fixed;
        bottom: 0;
        width: calc(100% - 65px);
        border-top: 1px solid #c2c6dc;
      }

      &__status {
        width: 50%;
        display: flex;
        column-gap: 8px;
        &__value {
          width: 112px;
          height: 32px;
          background: ${(props) => (props.theme === 'dark' ? '#6057CC' : '#E1E4E6')};
          display: flex;
          padding: 4px 14px 4px 8px;
          border-radius: 3px;
          align-items: center;

          h2 {
            font-size: 12px;
            font-family: Larsseit, sans-serif;
            color: ${(props) => (props.theme === 'dark' ? '#FFFFFF' : '#313131')};
            line-height: 14px;
            font-weight: normal;
            margin: 0;
            height: fit-content;
            &:nth-of-type(2) {
              margin-left: 3px;
            }
          }
        }
        .MuiSelect-select {
          padding: 0;
          background: ${(props) => (props.theme === 'dark' ? '#6057CC' : '#E1E4E6')};
          border-radius: 3px;
          width: 32px;
          height: 32px;
          color: transparent;
        }
        .MuiInput-root {
          height: 32px;
          width: 32px;
        }
        .MuiSelect-icon {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: ${(props) => (props.theme === 'dark' ? '#FFFFFF' : '#A5A5A5')};

          //@media(min-width: 769px) and (max-width: 1025px){
          //  margin-left: 7px;
          //}
          //@media(min-width: 1025px) and (max-width: 1099px){
          //  margin-left: 5px;
          //}
        }
      }
      button {
        background: ${(props) => (props.theme === 'dark' ? '#262C49' : '#10163A')};
        border: unset;
        border-radius: 3px;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: unset;
      }
    }
  }
  .status-history {
    padding-bottom: 24px;
    @media (max-width: 768px) {
      padding-bottom: 16px;
    }
    &__header {
      ${commonHeaderStyle};
    }
    &__contents {
      background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#E1E4E6')};
      padding: 16px;
      ${commonBorderBottomRadius};
      &__content {
        ${commonContentStyle};
        h2 {
          color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#313131')};
          &:nth-of-type(2) {
            font-weight: 300;
          }
        }
        p {
          color: ${(props) => (props.theme === 'dark' ? '#FFFFFF' : '#313131')};
          font-size: 12px;
          font-style: italic;
          font-weight: 300;
          font-family: Larsseit, sans-serif;
        }
      }
    }
  }
  .payment-details {
    &__header {
      ${commonHeaderStyle};
    }
    &__content {
      background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#E1E4E6')};
      h2 {
        color: ${(props) => (props.theme === 'dark' ? '#C2C6DC !important' : '#313131 !important')};
      }
      p {
        color: ${(props) => (props.theme === 'dark' ? '#FFFFFF !important' : '#313131 !important')};
      }

      padding: 16px !important;
      ${commonContentStyle};
      ${commonBorderBottomRadius};
    }
  }
  .print-receipt {
    padding-top: 24px;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 768px) {
      padding-top: 16px;
      &__item {
        width: 100% !important;
        flex-direction: row !important;
        justify-content: center !important;
        align-items: center !important;
        column-gap: 12px;
        height: fit-content !important;
        h2 {
          margin: 0;
        }
      }
    }
    &__item {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      background: #6057cc;
      width: 100px;
      height: 100px;
      padding: 14px 24px;
      border-radius: 3px;
      cursor: pointer;
      align-items: center;
      row-gap: 8px;
      img {
        width: 28px;
        height: 28px;
      }
      h2 {
        font-size: 16px;
        font-weight: normal;
        color: #ffffff;
        font-family: Larsseit, sans-serif;
        line-height: 16px;
        text-align: center;
      }
    }
  }
`;

export const VerticalLayoutStyled = styled.div`
  .content-wrapper {
    margin-top: ${(props) => props.idExists === true && '44px !important'};
    @media (max-width: 768px) {
      margin-top: ${(props) =>
        props.path === '/' ? '56px !important' : props.idExists === true && '28px !important'};
    }
  }

  .menu-swipe-area {
    width: unset !important;
  }
`;

export const StyledLayout = styled.div`
  .setting-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 14px;
    color: #ffffff;
    margin-bottom: 40px;
  }

  /*Notification Toast Popup*/

  .Toastify__toast-container {
    @media (max-width: 477px) {
      padding-top: 81px !important;
    }
  }

  .Toastify__toast-container--top-right {
    right: -23px;
    @media (max-width: 501px) {
      right: -38px;
    }
  }

  .custom-toast {
    color: white;
    background: #7065eb;
    height: 36px;
    width: 260px;
  }

  .toast-button {
    float: right;
    cursor: pointer;
    background: none;
    border: none;
  }

  .Toastify__toast {
    position: absolute !important;
    min-height: 36px;
    box-sizing: border-box;
    /* margin-bottom: 0rem; */
    margin: 0px;
    padding: 0px;
    border-radius: 5px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    box-shadow: 0 1px 0px 0 rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%);
    //right: 18px;
    @media (max-width: 477px) {
      right: 18px;
    }
    z-index: 10;
  }

  .Toastify__toast-body {
    margin: auto 0 !important;
    flex: 1 1 auto;
    padding: 6px !important;
    display: flex;
    align-items: start !important;
    z-index: 10;
    position: relative;
  }

  .toast-button1 {
    padding-top: 10px;
    position: absolute;
    right: -124px;
    top: -9px;
    z-index: 10;
  }

  .toast-button2 {
    padding-right: 20px;
    position: relative;
    z-index: 10;
    /*padding-top: 9px;*/
  }

  .toast-button2-hide {
    opacity: 0;
  }

  .toast-text {
    font-size: 12px !important;
    font-weight: 600;
    padding-top: 4px !important;
    padding-left: 10px !important;
    @media (max-width: 477px) {
      padding-left: 28px !important;
    }
  }
`;

export const CustomCard = styled(Card)`
  .card-body {
    padding: 30px;
  }

  .select-sound {
    margin-bottom: 70px;

    label {
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      color: #c2c6dc;
    }

    .vx-radio-con {
      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        color: #c2c6dc;
      }
    }
  }

  .select-sound-mode {
    margin-bottom: 50px;

    label {
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      color: #c2c6dc;
    }

    .vx-radio-con {
      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        color: #c2c6dc;
      }
    }
  }

  .play-sound-btn {
    border-radius: 5px;
    background: transparent !important;
    width: 100%;
    min-width: 175px;
    height: 50px;
    border: 1px solid #bcc0d6;
    border-color: #bcc0d6 !important;
    display: flex;
    column-gap: 20px;
    padding: 16px 25px;
    margin-bottom: 15px;
  }
`;

export const StyledToastifyNotification = styled.div`
  .setting-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 14px;
    color: #ffffff;
    margin-bottom: 40px;
  }

  /*Notification Toast Popup*/

  .Toastify__toast-container {
    @media (max-width: 477px) {
      padding-top: 81px !important;
    }
  }

  .Toastify__toast-container--top-right {
    right: -23px;
    @media (max-width: 501px) {
      right: -38px;
    }
  }

  .custom-toast {
    color: white;
    background: #7065eb;
    height: 36px;
    width: 260px;
  }

  .toast-button {
    float: right;
    cursor: pointer;
    background: none;
    border: none;
  }

  .Toastify__toast {
    position: absolute !important;
    min-height: 36px;
    box-sizing: border-box;
    /* margin-bottom: 0rem; */
    margin: 0px;
    padding: 0px;
    border-radius: 5px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    box-shadow: 0 1px 0px 0 rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%);
    //right: 18px;
    @media (max-width: 477px) {
      right: 18px;
    }
  }

  .Toastify__toast-body {
    margin: auto 0 !important;
    flex: 1 1 auto;
    padding: 6px !important;
    display: flex;
    align-items: start !important;
  }

  .toast-button1 {
    padding-top: 10px;
    position: absolute;
    right: -124px;
    top: -9px;
    z-index: 2;
  }

  .toast-button2 {
    padding-right: 20px;
    /*padding-top: 9px;*/
  }

  .toast-button2-hide {
    opacity: 0;
  }

  .toast-text {
    font-size: 12px !important;
    font-weight: 600;
    padding-top: 4px !important;
    padding-left: 10px !important;
    @media (max-width: 477px) {
      padding-left: 28px !important;
    }
  }
`;

export const StyledLiveOrdersOff = styled.div`
  .vx-pagination li a {
    color: #c2c6dc;
    font-family: 'montserrat';
    font-size: 14px;
    font-weight: 700;
  }
  .main-menu-desktop {
    display: none;
    @media (max-width: 768px) {
      display: flex;
    }
  }

  .new-order-mobile {
    display: none;
    @media (max-width: 768px) {
      width: 100%;
      display: block;
    }
    border-radius: 3px;
    background: #00cdbe;
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    color: #ffffff;
    height: 40px;
    outline: unset;
    border: unset;
    width: 146px;
  }

  .sorting {
    padding-bottom: 24px;
    @media (max-width: 768px) {
      padding-bottom: 16px;
    }

    .filter-orders {
      display: flex;
      text-align: center;
      justify-content: flex-start;
      align-items: center;
      overflow: auto;
      overflow: auto;
      column-gap: 8px;
      //width: 60%;
      @media (max-width: 768px) {
        position: fixed;
        z-index: 5;
        bottom: 0;
        overflow-x: auto;
        width: 90%;
        display: none;
      }

      &__order {
        width: fit-content;
        height: fit-content;
        padding: 15px;
        background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#fff')};
        height: 80px;
        width: 104px;
        cursor: pointer;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        row-gap: 6px;
        align-items: center;
        justify-content: center;

        h2 {
          font-family: Larsseit, sans-serif !important;
          font-size: 12px;
          line-height: 16px;
          font-weight: normal;
          color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#262C49')};
          text-align: center;
          //padding-top: 8px;
          margin: 0;
        }

        img {
          width: 24px;
          height: 24px;
        }
      }

      .active-order {
        background: ${(props) => (props.theme === 'dark' ? '#6057CC' : '#313131')};

        h2 {
          color: #fff;
        }
      }
    }
  }

  .live-orders {
    .filters {
      align-items: center;
      //@media(max-width: 425px){
      //  flex-direction: column;
      //  row-gap: 8px;
      //  .filter-data,.form-group{
      //    width: 100% !important;
      //  }
      //  .filter-data{
      //    .col-md-6{
      //      padding-right: unset;
      //    }
      //  }
      //  .form-group{
      //    &:nth-of-type(2){
      //      .col-md-2,.col-md-6{
      //        padding-left: unset;
      //      }
      //    }
      //  }
      //}
      .form-group {
        margin-bottom: 0;
        @media (max-width: 768px) {
          .col-md-6 {
            max-width: 100%;
            flex: 0 0 100%;
          }
        }

        .custom-select {
          width: 100%;
        }

        &:nth-of-type(1) {
          width: 100%;
        }

        &:nth-of-type(2) {
          width: 50%;

          .col-md-6 {
            max-width: 100%;
            @media (max-width: 768px) {
              display: flex;
              justify-content: flex-end;
            }
          }
        }
      }

      .filter-data {
        display: none;

        .form-group {
          width: 100% !important;
        }

        @media (max-width: 768px) {
          display: block;
          width: 50%;
        }
      }

      .new-order {
        @media (max-width: 768px) {
          display: none;
        }
        border-radius: 3px;
        background: #00cdbe;
        font-size: 12px;
        font-weight: normal;
        line-height: 14px;
        color: #ffffff;
        height: 40px;
        outline: unset;
        border: unset;
        width: 146px;
      }

      .custom-select {
        width: 212px;
        height: 40px;
      }
    }

    .live-order {
      .row {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
`;

export const StyledModal = styled(Rodal)`
  .rodal-dialog {
    @media (min-width: 769px) {
      width: fit-content !important;
    }
  }

  background: rgba(49, 49, 49, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    box-shadow: unset;
    width: fit-content;
    height: 100%;
    background: transparent !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .customer-modal {
    width: fit-content;
    height: fit-content;
    background: ${(props) => (props.theme === 'dark' ? '#262C49' : '#FFFFFF')};
    padding: 32px;
    display: flex;
    flex-direction: column;
    outline: unset;
    height: ${(props) => (props.isLandspace === true ? '90% !important' : '668px')};
    overflow: auto;
    width: 457px;

    @media (max-width: 768px) {
      height: 100% !important;
      width: 100% !important;
    }

    @media (max-width: 425px) {
      width: 100%;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 32px;

      h2 {
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        font-family: Larsseit, sans-serif !important;
        color: ${(props) => (props.theme === 'dark' ? '#FFFFFF' : '#313131')};
        margin: 0;
      }

      button {
        font-family: Larsseit, sans-serif !important;
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#A5A5A5')};
        border: unset;
        outline: unset;
        background: unset;
        outline: unset;
      }
    }

    &__form {
      height: 100%;
      justify-content: space-between;
      display: flex;
      flex-direction: column;

      &__input_group {
        display: flex;
        flex-direction: column;

        input {
          margin-bottom: 24px;
          outline: unset;
          color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#B7B7B7')};
          padding: 15px 16px;
          width: 393px;
          background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#fff')};
          border: ${(props) => (props.theme === 'dark' ? 'unset' : '1px solid #DBDBDB')};
          border-radius: 3px;

          &:last-child {
            margin-bottom: 0;
          }

          &::placeholder {
            color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#45485B')};
            font-size: 12px;
            font-weight: 300;
            line-height: 18px;
            font-family: Larsseit, sans-serif !important;
          }

          &:focus {
            color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#B7B7B7')};
          }

          &:hover {
            color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#B7B7B7')};
          }
        }

        &__label {
          font-size: 14px;
          font-weight: 300;
          color: #c2c6dc;
          line-height: 18px;
          margin-bottom: 10px;
        }
      }

      button {
        background: ${(props) => (props.theme === 'dark' ? '#00CDBE' : '#313131')};
        color: #fff;
        width: 393px;
        padding: 20px 151px;
        margin-top: 16px;
        border: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: unset;
      }
    }
  }

  @media (max-width: 768px) {
    .customer-modal {
      padding: 16px;

      &__form {
        button,
        input {
          width: 100%;
        }
      }
    }
  }
`;

export const StyledModalCus = styled(Rodal)`
  .rodal-dialog {
    @media (min-width: 769px) {
      width: fit-content !important;
    }
  }

  background: rgba(49, 49, 49, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    box-shadow: unset;
    width: fit-content;
    height: 100%;
    background: transparent !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .customer-modal {
    width: fit-content;
    height: fit-content;
    background: ${(props) => (props.theme === 'dark' ? '#262C49' : '#FFFFFF')};
    padding: 32px;
    display: flex;
    flex-direction: column;
    outline: unset;
    height: ${(props) => (props.isLandspace === true ? '90% !important' : '90%')};
    overflow: auto;
    width: 457px;

    @media (max-width: 768px) {
      height: 100%;
    }

    @media (max-width: 425px) {
      width: 100%;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 32px;

      h2 {
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        font-family: Larsseit, sans-serif !important;
        color: ${(props) => (props.theme === 'dark' ? '#FFFFFF' : '#313131')};
        margin: 0;
      }

      button {
        font-family: Larsseit, sans-serif !important;
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#A5A5A5')};
        border: unset;
        outline: unset;
        background: unset;
        outline: unset;
      }
    }

    &__form {
      height: 100%;
      justify-content: space-between;
      display: flex;
      flex-direction: column;

      &__input_group {
        display: flex;
        flex-direction: column;

        input {
          margin-bottom: 24px;
          outline: unset;
          color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#B7B7B7')};
          padding: 8px 16px;
          width: 393px;
          background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#fff')};
          border: ${(props) => (props.theme === 'dark' ? 'unset' : '1px solid #DBDBDB')};
          border-radius: 3px;

          &:last-child {
            margin-bottom: 0;
          }

          &::placeholder {
            color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#45485B')};
            font-size: 12px;
            font-weight: 300;
            line-height: 18px;
            font-family: Larsseit, sans-serif !important;
          }

          &:focus {
            color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#B7B7B7')};
          }

          &:hover {
            color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#B7B7B7')};
          }
        }

        &__label {
          font-size: 14px;
          font-weight: 300;
          color: #c2c6dc;
          line-height: 18px;
          margin-bottom: 10px;
        }

        .search-input {
          margin-bottom: 0px !important;
        }
      }

      button {
        background: ${(props) => (props.theme === 'dark' ? '#00CDBE' : '#313131')};
        color: #fff;
        width: 393px;
        padding: 10px 151px;
        border-radius: 5px;
        margin-top: 16px;
        border: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: unset;
      }

      .add-user-btn {
        width: 50px !important;
        padding: 0px !important;
        margin-top: 0px !important;
        margin-left: 10px !important;
      }
    }
  }

  @media (max-width: 768px) {
    .customer-modal {
      padding: 16px;
      width: 100%;
      height: 100% !important;

      &__form {
        button,
        input {
          width: 100%;
        }
      }
    }
  }
  //@media(max-width: 376px){
  //  .customer-modal{
  //      &__form{
  //        button,input{
  //          width: 300px !important;
  //        }
  //      }
  //  }
  //}
`;

export const StyledModalOutlet = styled(Rodal)`
  .rodal-dialog {
    @media (min-width: 769px) {
      width: fit-content !important;
    }
  }

  background: rgba(49, 49, 49, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    box-shadow: unset;
    width: fit-content;
    height: 100%;
    background: transparent !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .outlet-modal {
    width: fit-content;
    height: fit-content;
    background: ${(props) => (props.theme === 'dark' ? '#262C49' : '#FFFFFF')};
    padding: 32px;
    display: flex;
    flex-direction: column;
    outline: unset;
    height: ${(props) => (props.isLandspace === true ? '90% !important' : '90%')};
    overflow: auto;
    width: 457px;

    @media (max-width: 768px) {
      height: 100%;
    }

    @media (max-width: 425px) {
      width: 100%;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;

      h2 {
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        font-family: Larsseit, sans-serif !important;
        color: ${(props) => (props.theme === 'dark' ? '#FFFFFF' : '#313131')};
        margin: 0;
      }

      button {
        font-family: Larsseit, sans-serif !important;
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#A5A5A5')};
        border: unset;
        outline: unset;
        background: unset;
        outline: unset;
      }
    }
    button {
      background: ${(props) => (props.theme === 'dark' ? '#00CDBE' : '#313131')};
      color: #fff;
      width: 393px;
      padding: 10px 151px;
      border-radius:5px
      margin-top: 16px;
      border: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: unset;
    }
  }

  @media (max-width: 768px) {
    .outlet-modal {
      padding: 16px;
      width: 100%;
      height: 100% !important;

      &__form {
        button,
        input {
          width: 100%;
        }
        overflow: hidden;
      }
    }
  }

`;
