import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { setOrderAddress } from '../redux/actions/cart';

// import CONSTANTS from '../utils/constants';

// include styles
import axios from 'axios';
import 'rodal/lib/rodal.css';
import CONSTANTS from '../../../constants';
import { getLiveOrders, setStateValue } from '../../../redux/actions/orders';
import { StyledModal } from '../../../styles/GlobalStyles';
import { createOrder, createOrderWithoutUser } from '../redux/actions/pos';
import SyncHelper from '../utils/SyncHelper';

const AddAddress = (props) => {
  const [landspaceDevice, setLandspaceDevice] = useState(false);
  const deviceScreen = window.screen;
  let theme = props.customizer?.customizer?.theme;
  let { user } = props;
  let [connection, setConnection] = useState(window.navigator.onLine);
  const [customer, setCustomer] = useState({
    name: '',
    address: '',
    note: '',
    phone_number: ''
  });

  const closeAddressModal = () => {
    setCustomer({
      name: '',
      address: '',
      note: '',
      phone_number: ''
    });
    props.handleClose();
  };

  useEffect(() => {
    setCustomer({ ['name']: props.address?.name });
    setCustomer({ ['address']: props.address?.address });
    setCustomer({ ['note']: props.address?.note });
    setCustomer({ ['phone_number']: props.address?.phone_number });
  }, []);

  const changeCustomer = (event) => {
    // props.setOrderAddress(data);
    let customerDataObject;
    customerDataObject = {
      name:
        event.target.name === 'name'
          ? event.target.value
          : customer.name !== ''
          ? customer.name
          : props.address?.name !== undefined
          ? props.address.name
          : '',
      address:
        event.target.name === 'address'
          ? event.target.value
          : customer.address !== ''
          ? customer.address
          : props.address?.address !== undefined
          ? props.address.address
          : '',
      phone_number:
        event.target.name === 'phone_number'
          ? event.target.value
          : customer.phone_number !== ''
          ? customer.phone_number
          : props.address?.phone_number !== undefined
          ? props.address.phone_number
          : '',
      note:
        event.target.name === 'note'
          ? event.target.value
          : customer.note !== ''
          ? customer.note
          : props.address?.note !== undefined
          ? props.address.note
          : ''
    };
    props.setOrderAddress(customerDataObject);
    setCustomer({ ...customer, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOfflineStatus);
    return () => {
      window.removeEventListener('offline', updateOfflineStatus);
      window.removeEventListener('online', updateOnlineStatus);
    };
  }, []);

  const updateOnlineStatus = () => {
    setConnection(true);
  };

  const updateOfflineStatus = () => {
    setConnection(false);
  };

  let localOrderList = JSON.parse(localStorage.getItem('offline_order_list'));
  let offlineOrderList = localOrderList ? (localOrderList.length ? localOrderList : []) : [];
  let data,
    updatedOfflineOrderList = offlineOrderList,
    newList;

  let placeOfflineOrders = async () => {
    const location_data = JSON.parse(localStorage.getItem('plugd_admin_location'));

    for (let i = 0; i < offlineOrderList.length; i++) {
      data = offlineOrderList[i];
      if (data.customer?.token) {
        await props.createOrder(data, location_data, data.customer?.token);
        newList = updatedOfflineOrderList.filter((p) => p.koId !== data.koId);
        updatedOfflineOrderList = newList;
        localStorage.setItem('offline_order_list', JSON.stringify(newList));
      } else {
        if (data.customer?.phone_number) {
          const url = `${CONSTANTS.API_URL}/customers/sessions/login`;
          let response = await axios.post(url, data.customer);
          if (response.status === 200) {
            await props.createOrder(data, location_data, response.data?.data?.token);
            newList = updatedOfflineOrderList.filter((p) => p.koId !== data.koId);
            updatedOfflineOrderList = newList;
            localStorage.setItem('offline_order_list', JSON.stringify(newList));
          }
        } else {
          await props.createOrderWithoutUser(data, location_data);
          newList = updatedOfflineOrderList.filter((p) => p.koId !== data.koId);
          updatedOfflineOrderList = newList;
          localStorage.setItem('offline_order_list', JSON.stringify(newList));
        }
      }
    }
  };

  let syncOfflineUsers = async () => {
    const location_data = JSON.parse(localStorage.getItem('plugd_admin_location'));
    await SyncHelper.syncOfflineUsersToOnine(location_data);
  };

  useEffect(() => {
    console.log('Your offline orders are in progress!');
    if (connection) {
      if (offlineOrderList && offlineOrderList.length > 0) {
        placeOfflineOrders().then((r) => {
          syncOfflineUsers();
          Swal.fire('Done!', 'Offline orders has been placed', 'success');
          let datas = {
            filter: 'live_orders',
            filterKeyName: 'live_orders',
            offlineOrder: false,
            page: 0,
            sort: 'DESC'
          };
          props.getLiveOrders(datas);
          props.setStateValue(datas);
        });
      } else {
        syncOfflineUsers();
      }
    }
  }, [connection]);

  const userRegistration = async () => {
    if (props.address.address === '' || props.address.address === undefined) {
      Swal.fire('Opps!', 'Please type your full delivery address', 'warning');
    } else {
      if (props.address.phone_number === '' || props.address.phone_number === undefined) {
        Swal.fire('Opps!', 'Please type your phone number', 'warning');
      } else {
        const customerData = {
          name:
            customer.name !== ''
              ? customer.name
              : props.address?.name !== undefined
              ? props.address.name
              : '',
          address:
            customer.address !== ''
              ? customer.address
              : props.address?.address !== undefined
              ? props.address.address
              : '',
          phone_number:
            customer.phone_number !== ''
              ? customer.phone_number
              : props.address?.phone_number !== undefined
              ? props.address.phone_number
              : '',
          note:
            customer.note !== ''
              ? customer.note
              : props.address?.note !== undefined
              ? props.address.note
              : ''
        };
        props.setOrderAddress(customerData);
        closeAddressModal();
      }
    }
  };

  const updateOrientationStatus = () => {
    const mql = window.matchMedia('(orientation: portrait)');
    if (!mql.matches) {
      setLandspaceDevice(true);
    } else {
      setLandspaceDevice(false);
    }
    // if (deviceScreen.orientation.type === "landscape-primary") {
    //     setLandspaceDevice(true);
    // } else {
    //     setLandspaceDevice(false);
    // }
  };

  useEffect(() => {
    updateOrientationStatus();
    if ('onorientationchange' in window) {
      window.addEventListener(
        'orientationchange',
        function () {
          updateOrientationStatus();
        },
        false
      );
    }
  }, []);

  return (
    <StyledModal
      theme={theme}
      visible={props.open}
      onClose={closeAddressModal}
      enterAnimation={'slideDown'}
      leaveAnimation={'slideDown'}
      isLandspace={landspaceDevice}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <div className="customer-modal">
        <div className="customer-modal__header">
          <h2>Add customer address</h2>
          <button onClick={closeAddressModal}>Skip</button>
        </div>
        <div className="customer-modal__form">
          <div className="customer-modal__form__input_group">
            <p className={'customer-modal__form__input_group__label'}>Address Name</p>
            <input
              autoFocus={true}
              value={
                customer.name !== ''
                  ? customer.name
                  : props.address?.name !== undefined
                  ? props.address?.name
                  : ''
              }
              name="name"
              onChange={changeCustomer}
              type="text"
              placeholder={'Name '}
            />
            <p className={'customer-modal__form__input_group__label'}>Phone Number</p>
            <input
              value={
                customer.phone_number !== ''
                  ? customer.phone_number
                  : props.address?.phone_number !== undefined
                  ? props.address?.phone_number
                  : ''
              }
              name="phone_number"
              onChange={changeCustomer}
              type="number"
              placeholder={'Number '}
            />
            <p className={'customer-modal__form__input_group__label'}>Note</p>
            <input
              value={
                customer.note !== ''
                  ? customer.note
                  : props.address?.note !== undefined
                  ? props.address?.note
                  : ''
              }
              name="note"
              onChange={changeCustomer}
              type="text"
              placeholder={'Note '}
            />
            <p className={'customer-modal__form__input_group__label'}>Address</p>
            <input
              value={
                customer.address !== ''
                  ? customer.address
                  : props.address?.address !== undefined
                  ? props.address?.address
                  : ''
              }
              name="address"
              onChange={changeCustomer}
              type="text"
              placeholder={'Address '}
            />
          </div>
          <button onClick={userRegistration}>Add</button>
        </div>
      </div>
    </StyledModal>
  );
};

function mapStateToProps(state) {
  return {
    customizer: state.customizer ? state.customizer : null,
    user: state.userReducer ? state.userReducer.user : null,
    address: state.cartReducer ? state.cartReducer.address : ''
  };
}

export default connect(mapStateToProps, {
  setOrderAddress,
  createOrder,
  createOrderWithoutUser,
  setStateValue,
  getLiveOrders
})(AddAddress);
