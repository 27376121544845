import CircularProgress from '@material-ui/core/CircularProgress';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { search } from 'ss-search';
import Swal from 'sweetalert2';
import CONSTANTS from '../../../constants';
import GoogleHelper from '../../../helpers/GoogleHelper';
import { resetUserData } from '../../../pos/src/redux/actions/users/index';
import {
  insertUserLocationById,
  resetExistingCustomerLocations,
  setExistingCustomerDetails,
  setExistingCustomerLocations
} from '../../../redux/actions/users';
import LocationService from '../../../services/LocationService';
import { StyledModalMap } from '../../../styles/GlobalStyles';
import { getStoreData } from '../utils/DBHelper';
import SharedMap from './SharedMap';

class MapModal extends Component {
  constructor(props) {
    super(props);
    this.timeout = 0;
    let predictions = null;
    this.state = {
      showMap: false,
      location: null,
      selectedOutlet: null,
      currentLocation: null,
      address: '',
      note: '',
      location_name: '',
      phone_number: this.props.existingCustomerDetails
        ? this.props.existingCustomerDetails.phone_number
        : this.props.userPhoneNumber,
      isSync: false,
      itemIndex: '',
      isLocationSet: false,
      isLocName: false,
      predictions: [],
      isFirst: true,
      isDrag: false,
      isDragError: false,
      loading: false
    };
  }

  async componentDidMount() {
    if (this.props.address) {
      this.setState({
        selectedOutlet: this.props.selectedOutlet,
        location: {
          lat: this.props.address?.location?.lat,
          lng: this.props.address?.location?.lng,
          address: this.props.address?.address
        },
        address: this.props.address?.address,
        itemIndex: this.props.itemIndex,
        note: this.props.address?.note,
        phone_number: this.props.existingCustomerDetails
          ? this.props.existingCustomerDetails.phone_number
          : this.props.userPhoneNumber,
        location_name: ''
      });
    } else {
      this.setState({
        selectedOutlet: this.props.selectedOutlet
      });
    }

    this.geoCodeAddress.bind(this);

    // return navigator.geolocation.getCurrentPosition(
    //   this.onGeoLocationSuccess.bind(this),
    //   this.onGeoLocationError.bind(this),
    //   {
    //     enableHighAccuracy: true,
    //     timeout: 30000
    //   }
    // );
  }

  async componentDidUpdate(prevProps) {
    if (!this.props.showModal && prevProps.showModal) {
      await this.setState(
        {
          address: '',
          note: '',
          location_name: '',
          phone_number: '',
          itemIndex: '',
          location: null
        },
        () => {
          return navigator.geolocation.getCurrentPosition(
            this.onGeoLocationSuccess.bind(this),
            this.onGeoLocationError.bind(this),
            {
              enableHighAccuracy: true,
              timeout: 30000
            }
          );
        }
      );
    }

    if (
      this.props.address &&
      JSON.stringify(this.props.address) !== JSON.stringify(prevProps.address)
    ) {
      await this.setState({
        location: {
          lat: this.props.address?.location?.lat,
          lng: this.props.address?.location?.lng,
          address: this.props.address?.address
        },
        address: this.props.address?.address,
        itemIndex: this.props.itemIndex,
        note: this.props.address?.note,
        phone_number: this.props.existingCustomerDetails
          ? this.props.existingCustomerDetails.phone_number
          : this.props.userPhoneNumber,
        location_name: this.props.deliveryInformation?.address
      });
    }

    if (this.props.selectedOutlet?.loc_id !== prevProps.selectedOutlet?.loc_id) {
      this.setState({ selectedOutlet: this.props.selectedOutlet });
    }
  }

  onGeoLocationSuccess(position) {
    let { latitude, longitude } = position.coords;
    let location = { lat: latitude, lng: longitude, address: '' };
    if (this.state.location) return this.setState({ currentLocation: location });
    this.setState({ currentLocation: location, location });
  }

  onGeoLocationError(error) {
    alert('Plugd needs your device location for delivery orders. Please enable your GPS.');
  }

  async displaySuggestions(predictions, status) {
    if (status != window.google.maps.places.PlacesServiceStatus.OK) {
      await this.setState({ loading: false });
      return;
    }
    let location = await GoogleHelper.getLatLongFromAddress(predictions[0]?.description);
    (await location) && this.setState({ location, loading: false });
  }

  async geoCodeAddress() {
    if (this.props.deliveryInformation?.address && window.navigator.onLine) {
      const service = new window.google.maps.places.AutocompleteService();
      let geolocation = new window.google.maps.LatLng(
        this.props.selectedOutlet?.latitude,
        this.props.selectedOutlet?.longitude
      );
      let circle = new window.google.maps.Circle({ center: geolocation, radius: 30000 });
      service.getPlacePredictions(
        {
          input: this.props.deliveryInformation?.address,
          locationRestriction: circle.getBounds(),
          componentRestrictions: { country: 'pk' }
        },
        await this.displaySuggestions.bind(this)
      );
    }
  }

  toggleMap() {
    this.setState({ showMap: !this.state.showMap });
  }

  onLocationSelect(t, map, coord) {
    this.setState({
      location: {
        lat: coord.latLng.lat(),
        lng: coord.latLng.lng(),
        address: ''
      }
    });
  }

  onPlaceSelect(location, addr) {
    this.setState(
      {
        location: {
          lat: location.lat,
          lng: location.lng,
          address: addr
        }
      },
      () => {
        if (this.state.selectedOutlet) {
          const distance = GoogleHelper.distanceBetweenTwoLatLngStrings(
            `${this.state.location.lat},${this.state.location.lng}`,
            `${this.state.selectedOutlet.latitude},${this.state.selectedOutlet.longitude}`
          );
          if (distance > 500) {
            this.props.onChangeOutlet(null, false);
          }
        }
      }
    );
    this.setState({ isLocationSet: false });
  }

  async onMapChange(t, map) {
    await this.setState({
      location: {
        lat: map.center.lat(),
        lng: map.center.lng(),
        address: ''
      },
      isFirst: false,
      isDrag: true,
      isDragError: false
    });
  }

  goToCurrentLocation() {
    this.setState({ location: this.state.currentLocation });
  }

  sendLocation() {
    this.toggleMap();
    if (this.state.location) this.invokeCallBack(this.state.location);
  }

  invokeCallBack(location) {
    let locationString = location ? `${location.lat},${location.lng}` : '';
    this.props.onChange({
      target: {
        suppressErrors: true,
        name: 'delivery_location',
        value: locationString
      }
    });
  }

  locationLabel() {
    if (!this.props.delivery_location) return 'Specify your location on map';
    if (JSON.stringify(this.state.currentLocation) === JSON.stringify(this.state.location))
      return 'Current Location';
    return 'Custom Location';
  }

  onOutletSelect(outlet) {
    this.setState({ selectedOutlet: outlet });
    this.props.onChangeOutlet(outlet, false);
  }

  onDone() {
    this.props.toggleModal();
    this.props.onChangeOutlet(this.state.selectedOutlet);
  }

  async onInputchange(event) {
    const name = event.target.name;
    if (this.timeout) clearTimeout(this.timeout);
    this.props.changeDeliveryInformation(event);
    if (event.target.value === '') {
      await this.setState({
        [name]: event.target.value,
        loading: false,
        isDragError: false
      });
    } else {
      if (name === 'address') {
        this.setState(
          {
            [name]: event.target.value,
            loading: window.navigator.onLine ? true : false
          },
          async () => {
            this.timeout = setTimeout(() => {
              if (this.state.address.length < 3) return;
              if (name === 'address') {
                this.geoCodeAddress();
              }
            }, 1500);
          }
        );
      } else {
        this.setState({
          [name]: event.target.value
        });
      }
    }

    await this.setState({
      isLocationSet: false,
      isLocName: false,
      isSetNumber: false,
      isDrag: false,
      isDragError: false,
      isFirst: false
    });
  }

  async prevStep() {
    await this.setState({
      isLocationSet: false,
      isLocName: false,
      isSetNumber: false,
      isDrag: false,
      isDragError: false,
      isFirst: false,
      isSaving: false,
      address: '',
      location_name: '',
      note: ''
    });

    this.props.setUserDeliveryInformation({
      location_name: '',
      address: '',
      phone_number: this.props.existingCustomerDetails
        ? this.props.existingCustomerDetails.phone_number
        : this.props.userPhoneNumber,
      note: ''
    });
    this.props.prevStep();
  }
  makeid(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  async saveLocation() {
    if (this.props.deliveryInformation?.address === '') {
      const element = document.getElementById('address');
      if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      await this.setState({ isLocationSet: !this.state.isLocationSet, isSaving: false });
      return;
    }
    if (window.navigator.onLine) {
      if (!this.state.isFirst && !this.state.isDragError) {
        if (!this.state.isDrag) {
          const element = document.getElementById('map-drag');
          if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          await this.setState({ isDragError: true, isSaving: false });
          return;
        }
      }
      if (this.state.isDragError) {
        return;
      }
    }
    if (this.state.isSaving) {
      return;
    }
    try {
      await this.setState({ isSaving: true });
      if (this.props.deliveryInformation?.address === '') {
        await this.setState({ isLocationSet: !this.state.isLocationSet });
        return;
      } else {
        if (window.navigator.onLine) {
          const locationLoc = LocationService.getLocationLoc();
          let locationLatLng = [this.state.location.lat, this.state.location.lng].toString();
          let areas = GoogleHelper.checkForDeliveryArea(locationLatLng, locationLoc.deliveryAreas);
          if (areas) {
            //this.props.updateSavedLocations(this.state);
            if (this.state.location_name === '') {
              this.state.location_name = this.props.deliveryInformation?.address;
            }
            await this.props.insertUserLocationById(
              this.state,
              this.state.selectedOutlet,
              this.props.existingCustomerDetails
                ? this.props.existingCustomerDetails?.user_id
                : this.props.user.user_id
            );
          } else {
            Swal.fire('Opps!', 'This restaurant can not deliver in the selected area', 'warning');
            await this.setState({ isSaving: false });
            return;
          }
        } else {
          //this.props.updateSavedLocations(this.state);
          if (this.state.location_name === '') {
            this.state.location_name = this.props.deliveryInformation?.address;
          }
          this.state.id = this.makeid(6);
          await this.props.insertUserLocationById(
            this.state,
            this.state.selectedOutlet,
            this.props.existingCustomerDetails
              ? this.props.existingCustomerDetails?.user_id
              : this.props.user.user_id
          );
        }
      }
      if (window.navigator.onLine) {
        await this.props.setLastLocation(this.state);
        if (!this.props.existingCustomerDetails) {
          let loc_id = this.props.selectedOutlet.is_outlet
            ? this.props.selectedOutlet.parent_id
            : this.props.selectedOutlet.loc_id;
          let usersList = this.props.allCustomers ? this.props.allCustomers : [];
          const result = search(usersList, ['user_id'], this.props.user.user_id);
          await this.props.setExistingCustomerDetails(result[0]);
          await this.props.setExistingCustomerLocations(loc_id, result[0]);
        }
        localStorage.setItem('location_index', this.props.existingCustomerLocations.length - 1);
      } else {
        await this.props.setLastLocation(this.state);
        let loc_id = this.props.selectedOutlet.is_outlet
          ? this.props.selectedOutlet.parent_id
          : this.props.selectedOutlet.loc_id;
        let localUsers = await getStoreData(
          CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
          CONSTANTS.INDEXDB.STORE.USERS
        );
        const result = search(localUsers[1].data, ['user_id'], this.props.user.user_id);
        let promises = [];
        await this.props.setExistingCustomerDetails(result[0]);
        await this.props.setExistingCustomerLocations(loc_id, result[0]);
        localStorage.setItem('location_index', this.props.existingCustomerLocations.length - 1);
      }
      //await this.props.resetUserData();
      await this.setState({ isSaving: false });
      this.props.prevStep();
    } catch (e) {
      console.log(e);
      await this.setState({ isSaving: false });
    }
  }

  isDoneEnabled() {
    return this.state.selectedOutlet;
  }

  render() {
    const outletsForBounds = this.props.data && this.props.data.length && this.props.data;
    const outlets = this.props.data && this.props.data.length && this.props.data;
    let theme = this.props.customizer?.customizer?.theme;

    return (
      <StyledModalMap
        theme={theme}
        visible={this.props.showModal}
        onClose={this.props.toggleModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        enterAnimation={'slideDown'}
        leaveAnimation={'slideDown'}
        duration={350}>
        <div className="customer-modal">
          <div className="customer-modal__header">
            <h2>{this.props.editLocation ? 'Edit Location' : this.props.name}</h2>
            <button onClick={this.props.toggleModal}>Skip</button>
          </div>
          {!this.props.isTakeAway && (
            <>
              <div className="customer-modal__form">
                <div className="customer-modal__form__input_group">
                  <form>
                    <div id="address" className="form-group ">
                      <div className="d-flex flex-row align-items-center">
                        <input
                          type="text"
                          className="form-control"
                          id="inputAddress"
                          placeholder="Full Delivery Address"
                          name="address"
                          value={this.props.deliveryInformation?.address}
                          defaultValue={this.props.deliveryInformation?.address}
                          onChange={this.onInputchange.bind(this)}
                          required
                        />
                        {this.state.loading ? (
                          <div
                            className={
                              this.state.loading
                                ? 'ml-1 circular-progress-delivery-flow'
                                : 'ml-1 circular-progress'
                            }>
                            <CircularProgress size={20} />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      {this.state.isLocationSet && (
                        <small className="text-danger">Please enter your full address.</small>
                      )}
                    </div>
                    {/* <div className="form-group">
                      <input
                        type="phone"
                        className="form-control"
                        id="phone_number"
                        placeholder="Enter your phone number"
                        name="phone_number"
                        value={
                          this.props.existingCustomerDetails
                            ? this.props.existingCustomerDetails.phone_number
                            : this.props.userPhoneNumber
                        }
                        defaultValue={
                          this.props.existingCustomerDetails
                            ? this.props.existingCustomerDetails.phone_number
                            : this.props.userPhoneNumber
                        }
                        onChange={this.onInputchange.bind(this)}
                        required
                      />
                    </div> */}
                    {window.navigator.onLine ? (
                      <>
                        <div
                          id="map-drag"
                          className={`map-container ${
                            !this.props.isTakeAway ? 'takeaway-map-container' : ''
                          }`}>
                          {this.state.isDragError && (
                            <small>
                              <span className="text-danger">
                                Please drag the map to your exact location.
                              </span>
                            </small>
                          )}
                          {/* <div className="places">
                      <Places
                        placeholder="Search"
                        onSelect={this.onPlaceSelect.bind(this)}
                        selectedOutlet={this.props.selectedOutlet}
                      />
                    </div> */}
                          {window.navigator.onLine ? (
                            <div className={`map ${this.state.isDragError ? 'map-shake' : ''}`}>
                              {this.props.showModal && (
                                <SharedMap
                                  defaultLocation={this.state.location}
                                  currentLocation={this.state.currentLocation}
                                  onLocationSelect={this.onLocationSelect.bind(this)}
                                  onMapChange={this.onMapChange.bind(this)}
                                  goToCurrentLocation={this.goToCurrentLocation.bind(this)}
                                  outlets={outlets}
                                  outletsForBounds={outletsForBounds}
                                  onOutletSelect={this.onOutletSelect.bind(this)}
                                  selectedOutlet={this.state.selectedOutlet}
                                  isTakeAway={this.props.isTakeAway}
                                  redraw={this.props.showModal}
                                />
                              )}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        <br></br>
                      </>
                    ) : (
                      ''
                    )}
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="location_name"
                        name="location_name"
                        placeholder="Name (E.g. Home, Work, Mom's house etc)"
                        value={this.props.deliveryInformation?.location_name}
                        defaultValue={this.props.deliveryInformation?.location_name}
                        onChange={this.onInputchange.bind(this)}
                        required
                      />
                      {this.state.isLocName && (
                        <small className="text-danger">Please enter your location name.</small>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="note"
                        name="note"
                        placeholder="Add note (E.g. don't ring bell, Nearby Landmark etc)"
                        value={this.props.deliveryInformation?.note}
                        defaultValue={this.props.deliveryInformation?.note}
                        onChange={this.onInputchange.bind(this)}
                        required
                      />
                    </div>
                  </form>
                </div>
                {!this.props.isTakeAway && (
                  <button
                    className="btn-add"
                    disabled={this.state.isSaving}
                    onClick={this.saveLocation.bind(this)}>
                    Save Location
                  </button>
                )}
                <button className="btn-add" onClick={this.prevStep.bind(this)}>
                  Previous Step
                </button>
                <br></br>
              </div>
            </>
          )}
        </div>
      </StyledModalMap>
    );
  }
}

function mapStateToProps(state) {
  return {
    existingCustomerDetails: state.users.existingCustomerDetails,
    existingCustomerLocations: state.users.existingCustomerLocations,
    customizer: state.customizer ? state.customizer : null,
    user: state.cartReducer ? state.cartReducer.customer : null,
    address: state.cartReducer ? state.cartReducer.address : '',
    userPhoneNumber: state.userReducer ? state.userReducer.userPhoneNumber : null,
    allCustomers: state.users.allCustomers.data
  };
}

export default connect(mapStateToProps, {
  insertUserLocationById,
  setExistingCustomerDetails,
  setExistingCustomerLocations,
  resetUserData,
  resetExistingCustomerLocations
})(MapModal);
