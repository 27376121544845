import React from 'react';
import * as Icon from 'react-feather';
import CheftIcon from '../components/shared/ChefIcon';

const managerNavigationConfig = (location = null) =>
  [
    {
      id: 'live-orders',
      title: 'Live Orders',
      type: 'item',
      icon: <Icon.Package size={20} />,
      permissions: ['admin', 'editor'],
      access: 'live-orders',
      navLink: '/'
    },
    {
      id: 'order-types',
      title: 'Order Status',
      type: 'item',
      icon: <Icon.File size={20} />,
      permissions: ['admin', 'editor'],
      navLink: '/locations/order_types',
      access: 'ordertypes',
      filterBase: '/locations/order_types'
    },
    {
      id: 'menu-manager',
      title: 'Menu',
      type: 'item',
      icon: <Icon.List size={20} />,
      permissions: ['admin', 'editor'],
      access: 'menu',
      navLink: '/locations/menu'
    },
    {
      id: 'inventory-manager',
      title: 'Inventory Management',
      type: 'collapse',
      icon: <Icon.FileText size={20} />,
      access: 'inventory',
      notificationIcon: true,
      children: [
        {
          id: 'view-inventory',
          title: 'Inventory',
          type: 'item',
          icon: <Icon.FileText size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/inventory'
        },
        {
          id: 'recipe-manager',
          title: 'Recipe',
          type: 'item',
          icon: <Icon.Book size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/recipe'
        },
        {
          id: 'inventory-stock-manager',
          title: 'Inventory Stock',
          type: 'item',
          icon: <Icon.ShoppingBag size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/inventory-stock',
          notificationIcon: true
        },
        {
          id: 'purchases-manager',
          title: 'Purchases',
          type: 'item',
          icon: <Icon.CreditCard size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/purchases'
        },
        {
          id: 'productions-manager',
          title: 'Productions',
          type: 'item',
          icon: <CheftIcon />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/productions'
        },
        {
          id: 'inventory-stock-adjustments',
          title: 'Stock Adjustment',
          type: 'item',
          icon: <Icon.FileMinus size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/stock-adjustment'
        },
        {
          id: 'inventory-stock-transfers',
          title: 'Stock Transfers',
          type: 'item',
          icon: <Icon.RefreshCcw size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/stock-transfers'
        }
      ].filter((child) => Object.keys(child).length !== 0)
    },
    {
      id: 'analytics',
      title: 'Analytics',
      type: 'collapse',
      icon: <Icon.FileText size={20} />,
      notificationIcon: true,
      access: 'analytics',
      children: [
        {
          id: 'overall-stats',
          title: 'Overall Stats',
          type: 'item',
          icon: <Icon.Activity size={20} />,
          permissions: ['admin', 'editor'],
          access: 'analytics',
          navLink: '/locations/overall_stats'
        },
        {
          id: 'restaurant-stats',
          title: 'Restaurants Stats',
          type: 'item',
          icon: <Icon.Home size={20} />,
          access: 'analytics',
          permissions: ['admin', 'editor'],
          navLink: '/locations/restaurants_stats'
        },
        {
          id: 'customer-stats',
          title: 'Customer Stats',
          type: 'item',
          icon: <Icon.User size={20} />,
          permissions: ['admin', 'editor'],
          access: 'analytics',
          navLink: '/locations/customer_stats'
        },
        {
          id: 'restaurant-stats',
          title: 'Restaurants Stats',
          type: 'item',
          icon: <Icon.Activity size={20} />,
          permissions: ['admin', 'editor'],
          access: 'analytics',
          navLink: '/locations/restaurants_stats'
        },
        {
          id: 'item-stats',
          title: 'Item Stats',
          type: 'item',
          icon: <Icon.List size={20} />,
          permissions: ['admin', 'editor'],
          access: 'analytics',
          navLink: '/locations/item_stats'
        },
        {
          id: 'orders-stats',
          title: 'Order Logs',
          type: 'item',
          icon: <Icon.ShoppingCart size={20} />,
          permissions: ['admin', 'editor'],
          access: 'analytics',
          navLink: '/locations/orders_stats'
        },
        {
          id: 'bins-stats',
          title: 'Bank Discounts Reports',
          type: 'item',
          icon: <Icon.CreditCard size={20} />,
          permissions: ['admin', 'editor'],
          access: 'bin-discounts',
          navLink: '/locations/bin_stats'
        },
        {
          id: 'bank-discounts-stats',
          title: 'Bank Discounts Logs',
          type: 'item',
          icon: <Icon.Percent size={20} />,
          permissions: ['admin', 'editor'],
          access: 'bin-discounts',
          navLink: '/locations/bin_orders'
        }
      ]
    },
    {
      id: 'promotion-dsicount',
      title: 'Promotions & Discounts',
      type: 'collapse',
      access: 'promotions',
      icon: <Icon.Monitor size={20} />,
      children: [
        {
          id: 'promotions',
          title: 'Promotions',
          type: 'item',
          access: 'promotions',
          icon: <Icon.Monitor size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/promotions'
        },
        {
          id: 'bankdiscounts',
          title: 'Banks Discounts',
          type: 'item',
          access: 'bin-discounts',
          icon: <Icon.CreditCard size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/bindiscounts'
        },
        {
          id: 'loyalties',
          title: 'Loyalties',
          type: 'item',
          access: 'loyalty',
          icon: <Icon.Star size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/loyalties'
        }
      ]
    },
    {
      id: 'app-notifications',
      title: 'App Notifications',
      type: 'collapse',
      access: 'notifications',
      icon: <Icon.Mail size={20} />,
      children: [
        {
          id: 'in-app-notifications',
          title: 'In App Notifications',
          type: 'item',
          icon: <Icon.MessageSquare size={20} />,
          permissions: ['admin', 'editor'],
          access: 'notifications',
          navLink: '/locations/app/notifications'
        },
        {
          id: 'notifications-logs',
          title: 'Notifications Logs',
          type: 'item',
          icon: <Icon.MessageSquare size={20} />,
          permissions: ['admin', 'editor'],
          access: 'notifications',
          navLink: '/locations/app/notifications/logs'
        }
      ]
    },
    // {
    //   id: 'modifiers',
    //   title: 'Modifiers',
    //   type: 'item',
    //   icon: <Icon.Archive size={20} />,
    //   permissions: ['admin', 'editor'],
    //   navLink: '/locations/modifiers'
    // },
    {
      id: 'sms-marketing',
      title: 'Communications',
      type: 'collapse',
      access: 'sms-marketing',
      icon: <Icon.Mail size={20} />,
      children: [
        {
          id: 'sms-campaigns',
          title: 'SMS Campaigns',
          type: 'item',
          access: 'sms-marketing',
          icon: <Icon.MessageSquare size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/sms/campaigns'
        },
        {
          id: 'sms-plans',
          title: 'Purchase SMS Bundle',
          type: 'item',
          access: 'sms-marketing',
          icon: <Icon.ShoppingBag size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/sms/plans'
        }
      ]
    },
    {
      id: 'restaurant-settings',
      title: 'Restaurant Settings',
      type: 'collapse',
      access: 'restaurant-settings',
      icon: <Icon.Home size={20} />,
      children: [
        {
          id: 'outlets',
          title: 'Outlets',
          type: 'item',
          icon: <Icon.ShoppingBag size={20} />,
          access: 'restaurant-settings',
          permissions: ['admin', 'editor'],
          navLink: '/locations/outlets'
        },
        {
          id: 'departments',
          title: 'Departments',
          type: 'item',
          icon: <Icon.ShoppingBag size={20} />,
          access: 'restaurant-settings',
          permissions: ['admin', 'editor'],
          access: 'departments',
          navLink: '/locations/departments'
        },
        {
          id: 'printer_stations',
          title: 'Printer Stations',
          type: 'item',
          icon: <Icon.Printer size={20} />,
          permissions: ['admin', 'editor'],
          access: 'printer-stations',
          navLink: '/locations/printer_stations'
        },
        {
          id: 'branding',
          title: 'Branding',
          type: 'item',
          access: 'restaurant-settings',
          icon: <Icon.Layout size={20} />,
          permissions: ['admin'],
          navLink: '/locations/branding'
        },
        {
          id: 'pagelayouts',
          title: 'Page Layouts',
          type: 'item',
          access: 'restaurant-settings',
          icon: <Icon.Monitor size={20} />,
          permissions: ['admin'],
          navLink: '/locations/pagelayouts'
        },
        {
          id: 'pages',
          title: 'Web Pages',
          type: 'item',
          access: 'restaurant-settings',
          icon: <Icon.Monitor size={20} />,
          permissions: ['admin', 'editor'],
          access: 'web-pages',
          navLink: '/locations/pages'
        },
        {
          id: 'tables',
          title: 'Tables',
          type: 'item',
          access: 'tables',
          icon: <Icon.Tablet size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/tables'
        },
        {
          id: 'metatags',
          title: 'MetaTags',
          type: 'item',
          icon: <Icon.Flag size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/metatags'
        }
      ]
    },
    {
      id: 'settings',
      title: 'Settings',
      type: 'collapse',
      access: 'settings',
      icon: <Icon.Settings size={20} />,
      children: [
        {
          id: 'payment-processor',
          title: 'Payment Settings',
          type: 'item',
          icon: <Icon.CreditCard size={20} />,
          permissions: ['admin', 'editor'],
          access: 'payment-settings',
          navLink: '/locations/payment_processor',
          filterBase: '/locations/payment_processor'
        },
        {
          id: 'rider-services',
          title: 'Rider Services',
          type: 'item',
          icon: <Icon.User size={20} />,
          permissions: ['admin', 'editor'],
          access: 'rider-services',
          navLink: '/locations/rider_services',
          filterBase: '/locations/rider_services'
        }
      ]
    },
    {
      id: 'riders-and-delivery',
      title: 'Riders & Delivery',
      type: 'collapse',
      access: 'rider-and-delivery',
      icon: <Icon.Truck size={20} />,
      children: [
        {
          id: 'rider-compensation',
          title: 'Rider Compensation',
          type: 'item',
          access: 'rider-and-delivery',
          icon: <Icon.CreditCard size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/rider_compensation'
        },
        {
          id: 'deliveries-history',
          title: 'Deliveries History',
          type: 'item',
          access: 'rider-and-delivery',
          icon: <Icon.CreditCard size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/deliveries'
        }
      ]
    },
    {
      id: 'divider'
    },
    {
      id: 'logout',
      title: 'Logout',
      access: 'logout',
      type: 'item',
      icon: <Icon.Power size={20} />,
      permissions: ['admin', 'editor'],
      navLink: '/logout'
    }
  ].filter((child) => Object.keys(child).length !== 0);
export default managerNavigationConfig;
