import { Workbox } from 'workbox-window';

export default function swDev() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const swUrl = '/worker.js';
      navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration);
          const wb = new Workbox(swUrl, { debug: false });
          wb.register();
          registration.update();
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    });
  }
}
