import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import styled from 'styled-components';

export const StyledItemCard = styled.div`
  height: ${({ path }) =>
    path === '/locations/pos' ? 'calc(100vh - 185px)' : 'calc(100vh - 64px)'};
  background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#FFFFFF')};

  &::-webkit-scrollbar {
    width: 0;
  }

  .title-content-select {
    font-size: 16px !important;
    font-weight: 300 !important;
    font-family: Larsseit, sans-serif !important;
    line-height: 16px;
  }

  .invoice-component {
    display: none;
    @media print {
      margin-top: 50px;
      display: block;
    }
  }

  .edit-order-btn {
    display: flex;
    justify-content: center;
    flex-direction: row;
    background: #00cdbe;
    height: 40px;
    pointer-events: visible;
    width: 100px;
    right: unset;
    bottom: 0px;
    color: #ffffff;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    display: ${(props) => (props.path === '/locations/pos' ? 'block' : 'none')};
    height: 100vh;
  }

  .MuiButtonBase-root {
    font-family: Larsseit, sans-serif !important;
  }

  .order-item-card {
    position: relative;

    .order {
      height: ${({ path, windowHeight }) =>
        path === '/locations/pos' ? `${windowHeight - 252}px` : `${windowHeight - 127}px`};

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #343d69;
        width: 4px;
        border-radius: 5px;
      }

      /* Handle */

      &::-webkit-scrollbar-thumb {
        background: #7367f0;
        border-radius: 5px;
      }

      /* Handle on hover */

      &::-webkit-scrollbar-thumb:hover {
        background: #7367f0;
      }

      @media (max-width: 1280px) {
        height: ${({ path, windowHeight }) =>
          path === '/locations/pos' ? `${windowHeight - 252}px` : `${windowHeight - 129}px`};
      }
    }

    .customer {
      background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#DDDDDD')};
      display: flex;
      justify-content: space-between;
      pointer-events: ${(props) => props.path !== '/locations/pos' && 'none'};

      .details {
        padding: 12px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        background: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#262C49')};
        height: 67px;

        h2 {
          display: flex;
          justify-content: space-between;
          column-gap: 2px;
          font-size: 12px;
          line-height: 16px;
          font-weight: 300;
          font-family: Larsseit, sans-serif !important;
          color: ${(props) => (props.theme === 'dark' ? '#262C49' : '#313131')};
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }

          //span{
          //  &:nth-of-type(2){
          //    font-size: 12px;
          //    color: #A5A5A5;
          //  }
          //}
        }

        &__customer {
          display: flex;
          column-gap: 8px;
          flex-direction: column;
          width: 100%;

          &__data {
            width: 100%;
          }

          h2 {
            font-size: 16px;
            font-family: Larsseit, sans-serif !important;
            line-height: 16px;
            font-weight: normal;
            color: ${(props) => (props.theme === 'dark' ? '#10163A' : '#FFFFFF')};
            margin: 0;

            &:first-child {
              margin-bottom: 8px;
            }

            &:nth-of-type(1) {
              font-size: 20px;
              font-weight: 500;
            }

            &:nth-of-type(2) {
              font-weight: 300;
              line-height: 18px;
            }
          }
        }

        .clear-user-btn {
          outline: unset;
          border: unset;
          background: transparent;
          display: ${(props) => props.path === '/locations/pos/checkout-done' && 'none'};
        }
      }

      .clear-btn {
        background: ${(props) => (props.theme === 'dark' ? '#262C49' : '#313131')};
        border: unset;
        padding: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        outline: unset;

        h2 {
          color: #ff4646;
          font-size: 12px;
          line-height: 16px;
          font-weight: normal;
          font-family: Larsseit, sans-serif !important;
          margin-top: 8px;
        }
      }

      //.title-content{
      //  font-size: 12px;
      //  color: #a5a5a5;
      //}
    }
  }

  .order {
    //height: calc(100vh - 269px);
    overflow: hidden;

    &::-webkit-scrollbar {
      width: 0;
    }

    .order-body {
      height: 100%;
      margin-top: 0px;
      padding-bottom: 90px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #343d69;
        width: 4px;
        border-radius: 5px;
      }

      /* Handle */

      &::-webkit-scrollbar-thumb {
        background: #7367f0;
        border-radius: 5px;
      }

      /* Handle on hover */

      &::-webkit-scrollbar-thumb:hover {
        background: #7367f0;
      }
    }

    .order-details {
      display: flex;
      justify-content: space-between;
      padding: 16px 12px;
      align-items: center;
      pointer-events: ${(props) => props.path !== '/locations/pos' && 'none'};

      h2 {
        width: fit-content;
        margin: 0;
        color: ${(props) => (props.theme === 'dark' ? '#ffffff' : '#313131')};
        font-size: 20px;
        line-height: 20px;
        font-weight: 500;
        font-family: Larsseit, sans-serif !important;

        &:nth-of-type(2) {
          color: ${(props) => (props.theme === 'dark' ? '#ffffff' : '#a5a5a5')};
          font-size: 12px;
          line-height: 16px;
          font-weight: normal;
          font-family: Larsseit, sans-serif !important;
        }
      }
    }

    .order-items {
      border-top: 1px solid #e1e4e6;
      border-bottom: 1px solid #e1e4e6;
      padding: 24px 12px;
      margin-bottom: 12px;
      pointer-events: ${(props) => props.path !== '/locations/pos' && 'none'};
      //height: ${(props) =>
        props.path === '/locations/pos' ? 'calc(100vh - 631px)' : 'calc(100vh - 451px)'};
      overflow: auto;

      &::-webkit-scrollbar {
        width: 0;
      }

      span {
        display: flex;
        justify-content: space-between;

        &:first-child {
          h2 {
            font-size: 16px;
            color: ${(props) => (props.theme === 'dark' ? '#ffffff' : '#313131')};
            line-height: 20px;
            font-weight: normal;
            font-family: Larsseit, sans-serif !important;
          }
        }
      }

      .order-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }

        h2 {
          font-size: 16px;
          line-height: 20px;
          font-weight: normal;
          font-family: Larsseit, sans-serif !important;
          color: ${(props) => (props.theme === 'dark' ? '#ffffff' : '#313131')};

          p {
            font-size: 12px;
            line-height: 16px;
            color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#A5A5A5')};
            font-weight: 300;
            font-family: Larsseit, sans-serif !important;
            margin-top: 4px;
          }

          &:nth-of-type(1) {
            span {
              &:nth-of-type(2) {
                font-size: 12px;
                line-height: 16px;
                color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#A5A5A5')};
                font-weight: 300;
                font-family: Larsseit, sans-serif !important;
                margin-top: 4px;
              }
            }
          }
        }

        &__item {
          width: 100%;
          //padding-left: 16px;
          display: flex;
          justify-content: space-between;

          &__items {
            width: 80%;
            display: flex;
            //align-items: center;
            column-gap: 16px;

            &__increment {
              //width: 30%;
              display: ${(props) => (props.path === '/locations/pos' ? 'flex' : 'none')};
              //justify-content: space-between;
              height: fit-content;
              column-gap: 8px;

              button {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 24px;
                font-family: Larsseit, sans-serif !important;
                color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#313131')};
                line-height: 16px;
                border: unset;
                outline: unset;
                border-radius: 3px;
                background: ${(props) => (props.theme === 'dark' ? '#262C49' : '#E9E9E9')};
                width: 36px;
                height: 36px;
              }

              input {
                width: 32px;
                height: 32px;
                background: #262c49;
                outline: unset;
                border-radius: 3px;
                color: #ffffff;
                font-family: Larsseit, sans-serif !important;
                font-size: 12px;
                font-weight: normal;
                line-height: 15px;
                border: unset;
                padding: 8px;
                text-align: center;
              }
            }

            &__item {
              display: flex;
              column-gap: 8px;

              &__count {
                h2 {
                  font-size: 16px;
                  font-weight: normal;
                  line-height: 20px;
                  color: #00cdbe !important;
                  font-family: Larsseit, sans-serif !important;
                }
              }

              &__info {
                h2 {
                  color: ${(props) => (props.theme === 'dark' ? '#ffffff' : '#2C2C2C')} !important;
                  font-size: 16px;
                  font-weight: normal;
                  line-height: 20px;
                  font-family: Larsseit, sans-serif !important;
                }

                p {
                  font-size: 12px;
                  line-height: 16px;
                  color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#464646')};
                  font-weight: 300;
                  font-family: Larsseit, sans-serif !important;
                  margin-top: 4px;
                  margin-bottom: 4px;
                }
              }
            }
          }

          &__price {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 8px;

            h2 {
              margin: 0;
              height: fit-content;
              font-size: 16px;
              line-height: 20px !important;
              font-weight: normal !important;
              color: ${(props) => (props.theme === 'dark' ? '#c2c6dc' : '#2C2C2C')} !important;
              font-family: Larsseit, sans-serif !important;
              text-align: right;
            }
          }
        }
      }
    }

    .discount,
    .sub-total,
    .due-total,
    .discounted-total,
    .tax-total {
      display: flex;
      justify-content: space-between;
      padding: 12px;
      pointer-events: ${(props) => props.path !== '/locations/pos' && 'none'};

      h2 {
        font-size: 16px;
        line-height: 16px;
        font-weight: normal;
        font-family: Larsseit, sans-serif !important;
        margin: 0;
        color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#313131')};
      }

      span {
        font-size: 16px;
        line-height: 16px;
        font-weight: normal;
        font-family: Larsseit, sans-serif !important;
        color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#313131')};
      }
    }

    .due-total {
      padding-top: 0;
      padding-bottom: 24px;
    }

    .divider {
      height: 1px;
      border-top: 1px solid #c2c6dc;
      padding-bottom: 8px;
      width: calc(100% - 24px);
      align-self: center;
      justify-content: center;
      display: flex;
      margin: 0 auto;
    }

    .discount {
      padding-top: 16px;
      padding-bottom: 24px;

      .Dropdown-control {
        padding: 0;
        background: ${(props) => (props.theme === 'dark' ? '#262C49' : '#E1E4E6')};
        color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#313131')};
        width: 120px;
        height: 36px;
        border: unset;
        border-radius: 5px;
        padding: 10px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .Dropdown-menu {
        background: ${(props) =>
          props.theme === 'dark' ? '#262C49 !important' : '#E1E4E6 !important'};
        border: unset !important;
        border-bottom-left-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
        top: 94%;

        .Dropdown-option {
          color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#313131')};
          padding: 8px 16px 8px 16px;
          line-height: 16px;

          &:nth-of-type(1) {
            padding-top: 2px;
          }

          &:nth-of-type(2) {
            padding-top: 0;
          }

          &:hover {
            background: transparent !important;
          }
        }

        .is-selected {
          background: transparent;
        }
      }
    }

    .input-discount {
      padding: 0 12px 18px;
      display: flex;
      flex-direction: column;
      pointer-events: ${(props) => props.path !== '/locations/pos' && 'none'};

      input {
        outline: unset;
        border: 1px solid ${(props) => (props.theme === 'dark' ? '#ced4da' : '#ced4da')};
        border-radius: 3px;
        padding: 12px;
        text-align: right;
        background: transparent;
        color: #c2c6dc;
      }
    }

    .sub-total,
    .discounted-total {
      padding-bottom: 8px !important;
      padding-top: 0px;
    }

    .tax-total {
      padding-top: 0;
      padding-bottom: 16px;
    }

    .add-note {
      padding: 12px 12px 0 12px;
      display: flex;
      flex-direction: column;
      pointer-events: ${(props) => props.path !== '/locations/pos' && 'none'};

      button {
        width: 100%;
        height: 40px;
        background: ${(props) => (props.theme === 'dark' ? '#262C49' : '#f9f9f9')};
        color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#313131')};
        font-size: 16px;
        font-weight: normal;
        line-height: 24px;
        border: ${(props) => (props.theme === 'dark' ? 'unset' : '1px solid #A5A5A5')};
        border-radius: 5px;
        outline: unset;
        border-radius: 3px;
      }

      input {
        outline: unset;
        border: 1px solid ${(props) => (props.theme === 'dark' ? '#ced4da' : '#ced4da')};
        border-radius: 3px;
        padding: 12px;
        background: transparent;
        color: #c2c6dc;
        color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#313131')};
      }
    }

    .discount {
      align-items: center;
      //border-bottom: 1px solid #E1E4E6;
      //column-gap: 200px;
      pointer-events: ${(props) => props.path !== '/locations/pos' && 'none'};

      .discount-select {
        width: 120px;

        .css-1wa3eu0-placeholder {
          color: #c2c6dc;
        }

        .css-1okebmr-indicatorSeparator {
          display: none;
        }

        .css-2613qy-menu {
          background: #262c49 !important;
        }

        .css-1s2u09g-control {
          background: #262c49;
          border: unset;
          outline: unset;

          &:hover {
            background: #262c49;
            border-color: unset;
          }
        }

        .css-1pahdxg-control {
          background: #262c49;
          border: unset !important;
          box-shadow: unset !important;
        }

        .css-tlfecz-indicatorContainer {
          color: #c2c6dc;
        }
      }

      .MuiFormControl-root {
        height: fit-content;
        background: #262c49;
        border-radius: 5px;
        font-family: Larsseit, sans-serif !important;
        width: 120px;

        .MuiSelect-root {
          padding: 10px 24px 10px 15px;
          font-size: 12px;
          line-height: 28px;
          font-weight: normal;
          font-family: Larsseit, sans-serif !important;
          color: #ffffff;

          @media (max-width: 768px) {
            padding-right: 35px;
            padding-left: 20px;
          }
        }

        @media (max-width: 768px) {
          position: unset;
          //position: fixed;
          //width: 100%;
          //left: 0;
          //border-radius: 0;
          //top: 64px;
          z-index: 100000;
        }
      }

      .MuiSelect-icon {
        top: 50%;
        right: -8px;
        transform: translate(-50%, -50%);
        color: #fff;
        @media (max-width: 768px) {
          right: 3px;
        }
      }
    }
  }

  .clear-btn {
    background: ${(props) => (props.theme === 'dark' ? '#262C49' : '#E1E4E6')};
    width: fit-content;
    outline: unset;
    border: unset;
    border-radius: 3px;
    align-items: center;
    column-gap: 8px;
    padding: 12px 14px;
    margin-left: 12px;
    margin-bottom: 0px;
    pointer-events: ${(props) => props.path !== '/locations/pos' && 'none'};
    display: ${(props) => (props.path === '/locations/pos' ? 'flex' : 'none')};

    h2 {
      color: #ea5455;
      font-size: 12px;
      line-height: 16px;
      font-weight: normal;
      font-family: Larsseit, sans-serif !important;
      margin: 0;
    }
  }

  .payment {
    display: ${(props) => (props.path === '/locations/pos/checkout-done' ? 'none' : 'flex')};
    justify-content: center;
    flex-direction: row;
    background: #00cdbe;
    //padding: 20px 12px;
    height: 60px;
    pointer-events: ${(props) => props.path !== '/locations/pos' && 'visible'};
    //margin-top: 80px;
    //width: 33%;
    width: 100%;
    right: ${(props) => (props.path === '/locations/pos/cash-checkout' ? 'unset' : '0px')};
    position: absolute;
    bottom: ${(props) => (props.path === '/locations/pos/cash-checkout' ? '0px' : '0px')};
    justify-content: ${(props) => (props.path === '/locations/pos' ? 'space-between' : 'center')};
    align-items: center;

    button {
      width: 100%;
      height: 100%;
      border: unset;
      background: transparent;
      font-family: Larsseit, sans-serif !important;
      font-size: 16px;
      font-weight: normal;
      line-height: 25px;
      color: #ffffff;
    }

    @media (max-width: 768px) {
      position: fixed;
      width: 100%;
      bottom: 0;
      height: 50px;
      display: none;
    }

    &__parent {
      cursor: pointer;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      column-gap: 1px;
      @media (max-width: 768px) {
        //flex-direction: row;
      }

      button {
        color: #fff;
        //padding: 12px 52px;
        border: unset;
        background: unset;
        font-family: Larsseit, sans-serif !important;
        font-weight: normal;
        font-size: 16px;
        width: fit-content;
        height: fit-content;
        outline: unset;
        //background: #313131;
        display: flex;
        justify-content: ${(props) => (props.path === '/locations/pos' ? 'flex-start' : 'center')};
        padding: 0;
      }

      &__pay,
      &__place-order {
        cursor: pointer;
        justify-content: space-between;
        //padding: 15px 16px;
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 768px) {
          height: 50px;
        }

        button {
          font-size: 20px;
          width: 100%;
          height: 100%;
        }
      }

      &__place-order {
        background: #6057cc;
      }

      &__pay {
        background: #00cdbe;
        cursor: pointer;
        justify-content: space-between;

        &__due {
          width: 50%;
          display: flex;
          justify-content: flex-end;

          h2 {
            font-size: 16px;
            color: #ffffff;
            font-family: Larsseit, sans-serif !important;
            font-weight: 500;
            line-height: 16px;
            margin: 0;
          }
        }
      }
    }
  }
`;

export const StyledSelect = styled(Select)`
  .select {
    &__indicator {
      color: ${(props) => (props.theme === 'dark' ? '#C2C6D6' : '#262c49')};
      &:hover {
        color: ${(props) => (props.theme === 'dark' ? '#C2C6D6' : '#262c49')};
      }
    }
    &__control {
      background: ${(props) =>
        props.theme === 'dark' ? '#262C49 !important' : '#E9E9E9 !important'};
      border-color: ${(props) =>
        props.theme === 'dark' ? '#262C49 !important' : '#E9E9E9 !important'};

      .select {
        &__indicator-separator {
          display: none;
        }

        &__placeholder {
          color: ${(props) => (props.theme === 'dark' ? '#C2C6D6' : '#262c49')};
        }

        &__input-container {
          input {
            color: #c2c6d6 !important;
          }
        }
      }
    }
  }
`;

export const useStyles = makeStyles(() => ({
  formControl: {
    '& .MuiInputBase-root': {
      color: '#262C49',
      borderColor: '#262C49',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '5px',
      justifyContent: 'center',
      background: '#262C49'
    },
    '& .MuiSelect-select.MuiSelect-select': {
      padding: '14px 16px',
      fontFamily: 'Larsseit, sans-serif !important'
    }
  },
  select: {
    width: 'auto',
    fontSize: '12px',
    '&:focus': {
      backgroundColor: 'transparent'
    },
    fontFamily: 'Larsseit, sans-serif !important'
  },
  selectIcon: {
    position: 'relative',
    // color: "#6EC177",
    fontSize: '14px',
    fontFamily: 'Larsseit, sans-serif !important'
  },
  mainPaper: {
    borderRadius: 5,
    fontFamily: 'Larsseit, sans-serif !important',
    '@media(max-width: 768px)': {
      minWidth: '120px !important',
      borderRadius: '5px',
      right: '12px !important'
    }
  },
  paper: {
    borderRadius: 5,
    fontFamily: 'Larsseit, sans-serif !important'
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    fontFamily: 'Larsseit, sans-serif !important',
    '@media(max-width: 768px)': {
      zIndex: '1000000 !important'
    },
    '& li': {
      fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: '12px',
      padding: '14px 16px',
      background: '#262C49',
      fontFamily: 'Larsseit, sans-serif !important'
    },
    '& li.Mui-selected': {
      color: '#262C49',
      background: '#C2C6DC',
      fontFamily: 'Larsseit, sans-serif !important'
    },
    '& li.Mui-selected:hover': {
      background: '#C2C6DC',
      color: '#262C49',
      fontFamily: 'Larsseit, sans-serif !important'
    }
  }
}));
