import moment from 'moment';
import CONSTANTS from '../../../constants';
import { customFetch as fetch } from '../../../helpers/FetchHelper';
import { addData, deleteData, getStoreData } from '../../../pos/src/utils/DBHelper';
import SyncHelper from '../../../pos/src/utils/SyncHelper';
import LoginService from '../../../services/LoginService';
import UserService from '../../../services/UserService';

export function getRiders(loc_id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.USERS.GET_RIDERS,
        riders: [],
        loading: true
      });
      let riders = await UserService.getRiders(loc_id);
      dispatch({
        type: CONSTANTS.ACTIONS.USERS.GET_RIDERS,
        riders,
        loading: false
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.USERS.GET_RIDERS,
        riders: [],
        loading: false
      });
    }
  };
}

export function uploadUsers(data, loc_id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.USERS.UPLOAD_USERS,
        users: [],
        loading: true
      });
      let users = await UserService.uploadUsers(data, loc_id);
      dispatch({
        type: CONSTANTS.ACTIONS.USERS.UPLOAD_USERS,
        users,
        loading: false
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.USERS.UPLOAD_USERS,
        users: [],
        loading: false
      });
    }
  };
}

export function getUploadUsers(loc_id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.USERS.UPLOAD_USERS,
        users: [],
        loading: true
      });
      let users = await UserService.getUploadedUsers(loc_id);
      dispatch({
        type: CONSTANTS.ACTIONS.USERS.UPLOAD_USERS,
        users,
        loading: false
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.USERS.UPLOAD_USERS,
        users: [],
        loading: false
      });
    }
  };
}

export function getCustomers(loc_id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.USERS.GET_CUSTOMERS,
        customers: [],
        loading: true
      });

      let newdate = moment().format(CONSTANTS.DATE_TIMES.FORMAT.DB);
      await deleteData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, 0);
      await deleteData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, 1);
      let customers = await UserService.getCustomers(loc_id);

      await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, {
        id: 0,
        lastSync: newdate
      });

      await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, {
        id: 1,
        data: customers
      });

      dispatch({
        type: CONSTANTS.ACTIONS.USERS.GET_CUSTOMERS,
        customers,
        loading: false
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.USERS.GET_CUSTOMERS,
        customers: [],
        loading: false
      });
    }
  };
}

export function getAllUsers() {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.USERS.ALL_USERS,
        users: [],
        loading: true
      });
      let users = await UserService.getAllUsers();
      localStorage.setItem('allUser', JSON.stringify(users));
      dispatch({
        type: CONSTANTS.ACTIONS.USERS.ALL_USERS,
        users,
        loading: false
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.USERS.ALL_USERS,
        users: [],
        loading: false
      });
    }
  };
}
export function updateBanStatus(data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.USERS.ALL_USERS,
        users: [],
        loading: true
      });
      let users = await UserService.updateBanStatus(data);
      dispatch({
        type: CONSTANTS.ACTIONS.USERS.ALL_USERS,
        users,
        loading: false
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.USERS.ALL_USERS,
        users: [],
        loading: false
      });
    }
  };
}
export function getAllReports() {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.USERS.ALL_REPORTS,
        reports: [],
        loading: true
      });
      let reports = await UserService.getAllReports();
      dispatch({
        type: CONSTANTS.ACTIONS.USERS.ALL_REPORTS,
        reports,
        loading: false
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.USERS.ALL_REPORTS,
        reports: [],
        loading: false
      });
    }
  };
}

export function deleteReport(data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.USERS.ALL_REPORTS,
        reports: [],
        loading: true
      });
      let reports = await UserService.deleteReports(data);
      dispatch({
        type: CONSTANTS.ACTIONS.USERS.ALL_REPORTS,
        reports,
        loading: false
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.USERS.ALL_REPORTS,
        reports: [],
        loading: false
      });
    }
  };
}

export function getExistingCustomerLocations(loc_id, existingCustomerDetails) {
  return async (dispatch) => {
    try {
      let { token } = LoginService.getToken();
      let existingCustomerLocations = await fetch(
        `${CONSTANTS.API_URL}/locations/${loc_id}/customer_locations/${existingCustomerDetails.user_id}`,
        {
          method: 'GET',
          headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
        }
      );
      existingCustomerLocations = await existingCustomerLocations.json();
      if (existingCustomerLocations) {
        dispatch({
          type: CONSTANTS.ACTIONS.USERS.SET_CUSTOMER_SAVED_LOCATIONS,
          existingCustomerLocations
        });
      }
    } catch (error) {
      console.log(error);
      //throw new Error('Could not fetch customer saved Locations');
    }
  };
}

export function setExistingCustomerLocations(loc_id, existingCustomerDetails) {
  return async (dispatch) => {
    try {
      let localUsersLocations = await getStoreData(
        CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
        CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS
      );
      if (localUsersLocations.length > 0) {
        let locations = localUsersLocations[1].data;
        let existingCustomerLocations = locations.filter(
          (row) =>
            row.user_id === existingCustomerDetails.user_id &&
            (row.loc_id === null || row.loc_id === loc_id)
        );
        existingCustomerLocations.sort((a, b) => a.id - b.id);
        if (existingCustomerLocations) {
          dispatch({
            type: CONSTANTS.ACTIONS.USERS.SET_CUSTOMER_SAVED_LOCATIONS,
            existingCustomerLocations
          });
        }
      }
    } catch (error) {
      console.log(error);
      //throw new Error('Could not fetch customer saved Locations');
    }
  };
}

export function insertUserLocationById(address, location, user_id) {
  return async (dispatch) => {
    try {
      if (window.navigator.onLine) {
        let { token } = LoginService.getToken();
        let selectedLocation = {
          user_id: user_id,
          name: address?.location_name,
          address: address?.address,
          note: address?.note,
          latitude: address?.location.lat,
          longitude: address?.location?.lng
        };
        let existingCustomerLocations = await fetch(
          `${CONSTANTS.API_URL}/locations/${location.loc_id}/customer_locations/${user_id}`,
          {
            method: 'POST',
            body: JSON.stringify(selectedLocation),
            headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
          }
        );
        existingCustomerLocations = await existingCustomerLocations.json();
        let loc_id = location.is_outlet ? location.parent_id : location.loc_id;
        let localUsersLocations = await getStoreData(
          CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
          CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS
        );
        if (localUsersLocations.length > 0) {
          let locations = localUsersLocations[1].data;
          const findNewItems = (prev, curr) => {
            const prevIds = new Set(prev.map((item) => item.id));
            return curr.filter((item) => !prevIds.has(item.id));
          };
          const newItems = findNewItems(locations, existingCustomerLocations);
          locations.push(newItems[0]);
          await deleteData(
            CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
            CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS,
            1
          );
          await addData(
            CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
            CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS,
            {
              id: 1,
              data: locations
            }
          );
        }

        if (existingCustomerLocations) {
          existingCustomerLocations.sort((a, b) => a.id - b.id);
          dispatch({
            type: CONSTANTS.ACTIONS.USERS.SET_CUSTOMER_SAVED_LOCATIONS,
            existingCustomerLocations
          });
        }
      } else {
        let userLocation = {
          address: address?.address,
          name: address?.location_name,
          latitude: '0',
          longitude: '0',
          loc_id: location.is_outlet ? location.parent_id : location.loc_id,
          note: address?.note,
          status: 0,
          user_id: user_id,
          id: address?.id
        };

        await SyncHelper.saveUsersLocationOffline(userLocation, location);
      }
    } catch (error) {
      throw new Error('Could not add Customer Location');
    }
  };
}

export function resetExistingCustomerDetails() {
  return {
    type: CONSTANTS.ACTIONS.USERS.SET_EXISTING_CUSTOMER_DETAILS,
    existingCustomerDetails: null
  };
}

export function setExistingCustomerDetails(existingCustomerDetails) {
  return {
    type: CONSTANTS.ACTIONS.USERS.SET_EXISTING_CUSTOMER_DETAILS,
    existingCustomerDetails: existingCustomerDetails
  };
}

export function setCustomerLocation(customerSelectedLocation) {
  return {
    type: CONSTANTS.ACTIONS.USERS.SET_CUSTOMER_SELECTED_LOCATION,
    customerSelectedLocation: customerSelectedLocation
  };
}

export function resetCustomerLocation() {
  return {
    type: CONSTANTS.ACTIONS.USERS.RESET_CUSTOMER_SELECTED_LOCATION,
    customerSelectedLocation: {}
  };
}

export function resetExistingCustomerLocations() {
  return {
    type: CONSTANTS.ACTIONS.USERS.RESET_CUSTOMER_SAVED_LOCATIONS,
    existingCustomerLocations: []
  };
}

export function showAddLocationButton(showButton) {
  return {
    type: CONSTANTS.ACTIONS.USERS.SET_SHOW_ADD_LOCATION_BUTTON,
    showButton: showButton
  };
}
