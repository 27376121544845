import CONSTANTS from '../../../../../constants';
import { customFetch as fetch } from '../../../../../helpers/FetchHelper';
import LoginService from '../../../../../services/LoginService';
import {
  CATEGORY_MENU,
  CLEAR_USER_INFO,
  CREATE_ORDER,
  GET_CATEGORY_UPDATE,
  GET_PROMOTIONS,
  LIVE_ORDERS_DETAILS,
  LIVE_ORDERS_LIST,
  MENU_OPTIONS,
  MENU_UPDATE,
  SET_CATEGORY_ICON_CLASS_LIST,
  SET_LOCATION,
  SET_ORDER_TYPE_LIST,
  SET_PROMOTIONS,
  SET_SOUND_COUNT,
  SET_SOUND_MODE,
  SET_SOUND_ONE_CARD,
  SET_SOUND_PLAY,
  SET_SOUND_VOLUME,
  SET_TOKEN,
  SET_USER_INFO
} from '../../constants/pos/index';

export function setLocationData(location) {
  return async (dispatch) => {
    try {
      dispatch(setLocation(location));
    } catch (e) {
      alert('Location not found');
    }
  };
}
export function clearUserToken() {
  return async (dispatch) => {
    try {
      dispatch(clearUser());
    } catch (e) {
      alert('Location not found');
    }
  };
}

export function setTokenData(token) {
  return async (dispatch) => {
    try {
      dispatch(setToken(token));
    } catch (e) {
      alert('Token not found');
    }
  };
}

export function setMenuOptionsData(loc_id, initialize = false) {
  let { token } = LoginService.getToken();
  return async (dispatch) => {
    try {
      if (initialize) dispatch(setOptions([], true));
      let response = await fetch(
        `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU_OPTIONS.DEFAULT(loc_id)}`,
        {
          method: 'GET',
          headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
        }
      );
      response = await response.json();
      dispatch(setOptions(response, false));
    } catch (e) {
      alert('Options not found');
      dispatch(setOptions([], false));
    }
  };
}

export function setCategoriesData(loc_id, minimalData = 0, initialize = true) {
  let { token } = LoginService.getToken();
  return async (dispatch) => {
    try {
      if (initialize) dispatch(setCategories('', true));
      let response = await fetch(
        `${CONSTANTS.API_URL}${CONSTANTS.URLS.CATEGORY.DEFAULT(loc_id)}?minimalData=${minimalData}`,
        {
          method: 'GET',
          headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
        }
      );
      response = await response.json();
      dispatch(setCategories(response, false));
    } catch (e) {
      alert('Categories not found');
      dispatch(setCategories('', false));
    }
  };
}

export function saveCategories(loc_id, category, initialize = false) {
  let { token } = LoginService.getToken();
  return async (dispatch) => {
    try {
      if (initialize) dispatch(setCategoriesStatus('', true));
      let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.CATEGORY.DEFAULT(loc_id)}`, {
        method: 'POST',
        body: JSON.stringify(category),
        headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
      });
      response = await response.json();
      dispatch(setCategoriesStatus(response, false));
    } catch (e) {
      alert('Categories not found');
    }
  };
}

export function saveItems(loc_id, item, token, initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize) dispatch(setItemStatus('', true));
      let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.DEFAULT(loc_id)}`, {
        method: 'POST',
        body: item,
        headers: { Accept: CONSTANTS.REQUEST_HEADERS.Accept, 'X-ACCESS-TOKEN': token }
      });
      response = await response.json();
      dispatch(setItemStatus(response, false));
    } catch (e) {
      alert('Items not found');
      dispatch(setItemStatus('', false));
    }
  };
}

export function createOrder(
  postData,
  locationData,
  token = null,
  paymentData = null,
  initialize = false
) {
  return async (dispatch) => {
    try {
      if (initialize) dispatch(setItemStatus('', true));

      // if(paymentData && !(_.isEmpty(paymentData))) {
      //     postData.payment_details = { details: JSON.stringify(paymentData), method: paymentData.method, processor_id: paymentData.processor_id };
      //     postData.payment_status_id = paymentData.payment_status_id;
      // } else {
      //     let paymentStatuses = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.PAYMENTS.DEFAULTS}/payment_statuses`, {
      //         method: 'GET',
      //         headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
      //     });
      //     paymentStatuses = await paymentStatuses.json();
      //     if(paymentStatuses) return paymentStatuses;
      //
      //     let paidStatus = _.find(paymentStatuses, (paymentStatus) => paymentStatus.key === CONSTANTS.ORDERS.VARIABLES.PAYMENT_STATUSES.NOT_PAID);
      //     postData.payment_status_id = paidStatus.id;
      // }
      let url = `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.CREATE_ORDER(locationData.slug)}`;

      let response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
      });
      if (!response.ok && response.status === 500) {
        throw Error('Could not place order');
      }

      response = await response.json();
      return response;
    } catch (e) {
      alert('Failed to create order');
      dispatch(setOrder('', false));
    }
  };
}
export function updateOrder(
  postData,
  locationData,
  token = null,
  paymentData = null,
  initialize = false,
  orderId
) {
  return async (dispatch) => {
    try {
      if (initialize) dispatch(setItemStatus('', true));

      // if(paymentData && !(_.isEmpty(paymentData))) {
      //     postData.payment_details = { details: JSON.stringify(paymentData), method: paymentData.method, processor_id: paymentData.processor_id };
      //     postData.payment_status_id = paymentData.payment_status_id;
      // } else {
      //     let paymentStatuses = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.PAYMENTS.DEFAULTS}/payment_statuses`, {
      //         method: 'GET',
      //         headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
      //     });
      //     paymentStatuses = await paymentStatuses.json();
      //     if(paymentStatuses) return paymentStatuses;
      //
      //     let paidStatus = _.find(paymentStatuses, (paymentStatus) => paymentStatus.key === CONSTANTS.ORDERS.VARIABLES.PAYMENT_STATUSES.NOT_PAID);
      //     postData.payment_status_id = paidStatus.id;
      // }
      let url = `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.UPDATE(locationData.slug, orderId)}`;

      let response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
      });

      response = await response.json();
      return response;
      dispatch(setOrder(response, false));
    } catch (e) {
      alert('Failed to create order');
      dispatch(setOrder('', false));
    }
  };
}

export function createOrderWithoutUser(
  postData,
  locationData,
  paymentData = null,
  initialize = false
) {
  return async (dispatch) => {
    try {
      console.log('called create order', { postData });
      if (initialize) dispatch(setItemStatus('', true));

      let url = `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.CREATE_ORDER(locationData.slug)}`;

      let response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: { ...CONSTANTS.REQUEST_HEADERS }
      });

      if (!response.ok && response.status === 500) {
        throw Error('Could not place order');
      }

      response = await response.json();

      return response;
    } catch (e) {
      alert('Failed to create order');
      dispatch(setOrder('', false));
    }
  };
}

export function updateOrderWithoutUser(
  postData,
  locationData,
  paymentData = null,
  initialize = false,
  orderId
) {
  return async (dispatch) => {
    try {
      if (initialize) dispatch(setItemStatus('', true));
      let url = `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.UPDATE_ORDER(
        locationData.slug,
        orderId
      )}`;

      let response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: { ...CONSTANTS.REQUEST_HEADERS }
      });

      response = await response.json();
      return response;
      dispatch(setOrder(response, false));
    } catch (e) {
      console.log(e);
      alert('Failed to create order');
      dispatch(setOrder('', false));
    }
  };
}

export function getLiveOrders(params, token, initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize) dispatch(setUser('', true));
      let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.LIST}`, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
      });
      response = await response.json();
      dispatch(setLiveOrderList(response, false));
    } catch (error) {
      console.log(error);
      dispatch(setLiveOrderList('', false));
    }
  };
}

export function getCategoryClassnameList(initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize) dispatch(setUser('', true));
      let response = await fetch(`${CONSTANTS.API_URL}/icon-class`, {
        method: 'GET',
        headers: { ...CONSTANTS.REQUEST_HEADERS }
      });
      response = await response.json();
      dispatch(setCategoryClassNameList(response, false));
      return response;
    } catch (error) {
      dispatch(setCategoryClassNameList('', false));
    }
  };
}

export function addNewCategoryClassName(data, initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize) dispatch(setUser('', true));
      let response = await fetch(`${CONSTANTS.API_URL}/icon-class`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { ...CONSTANTS.REQUEST_HEADERS }
      });
      if (response.status === 200) {
        getCategoryClassnameList();
      }
      return response;
    } catch (error) {
      return;
    }
  };
}

export function getLiveOrderDetails(locationId, orderId, token, initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize) dispatch(setLiveOrderDetails('', true));
      let response = await fetch(
        `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.DEFAULT(locationId)}/${orderId}`,
        {
          method: 'GET',
          headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
        }
      );
      response = await response.json();
      dispatch(setLiveOrderDetails(response, false));
    } catch (error) {
      console.log(error);
      dispatch(setLiveOrderDetails('', false));
    }
  };
}

export async function userLogin(userCredentials, initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize) dispatch(setUser('', true));
      let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.LOGIN}`, {
        method: 'POST',
        body: JSON.stringify(userCredentials),
        headers: { ...CONSTANTS.REQUEST_HEADERS }
      });
      response = await response.json();
      dispatch(setUser(response, false));
      return response;
    } catch (error) {
      console.log(error);
      dispatch(setUser('', false));
    }
  };
}

export function setLocation(location) {
  return {
    type: SET_LOCATION,
    data: location
  };
}

export function setToken(token) {
  return {
    type: SET_TOKEN,
    data: token
  };
}

export function setOptions(data, loading) {
  return {
    type: MENU_OPTIONS,
    data: data,
    loading: loading
  };
}

export function setCategories(data, loading) {
  return {
    type: CATEGORY_MENU,
    data: data,
    loading: loading
  };
}

export function setCategoryClassNameList(data, loading) {
  return {
    type: SET_CATEGORY_ICON_CLASS_LIST,
    data: data,
    loading: loading
  };
}

export function setCategoriesStatus(data, loading) {
  return {
    type: GET_CATEGORY_UPDATE,
    data: data,
    loading: loading
  };
}

export function setItemStatus(data, loading) {
  return {
    type: MENU_UPDATE,
    data: data,
    loading: loading
  };
}

export function setOrder(data, loading) {
  return {
    type: CREATE_ORDER,
    data: data,
    loading: loading
  };
}

export function setLiveOrderList(data, loading) {
  return {
    type: LIVE_ORDERS_LIST,
    data: data,
    loading: loading
  };
}

export function setOrderTypeList(data, loading) {
  return {
    type: SET_ORDER_TYPE_LIST,
    data: data,
    loading: loading
  };
}

export function setLiveOrderDetails(data, loading) {
  return {
    type: LIVE_ORDERS_DETAILS,
    data: data,
    loading: loading
  };
}

export function setUser(data, loading) {
  return {
    type: SET_USER_INFO,
    data: data,
    loading: loading
  };
}
export function clearUser() {
  return {
    type: CLEAR_USER_INFO
  };
}

export function promotionsData(data) {
  return {
    type: SET_PROMOTIONS,
    promotionsData: data
  };
}
export function promotionsDataCheck(data) {
  return {
    type: GET_PROMOTIONS,
    promotionsData: data
  };
}

export function setVolume(data) {
  return {
    type: SET_SOUND_VOLUME,
    volume: data
  };
}

export function setMode(data) {
  return {
    type: SET_SOUND_MODE,
    mode: data
  };
}

export function setCount(data) {
  return {
    type: SET_SOUND_COUNT,
    count: data
  };
}

export function setOneCard(data) {
  return {
    type: SET_SOUND_ONE_CARD,
    handleOneCard: data
  };
}

export function setOnPlay(data) {
  return {
    type: SET_SOUND_PLAY,
    howler_sound_play: data
  };
}
