import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getCustomers,
  resetCustomerLocation,
  resetExistingCustomerDetails,
  setCustomerLocation
} from '../../../redux/actions/users';
import { setOrderAddress, setOrderCustomer } from '../redux/actions/cart';
import { completeRegistration } from '../redux/actions/users';
import InputNumber from './InputNumber';
// include styles
import parsePhoneNumber from 'libphonenumber-js';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { StyledModalCus } from '../../../styles/GlobalStyles';
import SyncHelper from '../utils/SyncHelper';
import MapModal from './MapModal';

const AddNewCustomer = (props) => {
  let {
    showAddLocationButton,
    setCustomerLocation,
    resetExistingCustomerDetails,
    handleClose,
    changeCustomerClick,
    completeRegistration,
    user,
    existingCustomerDetails,
    nextStep,
    updateSavedLocations,
    setOrderAddress,
    existingCustomerLocations,
    handleCustomerInfoOpen,
    step,
    selectedOutlet,
    lastLocation,
    setLastLocation,
    orderType
  } = props;
  let location = useLocation();

  const [landspaceDevice, setLandspaceDevice] = useState(false);
  const [userCreated, setUserCreated] = useState(false);
  const [renderLoc, setRenderLoc] = useState(false);
  let theme = props.customizer?.customizer?.theme;
  const [isMount, setIsMount] = useState(true);
  const [customer, setCustomer] = useState({
    first_name: '',
    last_name: '',
    email: '',
    address: ''
  });
  const changeCustomer = (event) => {
    setCustomer({ ...customer, [event.target.name]: event.target.value });
  };
  function makeid(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const userRegistration = async () => {
    if (user.first_name) {
      nextStep();
    } else {
      if (customer.phone_number === '') {
        await Swal.fire('Opps!', 'You forgot to add phone number', 'warning');
      } else {
        if (customer.email !== '') {
          let regEmail =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!regEmail.test(customer.email)) {
            await Swal.fire('', 'Please enter valid email address', 'warning');
          } else {
            let phoneNumber = parsePhoneNumber(props.userPhoneNumber, 'PK');
            const data = {
              ...customer,
              user_id: user.user_id,
              phone_number: phoneNumber.number,
              loc_id: selectedOutlet.loc_id
            };
            if (window.navigator.onLine) {
              const response = await completeRegistration(data, user.token);
              const customerData = {
                name: customer.first_name,
                address: customer.address,
                phone_number: user.phone_number,
                note: ''
              };

              if (response.status === 200) {
                let loc_id = selectedOutlet.is_outlet
                  ? selectedOutlet.parent_id
                  : selectedOutlet.loc_id;
                props.getCustomers(loc_id);
                props.user.first_name = customer.first_name;
                props.user.phone_number = data.phone_number;
                props.setOrderCustomer(props.user);
                props.setOrderAddress(customerData);
                setCustomer({ first_name: '', last_name: '', email: '' });
                Swal.fire('Great!', 'User registered completed', 'success');
                if (orderType.order_type === 'delivery') {
                  nextStep();
                } else {
                  props.handleClose();
                }
              }
            } else {
              data.user_id = makeid(6);
              const response = await SyncHelper.saveUserOffline(data, selectedOutlet);
              if (response) {
                const customerData = {
                  name: data.first_name,
                  address: data.address,
                  phone_number: data.phone_number,
                  note: ''
                };
                props.user.first_name = data.first_name;
                props.user.phone_number = customerData.phone_number;
                props.setOrderAddress(customerData);
                props.setOrderCustomer(data);
                setCustomer({ first_name: '', last_name: '', email: '' });
                Swal.fire('Great!', 'User registered completed', 'success');

                nextStep();
              }
            }
          }
        } else {
          let phoneNumber = parsePhoneNumber(props.userPhoneNumber, 'PK');
          const data = {
            ...customer,
            user_id: user.user_id,
            phone_number: phoneNumber.number,
            loc_id: selectedOutlet.loc_id
          };
          if (window.navigator.onLine) {
            const response = await completeRegistration(data, user.token);
            const customerData = {
              name: customer.first_name,
              address: customer.address,
              phone_number: user.phone_number,
              note: ''
            };

            if (response.status === 200) {
              let loc_id = selectedOutlet.is_outlet
                ? selectedOutlet.parent_id
                : selectedOutlet.loc_id;
              props.getCustomers(loc_id);
              props.user.first_name = customer.first_name;
              props.user.phone_number = data.phone_number;
              props.setOrderAddress(customerData);
              props.setOrderCustomer(props.user);
              setCustomer({ first_name: '', last_name: '', email: '' });
              Swal.fire('Great!', 'User registered completed', 'success');
              if (orderType.order_type === 'delivery') {
                nextStep();
              } else {
                props.handleClose();
              }
            }
          } else {
            data.user_id = makeid(6);
            const response = await SyncHelper.saveUserOffline(data, selectedOutlet);
            if (response) {
              const customerData = {
                name: data.first_name,
                address: data.address,
                phone_number: data.phone_number,
                note: ''
              };
              props.user.first_name = data.first_name;
              props.setOrderAddress(customerData);
              props.setOrderCustomer(data);
              setCustomer({ first_name: '', last_name: '', email: '' });
              Swal.fire('Great!', 'User registered completed', 'success');
              nextStep();
            }
          }
        }
      }
    }
  };

  const onDoneClick = () => {
    if (existingCustomerLocations.length === 0) {
      changeCustomerClick(existingCustomerDetails);
      nextStep();
    } else {
      if (!props.existingCustomerDetails.address) {
        existingCustomerLocations.map((loc, index) => {
          if (lastLocation && lastLocation.address === loc?.address) {
            setCustomerLocation(loc);
          }
        });
      }
      changeCustomerClick(existingCustomerDetails);
      handleClose();
    }
  };

  useEffect(() => {
    if (existingCustomerLocations !== null) {
      if (existingCustomerLocations.length > 0) {
        if (lastLocation === null) {
          setCustomerLocation(
            existingCustomerLocations[localStorage.getItem('location_index') || 0]
          );
        }
      }
    }
  }, [existingCustomerDetails, existingCustomerLocations]);

  const updateOrientationStatus = () => {
    const mql = window.matchMedia('(orientation: portrait)');
    if (!mql.matches) {
      setLandspaceDevice(true);
    } else {
      setLandspaceDevice(false);
    }
    // if (deviceScreen.orientation.type === "landscape-primary") {
    //     setLandspaceDevice(true);
    // } else {
    //     setLandspaceDevice(false);
    // }
  };

  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    setUserCreated(true);
  }, [user]);

  useEffect(() => {
    updateOrientationStatus();
    if ('onorientationchange' in window) {
      window.addEventListener(
        'orientationchange',
        function () {
          updateOrientationStatus();
        },
        false
      );
    }
  }, []);
  return (
    <StyledModalCus
      theme={theme}
      visible={true}
      onClose={props.handleClose}
      isLandspace={landspaceDevice}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      enterAnimation={'slideDown'}
      leaveAnimation={'slideDown'}
      duration={350}
      closeMaskOnClick={false}>
      <div className="customer-modal">
        <div className="customer-modal__header">
          {userCreated ? (
            <h2>Add a new Customer</h2>
          ) : !existingCustomerDetails ? (
            <h2>Search Customer</h2>
          ) : (
            <h2>Customer Information</h2>
          )}
          <button
            onClick={() => {
              if (location.pathname !== '/locations/pos/cash-checkout') {
                resetCustomerLocation();
                resetExistingCustomerDetails();
              }
              props.handleClose();
            }}>
            Skip
          </button>
        </div>
        <div className="customer-modal__form">
          <div className="customer-modal__form__input_group">
            {!userCreated && (
              <InputNumber
                changeCustomerClick={changeCustomerClick}
                nextStep={nextStep}
                handleClose={handleClose}
                handleCustomerInfoOpen={handleCustomerInfoOpen}
                searchNumber={true}
                selectedOutlet={selectedOutlet}
                lastLocation={lastLocation}
                setLastLocation={setLastLocation}
                updateSavedLocations={updateSavedLocations}
              />
            )}
            {userCreated && (
              <>
                <p className={'customer-modal__form__input_group__label'}>Customer Name</p>
                <input
                  autoFocus={true}
                  value={customer.first_name}
                  name="first_name"
                  onChange={changeCustomer}
                  type="text"
                  autocomplete="off"
                  placeholder={'Name '}
                />
                <p className={'customer-modal__form__input_group__label'}>Phone Number</p>
                <input
                  disabled
                  value={props.userPhoneNumber}
                  name="phone_number"
                  onChange={changeCustomer}
                  type="text"
                  placeholder={'Number '}
                />
                <p className={'customer-modal__form__input_group__label'}>Email</p>
                <input
                  value={customer.email}
                  name="email"
                  onChange={changeCustomer}
                  type="email"
                  autocomplete="off"
                  placeholder={'Email '}
                />
              </>
            )}
            {renderLoc === true && <MapModal selectedOutlet={selectedOutlet} />}
          </div>
          {existingCustomerDetails && existingCustomerLocations ? (
            <>
              <button onClick={onDoneClick}>
                {existingCustomerLocations.length === 0 ? 'Add Location' : 'Done'}
              </button>
              <br></br>
            </>
          ) : (
            <>
              {orderType.order_type === 'delivery'
                ? (userCreated || user.first_name) && (
                    <button
                      disabled={
                        !user.first_name &&
                        customer.address === '' &&
                        customer.first_name === '' &&
                        customer.email === ''
                      }
                      onClick={userRegistration}>
                      {user.first_name ? 'Next Step' : 'Complete'}
                    </button>
                  )
                : ''}
              {orderType.order_type !== 'delivery'
                ? userCreated &&
                  !user.first_name && (
                    <button
                      disabled={
                        !user.first_name &&
                        customer.address === '' &&
                        customer.first_name === '' &&
                        customer.email === ''
                      }
                      onClick={userRegistration}>
                      {user.first_name ? 'Next Step' : 'Complete'}
                    </button>
                  )
                : ''}
            </>
          )}
        </div>
      </div>
    </StyledModalCus>
  );
};

function mapStateToProps(state) {
  return {
    customizer: state.customizer ? state.customizer : null,
    user: state.userReducer ? state.userReducer.user : null,
    userPhoneNumber: state.userReducer ? state.userReducer.userPhoneNumber : null,
    existingCustomerDetails: state.users.existingCustomerDetails,
    userChanged: state.userReducer.userChanged,
    existingCustomerLocations: state.users.existingCustomerLocations,
    showAddLocationButton: state.users.showAddLocationButton,
    orderType: state.cartReducer ? state.cartReducer.orderType : ''
  };
}

export default connect(mapStateToProps, {
  getCustomers,
  setOrderCustomer,
  completeRegistration,
  setOrderAddress,
  resetExistingCustomerDetails,
  setCustomerLocation,
  resetCustomerLocation
})(AddNewCustomer);
