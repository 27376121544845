import { combineReducers } from 'redux';
import { order } from './OrderReducer';
import { currentStatus, orders } from './OrdersReducer';

const ordersReducer = combineReducers({
  order,
  orders,
  currentStatus
});

export default ordersReducer;
