import _ from 'lodash';
import CONSTANTS from '../constants';
import { customFetch as fetch } from '../helpers/FetchHelper';

export default {
  getToken,
  setLoginData,
  removeLoginData,
  login,
  setSmsWallet,
  getSmsWallet,
  getRole,
  getLoggedUser,
  getLatestModulesList,
  setRoleAndEmail,
  refresh,
  syncUsersList,
  logout
};

function setLoginData(tokenData, role) {
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_token`, tokenData.token);
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_refresh_token`, tokenData.refresh_token);
  let roles = [];
  role.map((item) => {
    if (!roles.includes(item.StaffGroup.staff_group_name)) {
      roles.push(item.StaffGroup.staff_group_name);
    }
  });
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_role`, roles);
  localStorage.setItem(`DashColor`, 'dark');
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_login_user`, tokenData.staff.staff_email);
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_login_user_id`, tokenData.staff.user_id);
  this.setSmsWallet(tokenData.sms_wallet);
}

function setRoleAndEmail(role, email, modules, user_id) {
  const findValue = (arr, value) => {
    return _.find(arr, (elem) => {
      return elem.StaffGroup.staff_group_id === value;
    });
  };
  let roles = [];
  let definedRoles = [16, 11, 13];
  for (let i = 0; i < definedRoles.length; i++) {
    if (findValue(role, definedRoles[i])) {
      let res = findValue(role, definedRoles[i]);
      roles.push(res.StaffGroup.staff_group_name);
      break;
    }
  }
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_role`, roles);
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_login_user`, email);
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_login_user_id`, user_id);
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_modules`, JSON.stringify(modules));
}

function setSmsWallet(wallet) {
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_sms_wallet`, wallet);
}

function removeLoginData() {
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_token`);
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_role`);
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_location`);
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_location_count`);
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_modules`);
}

function getToken() {
  let token = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_token`);
  if (token) return { token };
  return null;
}

function getRole() {
  let role = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_role`);
  if (role) return { role };
  return null;
}

function getSmsWallet() {
  let wallet = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_sms_wallet`);
  if (wallet) return { wallet };
  return null;
}

async function login(email, password) {
  let data = { email, password };
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.LOGIN}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: CONSTANTS.REQUEST_HEADERS
  });
  response = await response.json();
  if (response && response.token)
    return {
      modules: response.modules,
      token: response.token,
      refresh_token: response.refresh_token,
      sms_wallet: response.sms_wallet,
      role: response.role,
      staff: response.staff
    };
  throw new Error(`Could not login: ${response}`);
}

async function getLoggedUser() {
  let { token } = this.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}/session_user`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  this.setRoleAndEmail(
    response.role,
    response.staff.staff_email,
    response.modules,
    response.staff?.User?.user_id
  );
  return response.modules;
}
function getLatestModulesList() {
  const modules = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_modules`);
  if (modules) {
    return JSON.parse(modules);
  } else {
    return [];
  }
}

async function syncUsersList(data) {
  let { token } = this.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}/sync_users`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  return response;
}

async function refresh() {
  // const { token } = this.getToken();
  const refresh_token = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_refresh_token`);
  if (!refresh_token) {
    console.log('Refresh token not found in local storage');
    return;
  }
  let data = { refresh_token };
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.REFRESH_TOKEN}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { ...CONSTANTS.REQUEST_HEADERS }
  });
  if (response.status !== 200) {
    console.log(`Could not refresh token: ${response}`);
    return;
    //throw new Error(`Could not refresh token: ${response}`);
  }
  response = await response.json();
  if (response && response.token) {
    localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_token`, response.token);
    return response.token;
  } else {
    throw new Error(`Could not refresh token: ${response}`);
  }
}

async function logout() {
  const email = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_login_user`);
  const refresh_token = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_refresh_token`);
  if (email && refresh_token) {
    let data = { email, refresh_token };
    await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.LOGOUT}`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: CONSTANTS.REQUEST_HEADERS
    });
  }
  removeLoginData();
}
