import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircularProgress from '@material-ui/core/CircularProgress';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import _, { isInteger } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import 'react-dropdown/style.css';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { bytesToBase64 } from '../../../helpers/Base64Helper';
import GoogleHelper from '../../../helpers/GoogleHelper';
import InvoiceHelper from '../../../helpers/InvoiceHelper';
import ViewHelper from '../../../helpers/ViewHelper';
import { toggleCartVisibility } from '../../../redux/actions/customizer';
import { setStateValue } from '../../../redux/actions/orders';
import { getAllTables } from '../../../redux/actions/tables';
import {
  resetCustomerLocation,
  resetExistingCustomerDetails,
  resetExistingCustomerLocations
} from '../../../redux/actions/users/index';

import { getFilteredPromotionList } from '../../../redux/actions/promotions';
import LocationService from '../../../services/LocationService';
import LoginService from '../../../services/LoginService';
import PrinterService from '../../../services/PrinterService';
import { useGetAllPrinterStationsQuery } from '../../../services/PrinterStationService';
import { StyledItemCard } from '../container/style/ItemCardStyle';
import {
  addCartAmountDue,
  clearCart,
  clearCartCustomer,
  clearCheckout,
  removeItemFromCart,
  setAddNoteVisibilityStatus,
  setCheckout,
  setOrderAddress,
  setOrderComment,
  setOrderDate,
  setOrderDiscount,
  setOrderDiscountType,
  setOrderOrderingTime,
  setOrderTableNo,
  updateCartDiscount,
  updateQuantityFromCart
} from '../redux/actions/cart';
import {
  createOrder,
  createOrderWithoutUser,
  updateOrder,
  updateOrderWithoutUser
} from '../redux/actions/pos';
import { resetUserData } from '../redux/actions/users/index';
import CartService from '../utils/CartService';
import OptionHelper from '../utils/OptionHelper';
import { checkPromo } from '../utils/Promotions';
import CurrentOrder from './CurrentOrder';
import CustomerDetails from './CustomerDetails';
import CustomerSection from './CustomerSection';
import NoteCalculation from './NoteCalculation';
import PosInvoice from './PosInvoice';

const ItemCard = (props) => {
  let location = useLocation();
  const history = useHistory();
  const [params, setParams] = useState(skipToken);
  const componentRef = useRef();
  let theme = props.customizer?.customizer?.theme;
  const [discountType, setDiscountType] = useState(null);
  const [showAddNote, setShowAddNote] = useState(
    props.userCart?.noteVisibility ? props.userCart.noteVisibility : false
  );
  const [enablePrint, setEnablePrint] = useState(false);
  let itemCardVisible = props.customizer?.customizer?.itemCardVisible;
  const date = new Date();
  const [orderDate, setOrderOrderingDate] = useState();
  const [orderTime, setOrderTime] = useState();
  const [customerDetailModalOpen, setCustomerDetailModalOpen] = React.useState(false);
  const [windowHeight, setWindowHeight] = useState(0);
  const [promotions, setPromotions] = useState(props.promotions);
  const [isPlacerOrder, setIsPlacerOrder] = React.useState(false);
  const [isManualPromo, setIsManualPromo] = React.useState(false);

  const [promoCode, setPromoCode] = useState(null);
  let printerStations = [];
  const result = useGetAllPrinterStationsQuery(params);
  if (window.navigator.onLine) {
    printerStations = result?.data;
    localStorage.setItem('printerStations', JSON.stringify(printerStations));
  } else {
    let printers = localStorage.getItem('printerStations');
    if (printers) {
      printerStations = JSON.parse(printers);
    }
  }

  useEffect(() => {
    const location = LocationService.getLocation();
    if (location) {
      let promo_id = localStorage.getItem('promo_id');
      if (promo_id && promo_id !== 'null') {
        setTimeout(() => {
          setIsPlacerOrder(true);
          promo_id = JSON.parse(promo_id);
          props.checkPromo(promo_id.type, props.location);
          setDiscountType(promo_id.type);
          setPromoCode(promo_id.id);
          setIsManualPromo(true);
          setIsPlacerOrder(false);
        }, 500);
      }
      setParams({
        loc_id: location.loc_id
      });
    }
  }, []);

  let currency_code = localStorage.getItem('currency_code');
  let decimal_place = localStorage.getItem('decimal_place');
  const currencyCode = props.currency?.currency_code
    ? props.currency?.currency_code
    : currency_code;
  const decimalPlace = props.finalLooc?.decimal_places
    ? props.finalLooc?.decimal_places
    : decimal_place;

  useEffect(() => {
    setWindowHeight(window.innerHeight);
    window.addEventListener('resize', () => {
      setWindowHeight(window.innerHeight);
    });
    PrinterService.getSocket();
  }, []);

  const handleCustomerDetailModalOpen = () => {
    setCustomerDetailModalOpen(true);
  };

  const handleCustomerDetailModalClose = () => {
    setCustomerDetailModalOpen(false);
  };

  const discountSelectData = [];
  let promoCodeId = null;
  let code = 'NONE';
  let [comment, setComment] = useState('');

  const toggleAddNoteInput = () => {
    props.setAddNoteVisibilityStatus(!showAddNote);
    setShowAddNote(!showAddNote);
    setTimeout(() => {
      document.querySelector('.note-input').focus();
    }, 300);
  };

  const handleClose = () => {
    if (comment === '') {
      props.setAddNoteVisibilityStatus(false);
      setShowAddNote(false);
    }
  };

  useEffect(() => {
    if (comment === '') {
      props.setAddNoteVisibilityStatus(false);
      setShowAddNote(false);
    }
  }, [comment]);

  let calculatePrice = (carts) => {
    let price = 0;
    if (carts.length) {
      let len = carts.length;
      for (let i = 0; i < len; i++) {
        let calc = carts[i].cartItem.original_price
          ? carts[i].cartItem.original_price
          : CartService.getTotalFor(carts[i].cartItem) * carts[i].count;

        price += calc;
      }
      return price;
    } else {
      return 0;
    }
  };

  let calculatePriceWithDis = (carts, discount, deliveryCharge = 0) => {
    let price = 0;
    if (carts.length) {
      let len = carts.length;
      for (let i = 0; i < len; i++) {
        let calc = carts[i].cartItem.original_price
          ? carts[i].cartItem.original_price
          : CartService.getTotalFor(carts[i].cartItem) * carts[i].count;

        price += calc;
      }
      return price + deliveryCharge - parseFloat(discount);
    } else {
      return 0;
    }
  };

  let getTax = (taxPercent) => {
    taxPercent = parseFloat(taxPercent) || 0.0;
    return calculatePrice(props.cart) * (taxPercent / 100.0);
  };

  let handaleAddCart = (count, lineItem) => {
    const originalPrice =
      lineItem &&
      OptionHelper.optionsTotal(lineItem, lineItem?.options, count + 1, lineItem?.portionSize);

    props.updateQuantityFromCart(count + 1, lineItem.identify, originalPrice);
  };

  let handleRemoveCart = (count, lineItem) => {
    if (count === 1) {
      props.removeItemFromCart(lineItem);
    } else if (count > 0) {
      const originalPrice =
        lineItem &&
        OptionHelper.optionsTotal(lineItem, lineItem?.options, count - 1, lineItem?.portionSize);

      props.updateQuantityFromCart(count - 1, lineItem.identify, originalPrice);
    }
  };

  const changeCartVisibility = props.toggleCartVisibility;
  const connection = window.navigator.onLine;
  let promotionsList = props.promotionList ? props.promotionList : [];

  // if (!connection) {
  //   promotionsList = JSON.parse(localStorage.getItem('promotionList'));
  // }

  let filteredPromotionList = [];
  if (promotionsList?.length > 0) {
    promotionsList.map((promotion) => {
      if (promotion.active === 1 || (promotion.active === true && promotion.automatic === false)) {
        discountSelectData.push({
          label: promotion.code,
          value: promotion.code
        });
        filteredPromotionList.push(promotion);
      }
      filteredPromotionList.push(promotion);
    });
  }
  if (filteredPromotionList?.length > 0) {
    discountSelectData.unshift({ label: 'None', value: 'null' });
  }
  useEffect(() => {
    //CartService.setDeliveryCharge(props.location?.loc_id, 0);
    setComment(props.userCart?.comment ? props.userCart.comment : '');
    props.setOrderDate(new Date().toISOString().slice(0, 10));
    setOrderOrderingDate(new Date().toISOString().slice(0, 10));
    setOrderTime(moment(date.getTime()).format('LTS'));
    props.setOrderOrderingTime(moment(date.getTime()).format('LTS'));
    if (props.location) {
      props.getFilteredPromotionList(
        props.location.is_outlet ? props.location?.parent_id : props.location?.loc_id
      );
    }
  }, []);

  let cartItems = [];

  let handleComment = (e) => {
    props.setOrderComment(e.target.value);
    setComment(e.target.value);
  };

  for (let i = 0; i < props.cart.length; i++) {
    let cart = {
      ...props.cart[i].cartItem,
      itemCount: props.cart[i].count,
      itemTotal: props.cart[i].cartItem?.original_price,
      itemTotalAfterDiscount: props.cart[i].cartItem?.original_price - 0, //discount
      total_price: props.cart[i].cartItem?.original_price
    };
    cartItems.push(cart);
  }

  let order_data = CartService.transformedCart(cartItems);

  let cartData = [];
  props.cart &&
    props.cart.length &&
    props.cart.map((item, key) => {
      cartData.push(item.cartItem);
    });

  const handleDiscountChange = (event) => {
    setIsPlacerOrder(true);
    if (event.value === 'null') {
      setIsManualPromo(false);
      localStorage.removeItem('promo_id');
      promoCodeId = null;
      code = 'NONE';
      props.getFilteredPromotionList(
        props.location.is_outlet ? props.location?.parent_id : props.location?.loc_id
      );
      setPromotions(props.promotions);
    } else {
      promotionsList.map((promotion) => {
        if (promotion.code.toString() === event.value.toString()) {
          promoCodeId = promotion.id;
          code = promotion.code;
        }
      });
    }
    setPromoCode(promoCodeId);
    localStorage.setItem('promo_id', JSON.stringify({ id: promoCodeId, type: event.value }));
    setTimeout(() => {
      props.checkPromo(code, props.location);
      setIsPlacerOrder(false);
      if (code === 'NONE') {
        setIsManualPromo(false);
      } else {
        setIsManualPromo(true);
      }
    }, 1000);
    setDiscountType(event.value);
  };

  useEffect(() => {
    setPromotions(props.promotions);
    if (!props.cart || props.cart.length === 0 || !isManualPromo) return;
    let updatedCart = props.cart.map((cart) => {
      const item = cart.cartItem;
      const discounts = ViewHelper.getDiscountedItemPrice(
        item?.menu_price,
        item?.menu_id,
        item.menu_category_id,
        props.promotions,
        props.orderType,
        props.location
          ? props.location.OrderTypes === undefined
            ? props.location.orderTypes
            : props.location.OrderTypes
          : []
      );
      cart = {
        ...cart,
        cartItem: {
          ...item,
          discounts
        }
      };
      return cart;
    });
    props.updateCartDiscount(updatedCart);
  }, [props.promotions]);
  const discount = CartService.getDiscount(
    props.location,
    cartData,
    promotions,
    props.orderType?.id,
    promoCode,
    isManualPromo
  ).discount;
  let amountDue = calculatePriceWithDis(props.cart, discount) + getTax(props.location?.tax_percent);
  const locationLoc = LocationService.getLocationLoc();

  const deliveryLatLang =
    props?.address && props?.address?.location
      ? `${props.address?.location?.lat},${props.address?.location?.lng}`
      : props.customerSelectedLocation?.latitude
      ? `${props.customerSelectedLocation?.latitude},${props.customerSelectedLocation?.longitude}`
      : `${props.orderInfo?.address?.location?.lat},${props.orderInfo?.address?.location?.lng}`;
  let deliveryArea = undefined;
  if (
    props.userCart?.orderType?.order_type === 'delivery' &&
    !deliveryLatLang.includes('undefined')
  ) {
    deliveryArea = GoogleHelper.getDeliveryArea(
      deliveryLatLang,
      locationLoc?.deliveryAreas,
      calculatePriceWithDis(props.cart, discount)
    );
    if (deliveryArea?.minimum_order !== '') {
      let minimumOrder = parseInt(deliveryArea?.minimum_order);
      if (amountDue > minimumOrder) {
        CartService.setDeliveryCharge(
          props.location?.loc_id,
          deliveryArea ? (deliveryArea?.deliveryCharges ? deliveryArea?.deliveryCharges : 0) : 0
        );
      }
    } else {
      CartService.setDeliveryCharge(
        props.location?.loc_id,
        deliveryArea ? (deliveryArea?.deliveryCharges ? deliveryArea?.deliveryCharges : 0) : 0
      );
    }
  } else {
    CartService.setDeliveryCharge(props.location?.loc_id, 0);
  }
  let totalDue = CartService.getOrderTotalWithAllCharges(
    props.location,
    Number(localStorage.getItem('tax_percentage')),
    promotions,
    props.orderType?.id,
    promoCode,
    cartData,
    isManualPromo
  );

  useEffect(() => {
    totalDue = CartService.getOrderTotalWithAllCharges(
      props.location,
      Number(localStorage.getItem('tax_percentage')),
      promotions,
      props.orderType?.id,
      promoCode,
      cartData,
      isManualPromo
    );
  }, [deliveryArea]);

  let local_storage_location = window.navigator.onLine
    ? LocationService.getLocation()
    : JSON.parse(localStorage.getItem('current_location'));
  let taxWithDelivery = CartService.getTaxValueWithDelivery(
    cartData,
    deliveryArea ? deliveryArea.deliveryCharges : 0,
    discount,
    localStorage.getItem('tax_percentage')
  ).taxRate;
  let orderData = {
    longitude: props.location?.longitude
      ? props.location?.longitude
      : props.customerSelected?.longitude,
    latitude: props.location?.latitude
      ? props.location?.latitude
      : props.customerSelected?.latitude,
    user_id: props.user?.user_id ? (isInteger(props.user?.user_id) ? props.user?.user_id : 0) : '',
    offline_user_id: !isInteger(props.user?.user_id) ? props.user?.user_id : null,
    qrcode: '',
    comment: comment ? comment : props.address?.note,
    order_data: order_data,
    delivery_charges: deliveryArea ? deliveryArea.deliveryCharges : 0,
    total_discount: discount,
    discount_id: null,
    promocode_id: promoCode,
    discountType: discountType,
    totalPrice: calculatePriceWithDis(
      props.cart,
      discount,
      deliveryArea ? deliveryArea.deliveryCharges : 0
    ),
    totalItem: props.cart?.length,
    tax: taxWithDelivery,
    order_type_id: props.orderType?.id,

    order_type: props.orderType?.order_type,
    order_type_label: props.orderType?.label,
    location_id: props.location?.loc_id ? props.location?.loc_id : local_storage_location?.loc_id,
    timezone: props.location?.timezone
      ? props.location?.timezone
      : local_storage_location?.timezone,
    isOfflineOrder: window.navigator.onLine ? false : true,
    location_name: props.location ? props.location?.name : local_storage_location?.name,
    decimal_places: props.location
      ? props.location?.decimal_places
      : local_storage_location?.decimal_places,
    currency_code: props.currency ? props.currency?.currency_code : currency_code,

    table: {
      table_no: props.table?.id ? props.table?.id : props.table?.table_id,
      table_name: props.table?.name ? props.table?.name : props.table?.table_name
    },
    order_time: moment(new Date()).utc().format('HH:mm:ss'),
    order_date: moment(new Date()).utc().format('YYYY-MM-DD'),
    // delivery_location: `${props.address?.location?.lat},${props.address?.location?.lng}`,
    delivery_location:
      props.orderType?.order_type !== 'delivery'
        ? '0,0'
        : props.customerSelectedLocation?.latitude
        ? `${props.customerSelectedLocation?.latitude},${props.customerSelectedLocation?.longitude}`
        : props.address
        ? `${props.address?.location?.lat},${props.address?.location?.lng}`
        : `${props.orderInfo?.address?.location?.lat},${props.orderInfo?.address?.location?.lng}`,
    delivery_address:
      props.orderType?.order_type !== 'delivery'
        ? ''
        : props.customerSelectedLocation?.address
        ? props.customerSelectedLocation?.address
        : props.address?.address
        ? props.address?.address
        : props.orderInfo?.address?.address,
    delivery_phone: props.address?.phone_number
      ? props.address?.phone_number
      : props.user?.phone_number,
    address_id: props.customerSelectedLocation?.id
      ? props.customerSelectedLocation?.id
      : props.address?.id
      ? props.address?.id
      : props.orderInfo?.address?.id
      ? props.orderInfo?.address?.id
      : 1,
    payment_status_id: 1,
    payment_status_label: 'Paid',
    offline_order: false,
    send_email: false,

    customer: props.customer,
    order_variant: 'pos',
    payment: 'cash',
    payment_details: {
      details: 'Simple detail',
      method: 'cash',
      processor_id: null
    },
    status_id: 13
  };
  const orderTypes = JSON.parse(localStorage.getItem('orderTypeList'));

  const minimumOrderDelivery =
    props.location && orderTypes && ViewHelper.getMinimumOrderFor(orderTypes, 'delivery');
  const minimumOrderDine =
    props.location && orderTypes && ViewHelper.getMinimumOrderFor(orderTypes, 'dine_in');
  const minimumOrderPickUp =
    props.location && orderTypes && ViewHelper.getMinimumOrderFor(orderTypes, 'pick_up');
  const minimumOrderCurbSide =
    props.location && orderTypes && ViewHelper.getMinimumOrderFor(orderTypes, 'curbside_pickup');

  let handleSubmit = () => {
    if (promoCode !== null) {
      const promotion_data = {
        id: promoCode,
        type: discountType
      };
      localStorage.setItem('promo_id', JSON.stringify(promotion_data));
    }
    // if (!connection) {
    //   orderData.delivery_address = orderData.customer?.address;
    // }
    props.setCheckout(orderData);
  };

  const redirectToLocation = (location) => {
    history.push(`${location}`);
  };

  const clearOrderCustomer = () => {
    Swal.fire({
      title: 'Are you sure to remove user?',
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Clear',
      denyButtonText: `Keep`
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        props.clearCartCustomer();
        props.resetUserData();
        props.resetCustomerLocation();
        props.resetExistingCustomerLocations();
        props.resetExistingCustomerDetails();
        // props.clearCart()
      } else if (result.isDenied) {
      }
    });
  };

  if (window.ontouchstart) {
    alert('touch started');
  }

  function makeid(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function isWebview() {
    const navigator = window.navigator;
    const userAgent = navigator.userAgent;
    const normalizedUserAgent = userAgent.toLowerCase();
    const standalone = navigator.standalone;

    const isIos =
      /ip(ad|hone|od)/.test(normalizedUserAgent) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    const isAndroid = /android/.test(normalizedUserAgent);
    const isSafari = /safari/.test(normalizedUserAgent);
    const isWebview =
      (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !standalone && !isSafari);

    return isWebview;
  }

  const handlePrint = async (cartData, location, order) => {
    if (isWebview()) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          term: 'order_print',
          order: order.orderDB,
          location: location
        })
      );
    } else {
      if (PrinterService.isConnected()) {
        // print invoice receipts
        for (const printerStation of printerStations.filter((ps) => ps.is_front_of_house)) {
          const receiptData = await InvoiceHelper.getCartInvoiceData(
            cartData,
            location,
            order.orderDB.order_id
          );
          if (printerStation.enabled) {
            PrinterService.submit({
              type: printerStation.label,
              qty: printerStation.number_of_copies,
              raw_content: bytesToBase64(receiptData)
            });
          }
        }

        // print kitchen receipts
        for (const printerStation of printerStations.filter((ps) => !ps.is_front_of_house)) {
          let kitchenData;
          const category_ids = printerStation.categories.map((c) => c.category_id);
          if (category_ids.length > 0) {
            const order_data = cartData.order_data.filter((item) =>
              category_ids.includes(item.menu_category_id)
            );
            kitchenData = await InvoiceHelper.getKitchenInvoiceData(
              {
                ...cartData,
                order_data
              },
              order.orderDB.order_id,
              printerStation.label
            );
          } else {
            kitchenData = await InvoiceHelper.getKitchenInvoiceData(
              cartData,
              order.orderDB.order_id,
              printerStation.label
            );
          }
          if (printerStation.enabled) {
            PrinterService.submit({
              type: printerStation.label,
              raw_content: bytesToBase64(kitchenData)
            });
          }
        }
      }
    }
  };

  const saveToKitchenOrder = async () => {
    setIsPlacerOrder(true);
    let data = orderData;
    data.order_variant = 'kitchen';
    data.payment_status_id = 5;
    data.payment_status_label = 'Not Paid';
    data.status_id = 13; //being prepared
    let { token } = LoginService.getToken();

    if (props.userCart.orderType === '') {
      setEnablePrint(false);
      setIsPlacerOrder(false);
      Swal.fire(
        'Opps!',
        'Seems like you forgot to select order type, please select order type first',
        'warning'
      ).then(function () {
        props.setCustomerInfoModal((prevState) => ({
          ...prevState,
          orderType: true
        }));
      });
    } else if (props.userCart.orderType?.order_type === 'delivery' && !data.delivery_address) {
      setEnablePrint(false);
      setIsPlacerOrder(false);
      Swal.fire(
        'Opps!',
        'Seems like you forgot to type delivery address, please select delivery address first',
        'warning'
      ).then(function () {
        props.setCustomerInfoModal((prevState) => ({
          ...prevState,
          orderType: true
        }));
      });
    } else if (
      props.userCart.orderType?.order_type === 'delivery' &&
      props.address.address === '' &&
      deliveryArea === undefined
    ) {
      setEnablePrint(false);
      setIsPlacerOrder(false);
      Swal.fire(
        'Opps!',
        'Seems like you forgot to type delivery address, please select delivery address first',
        'warning'
      ).then(function () {
        props.setCustomerInfoModal((prevState) => ({
          ...prevState,
          orderType: true
        }));
      });
    } else if (
      props.userCart.orderType?.order_type === 'delivery' &&
      props.address &&
      // Object.keys(props.address).length > 0 &&
      !deliveryArea
    ) {
      setEnablePrint(false);
      setIsPlacerOrder(false);
      Swal.fire('Opps!', 'This restaurant can not deliver in the selected area', 'warning');
    } else {
      if (props.userCart.orderType?.order_type === 'dine_in' && props.userCart.table === '') {
        setEnablePrint(false);
        setIsPlacerOrder(false);
        Swal.fire(
          'Opps!',
          'Seems like you forgot to select table name, please select first',
          'warning'
        );
      } else {
        if (!amountDue) {
          setIsPlacerOrder(false);
          Swal.fire('Sorry!', 'Without items, your not allowed to checkout', 'warning');
        } else {
          if (
            props.userCart.orderType?.order_type === 'delivery' &&
            amountDue < minimumOrderDelivery
          ) {
            setIsPlacerOrder(false);
            Swal.fire('Opps!', 'Seems total amount is lower than minimum order amount', 'warning');
          } else if (
            props.userCart.orderType?.order_type === 'dine_in' &&
            amountDue < minimumOrderDine
          ) {
            setIsPlacerOrder(false);
            Swal.fire('Opps!', 'Seems total amount is lower than minimum order amount', 'warning');
          } else if (
            props.userCart.orderType?.order_type === 'pick_up' &&
            amountDue < minimumOrderPickUp
          ) {
            setIsPlacerOrder(false);
            Swal.fire('Opps!', 'Seems total amount is lower than minimum order amount', 'warning');
          } else if (
            props.userCart.orderType?.order_type === 'curbside_pickup' &&
            amountDue < minimumOrderCurbSide
          ) {
            setIsPlacerOrder(false);
            Swal.fire('Opps!', 'Seems total amount is lower than minimum order amount', 'warning');
          } else {
            if (props.user?.token) {
              if (props.userCart.cartUpdate === true) {
                if (connection) {
                  let datas = {
                    filter: 'live_orders',
                    filterKeyName: 'live_orders',
                    offlineOrder: false,
                    page: 0,
                    sort: 'DESC'
                  };
                  props.setStateValue(datas);
                  await props.updateOrder(
                    data,
                    props.location,
                    token,
                    null,
                    false,
                    props.userCart.orderId
                  );
                  setEnablePrint(true);
                  props.getAllTables(props.location?.loc_id);
                } else {
                  let datas = {
                    filter: 'offline',
                    filterKeyName: 'offline',
                    offlineOrder: true,
                    page: 0,
                    sort: 'DESC'
                  };
                  props.setStateValue(datas);
                  let updatedData = {
                    ...data,
                    koId: props.userCart.orderId
                  };
                  updatedData.offline_order = true;

                  let kitchenOrderList = JSON.parse(localStorage.getItem('offline_order_list'));

                  const orderIndex = _.findIndex(kitchenOrderList, {
                    koId: props.userCart.orderId
                  });
                  kitchenOrderList[orderIndex] = updatedData;
                  localStorage.setItem('offline_order_list', JSON.stringify(kitchenOrderList));
                  props.setCheckout(updatedData);
                  setEnablePrint(true);
                }
              } else {
                if (connection) {
                  let datas = {
                    filter: 'live_orders',
                    filterKeyName: 'live_orders',
                    offlineOrder: false,
                    page: 0,
                    sort: 'DESC'
                  };
                  props.setStateValue(datas);

                  const order = await props.createOrder(
                    data,
                    LocationService.getLocation(),
                    props.user.token
                  );
                  props.getAllTables(props.location?.loc_id);
                  handlePrint(data, LocationService.getLocation(), order);
                } else {
                  let dataOff = {
                    ...data,
                    koId: makeid(15)
                  };
                  dataOff.offline_order = true;

                  let datas = {
                    filter: 'offline',
                    filterKeyName: 'offline',
                    offlineOrder: true,
                    page: 0,
                    sort: 'DESC'
                  };
                  props.setStateValue(datas);

                  let kitchenOrderList = localStorage.getItem('offline_order_list');
                  if (!kitchenOrderList) {
                    kitchenOrderList = [];
                    kitchenOrderList.push(dataOff);
                    localStorage.setItem('offline_order_list', JSON.stringify(kitchenOrderList));
                  } else {
                    kitchenOrderList = JSON.parse(kitchenOrderList);
                    kitchenOrderList.push(dataOff);
                    localStorage.setItem('offline_order_list', JSON.stringify(kitchenOrderList));
                  }
                  props.setCheckout(dataOff);
                  setEnablePrint(true);
                }
              }
            } else {
              if (props.userCart.cartUpdate === true) {
                if (connection) {
                  let datas = {
                    filter: 'live_orders',
                    filterKeyName: 'live_orders',
                    offlineOrder: false,
                    page: 0,
                    sort: 'DESC'
                  };
                  props.setStateValue(datas);
                  await props.updateOrderWithoutUser(
                    data,
                    props.location,
                    null,
                    false,
                    props.userCart.orderId
                  );
                  setEnablePrint(true);
                  props.getAllTables(props.location?.loc_id);
                } else {
                  // get offline order list
                  // find index of offline order list
                  // replace specific index data from order list
                  // props.userCart.orderId
                  let updatedData = {
                    ...data,
                    koId: props.userCart.orderId
                  };
                  updatedData.offline_order = true;

                  let datas = {
                    filter: 'offline',
                    filterKeyName: 'offline',
                    offlineOrder: true,
                    page: 0,
                    sort: 'DESC'
                  };
                  props.setStateValue(datas);

                  let kitchenOrderList = JSON.parse(localStorage.getItem('offline_order_list'));
                  const orderIndex = _.findIndex(kitchenOrderList, {
                    koId: props.userCart.orderId
                  });
                  kitchenOrderList[orderIndex] = updatedData;
                  localStorage.setItem('offline_order_list', JSON.stringify(kitchenOrderList));
                  props.setCheckout(updatedData);
                  setEnablePrint(true);
                }
              } else {
                if (connection) {
                  let datas = {
                    filter: 'live_orders',
                    filterKeyName: 'live_orders',
                    offlineOrder: false,
                    page: 0,
                    sort: 'DESC'
                  };
                  props.setStateValue(datas);
                  const order = await props.createOrderWithoutUser(
                    data,
                    LocationService.getLocation()
                  );
                  props.getAllTables(props.location?.loc_id);
                  handlePrint(data, LocationService.getLocation(), order);
                } else {
                  let dataOff = {
                    ...data,
                    koId: makeid(15)
                  };
                  dataOff.offline_order = true;

                  let kitchenOrderList = localStorage.getItem('offline_order_list');
                  if (!kitchenOrderList) {
                    kitchenOrderList = [];
                    kitchenOrderList.push(dataOff);
                    localStorage.setItem('offline_order_list', JSON.stringify(kitchenOrderList));
                  } else {
                    kitchenOrderList = JSON.parse(kitchenOrderList);
                    kitchenOrderList.push(dataOff);
                    localStorage.setItem('offline_order_list', JSON.stringify(kitchenOrderList));
                  }
                  props.setCheckout(dataOff);
                }

                setEnablePrint(true);
              }
            }
            setTimeout(() => {
              setComment('');
              props.setOrderComment('');
              props.clearCart();
              props.clearCheckout();
              localStorage.removeItem('plugd_menu_cart');
              localStorage.setItem('plugd_admin_location', JSON.stringify({}));
              history.replace('/');
            }, 1000);
          }
        }
      }
    }
  };

  const proceedToPayment = () => {
    let data = orderData;
    if (props.userCart.orderType === '') {
      Swal.fire(
        'Opps!',
        'Seems like you forgot to select order type, please select first',
        'warning'
      ).then(function () {
        props.setCustomerInfoModal((prevState) => ({
          ...prevState,
          orderType: true
        }));
      });
    } else if (props.userCart.orderType?.order_type === 'delivery') {
      if (connection) {
        if (Object.keys(props.customer).length === 0) {
          setEnablePrint(false);
          Swal.fire(
            'Opps!',
            'Seems like you forgot to select customer and delivery address',
            'warning'
          ).then(function () {
            props.setCustomerInfoModal((prevState) => ({
              ...prevState,
              orderType: true
            }));
          });
        } else if (!data.delivery_address) {
          setEnablePrint(false);
          Swal.fire('Opps!', 'Seems like you forgot to type delivery address', 'warning').then(
            function () {
              props.setCustomerInfoModal((prevState) => ({
                ...prevState,
                orderType: true
              }));
            }
          );
        } else if (
          Object.keys(props.address ? props.address : props.orderInfo?.address).length > 0 &&
          // props.address &&
          !deliveryArea
        ) {
          setEnablePrint(false);
          Swal.fire('Opps!', 'This restaurant can not deliver in the selected area', 'warning');
        } else {
          if (!amountDue) {
            Swal.fire('Sorry!', 'Without items, your not allowed to checkout', 'warning');
          } else {
            if (
              props.userCart.orderType?.order_type === 'delivery' &&
              amountDue < minimumOrderDelivery
            ) {
              Swal.fire(
                'Opps!',
                'Seems total amount is lower than minimum order amount',
                'warning'
              );
            } else if (
              props.userCart.orderType?.order_type === 'dine_in' &&
              amountDue < minimumOrderDine
            ) {
              Swal.fire(
                'Opps!',
                'Seems total amount is lower than minimum order amount',
                'warning'
              );
            } else if (
              props.userCart.orderType?.order_type === 'pick_up' &&
              amountDue < minimumOrderPickUp
            ) {
              Swal.fire(
                'Opps!',
                'Seems total amount is lower than minimum order amount',
                'warning'
              );
            } else if (
              props.userCart.orderType?.order_type === 'curbside_pickup' &&
              amountDue < minimumOrderCurbSide
            ) {
              Swal.fire(
                'Opps!',
                'Seems total amount is lower than minimum order amount',
                'warning'
              );
            } else {
              handleSubmit();
              changeCartVisibility(false);
              props.addCartAmountDue(amountDue);
              history.push('/locations/pos/cash-checkout');
            }
          }
        }
      } else {
        if (Object.keys(props.customer).length === 0) {
          setEnablePrint(false);
          Swal.fire(
            'Opps!',
            'Seems like you forgot to select customer and delivery address',
            'warning'
          ).then(function () {
            props.setCustomerInfoModal((prevState) => ({
              ...prevState,
              orderType: true
            }));
          });
        } else if (!data.delivery_address) {
          setEnablePrint(false);
          Swal.fire('Opps!', 'Seems like you forgot to type delivery address', 'warning').then(
            function () {
              props.setCustomerInfoModal((prevState) => ({
                ...prevState,
                orderType: true
              }));
            }
          );
        } else {
          if (!amountDue) {
            Swal.fire('Sorry!', 'Without items, your not allowed to checkout', 'warning');
          } else {
            if (
              props.userCart.orderType?.order_type === 'delivery' &&
              amountDue < minimumOrderDelivery
            ) {
              Swal.fire(
                'Opps!',
                'Seems total amount is lower than minimum order amount',
                'warning'
              );
            } else if (
              props.userCart.orderType?.order_type === 'dine_in' &&
              amountDue < minimumOrderDine
            ) {
              Swal.fire(
                'Opps!',
                'Seems total amount is lower than minimum order amount',
                'warning'
              );
            } else if (
              props.userCart.orderType?.order_type === 'pick_up' &&
              amountDue < minimumOrderPickUp
            ) {
              Swal.fire(
                'Opps!',
                'Seems total amount is lower than minimum order amount',
                'warning'
              );
            } else if (
              props.userCart.orderType?.order_type === 'curbside_pickup' &&
              amountDue < minimumOrderCurbSide
            ) {
              Swal.fire(
                'Opps!',
                'Seems total amount is lower than minimum order amount',
                'warning'
              );
            } else {
              handleSubmit();
              changeCartVisibility(false);
              props.addCartAmountDue(amountDue);
              history.push('/locations/pos/cash-checkout');
            }
          }
        }
      }
    } else {
      if (props.userCart.orderType?.order_type === 'dine_in' && props.userCart.table === '') {
        setEnablePrint(false);
        Swal.fire(
          'Opps!',
          'Seems like you forgot to select table name, please select first',
          'warning'
        );
      } else {
        if (!amountDue) {
          Swal.fire('Sorry!', 'Without items, your not allowed to checkout', 'warning');
        } else {
          if (
            props.userCart.orderType?.order_type === 'delivery' &&
            amountDue < minimumOrderDelivery
          ) {
            Swal.fire('Opps!', 'Seems total amount is lower than minimum order amount', 'warning');
          } else if (
            props.userCart.orderType?.order_type === 'dine_in' &&
            amountDue < minimumOrderDine
          ) {
            Swal.fire('Opps!', 'Seems total amount is lower than minimum order amount', 'warning');
          } else if (
            props.userCart.orderType?.order_type === 'pick_up' &&
            amountDue < minimumOrderPickUp
          ) {
            Swal.fire('Opps!', 'Seems total amount is lower than minimum order amount', 'warning');
          } else if (
            props.userCart.orderType?.order_type === 'curbside_pickup' &&
            amountDue < minimumOrderCurbSide
          ) {
            Swal.fire('Opps!', 'Seems total amount is lower than minimum order amount', 'warning');
          } else {
            handleSubmit();
            changeCartVisibility(false);
            props.addCartAmountDue(amountDue);
            history.push('/locations/pos/cash-checkout');
          }
        }
      }
    }
  };

  const clearOrder = () => {
    Swal.fire({
      title: 'Are you sure to clear the order?',
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Clear',
      denyButtonText: `Keep`
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setComment('');
        props.setOrderComment('');
        setShowAddNote(false);
        props.setAddNoteVisibilityStatus(false);
        props.clearCart();
        props.setOrderAddress({});
        props.clearCartCustomer();
        props.toggleCartVisibility(false);
        localStorage.removeItem('promo_id');
        // toggleCartStatus();
      } else if (result.isDenied) {
      }
    });
  };

  const promo_code_from_localstorage = JSON.parse(localStorage.getItem('promo_id'));

  useEffect(() => {
    if (props.userCart.comment !== '') {
      setShowAddNote(true);
    }
  }, [props.userCart]);

  return (
    <StyledItemCard
      theme={theme}
      path={location.pathname}
      windowHeight={windowHeight}
      visibility={itemCardVisible.toString()}>
      <div className="order-item-card">
        <div className={'customer'}>
          <CustomerSection
            customerInfoModalOpen={props.customerInfoModalOpen}
            userCart={props.userCart}
            handleCustomerDetailModalOpen={handleCustomerDetailModalOpen}
            clearOrderCustomer={clearOrderCustomer}
            setCustomerInfoModal={props.setCustomerInfoModal}
            theme={theme}
          />

          <CustomerDetails
            open={customerDetailModalOpen}
            onClose={handleCustomerDetailModalClose}
          />
        </div>
        <div className={'order'}>
          {props.cart ? (
            props.cart.length ? (
              <div className="order-body">
                <div className={'order-details'}>
                  <h2>Current Order</h2>
                  {/*<h2>#12345</h2>*/}
                  {location.pathname !== '/locations/pos' &&
                    location.pathname !== '/locations/pos/checkout-done' && (
                      <>
                        <button
                          className="edit-order-btn"
                          onClick={() => redirectToLocation('/locations/pos')}>
                          Edit Order
                        </button>
                      </>
                    )}
                </div>
                {currencyCode !== undefined && (
                  <div className={'order-items'}>
                    {props.cart
                      ? props.cart.length
                        ? props.cart.map((item, key) => {
                            let lineItem = item ? (item.cartItem ? item.cartItem : '') : '';
                            let count = item ? (item.count ? item.count : '') : '';

                            return (
                              <CurrentOrder
                                key={key}
                                count={count}
                                lineItem={lineItem}
                                currencyCode={currencyCode}
                                decimalPlace={decimalPlace}
                                editItem={props.editItem}
                                handaleAddCart={handaleAddCart}
                                handleRemoveCart={handleRemoveCart}
                              />
                            );
                          })
                        : null
                      : null}
                  </div>
                )}
                {currencyCode !== undefined && (
                  <NoteCalculation
                    showAddNote={showAddNote}
                    location={location}
                    toggleAddNoteInput={toggleAddNoteInput}
                    handleComment={handleComment}
                    comment={comment}
                    handleClose={handleClose}
                    promo_code_from_localstorage={promo_code_from_localstorage}
                    discountSelectData={discountSelectData}
                    theme={theme}
                    promotionsList={promotionsList}
                    handleDiscountChange={handleDiscountChange}
                    calculatePrice={calculatePrice}
                    cart={props.cart}
                    currencyCode={currencyCode}
                    decimalPlace={decimalPlace}
                    discount={discount}
                    deliveryArea={deliveryArea}
                    cartData={cartData}
                    totalDue={totalDue}
                  />
                )}
                <button
                  onClick={() => {
                    clearOrder();
                    // props.resetUserData()
                  }}
                  className="clear-btn">
                  <FontAwesomeIcon icon={faTrash} size={'1x'} color="#ea5455" />
                  <h2>Delete Order</h2>
                </button>
              </div>
            ) : (
              <div style={{ height: '100%' }} />
            )
          ) : (
            <div style={{ height: '100%' }} />
          )}

          <div className="payment">
            {location.pathname === '/locations/pos' && (
              <div className={'payment__parent'}>
                {props.orderInfo?.orderType?.order_type === 'dine_in' ? (
                  <div className="payment__parent__pay" onClick={proceedToPayment}>
                    <button style={{ marginLeft: '10px', marginTop: '30px' }}>Pay</button>
                    {currencyCode !== undefined && (
                      <div className="payment__parent__pay__due">
                        <h2>{ViewHelper.toCurrency(totalDue, currencyCode, decimalPlace)}</h2>
                      </div>
                    )}
                  </div>
                ) : null}
                <div className="payment__parent__place-order">
                  {/* <ReactToPrint
                    onBeforeGetContent={saveToKitchenOrder}
                    trigger={() => <button>Place Order</button>}
                    content={() => (enablePrint === true ? componentRef.current : {})}
                  /> */}
                  <button disabled={isPlacerOrder} onClick={proceedToPayment}>
                    <span style={{ width: '100%', marginTop: '15px' }}>
                      {isPlacerOrder ? (
                        <div
                          className={
                            isPlacerOrder ? 'circular-progress-delivery-flow' : 'circular-progress'
                          }>
                          <CircularProgress size={30} thickness={5} color="#262c49" />
                        </div>
                      ) : (
                        'Place Order'
                      )}
                    </span>
                  </button>
                </div>
              </div>
            )}
            {location.pathname !== '/locations/pos' && (
              <>
                <button onClick={() => props.confirmCheckout()}>Done</button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={'invoice-component'} ref={componentRef}>
        <PosInvoice kitchenInvoice={true} />
      </div>
    </StyledItemCard>
  );
};

function mapStateToProps(state) {
  return {
    reducerState: state,
    customerSelectedLocation: state.users.customerSelectedLocation,
    customizer: state.customizer ? state.customizer : null,
    loading: state.posReducer ? state.posReducer.loading : false,
    location: state.locations ? state.locations?.location?.data : null,
    token: state.posReducer ? state.posReducer.token : null,
    categoryMenu: state.posReducer ? state.posReducer.categories : [],
    currency: state.locations ? state.locations?.location?.data?.Currency : null,
    finalLooc: state.locations ? state.locations?.location?.data : null,
    cart: state.cartReducer ? state.cartReducer.cart : [],
    customer: state.cartReducer ? state.cartReducer.customer : [],
    address: state.users.customerSelectedLocation
      ? state.users.customerSelectedLocation.address
      : state.cartReducer
      ? state.cartReducer.address
      : '',
    promotions: state.posReducer ? state.posReducer?.promotionsData : [],
    table: state.cartReducer ? state.cartReducer.table : '',
    orderType: state.cartReducer ? state.cartReducer.orderType : '',
    discountType: state.cartReducer ? state.cartReducer.discountType : '',
    discountAmount: state.cartReducer ? state.cartReducer.discountAmount : '',
    orderInfo: state.cartReducer ? state.cartReducer : null,
    paymentType: state.cartReducer ? state.cartReducer.paymentType : '',
    orderDate: state.cartReducer ? state.cartReducer.orderDate : '',
    orderTime: state.cartReducer ? state.cartReducer.orderTime : '',
    userCart: state.cartReducer ? state.cartReducer : [],
    user: state.cartReducer
      ? state.cartReducer.customer
        ? state.cartReducer.customer
        : null
      : null,
    promotionList: state.promotions
      ? state.promotions.allPromotions
        ? state.promotions.allPromotions.data
        : null
      : null,
    checkout: state.cartReducer ? state.cartReducer.checkout : ''
  };
}

export default connect(mapStateToProps, {
  addCartAmountDue,
  setOrderDate,
  setOrderOrderingTime,
  setOrderDiscountType,
  setOrderDiscount,
  setCheckout,
  clearCart,
  getFilteredPromotionList,
  getAllTables,
  checkPromo,
  updateQuantityFromCart,
  createOrder,
  toggleCartVisibility,
  clearCartCustomer,
  setAddNoteVisibilityStatus,
  setOrderComment,
  removeItemFromCart,
  createOrderWithoutUser,
  updateOrderWithoutUser,
  updateOrder,
  clearCheckout,
  setOrderTableNo,
  setOrderAddress,
  setStateValue,
  updateCartDiscount,
  resetUserData,
  resetExistingCustomerDetails,
  resetCustomerLocation,
  resetExistingCustomerLocations
})(ItemCard);
